import React from 'react';
import '../../../styles/statisticTable.css';

const StatisticsTable = ({ statistics }) => {
  return (
    <div className="statistics-container">
      {statistics?.map((niveau) => (
        <div key={niveau.niveau} className="niveau-section">
          <h2 className="niveau-title">{niveau.niveau}</h2>
          <table className="niveau-table">
            <thead>
              <tr>
                <th>Choix</th>
                {niveau.choixStatistics[0]?.centres.map((centre, index) => (
                  <th key={index}>{centre.centreConcours}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {niveau.choixStatistics.map((choixStat) => (
                <tr key={choixStat.choix}>
                  <td>{choixStat.choix}</td>
                  {choixStat.centres.map((centre, index) => (
                    <td key={index}>{centre.count}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default StatisticsTable;
