import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { useEffect } from 'react';
import { MenuItem } from '@mui/material';

const CreateDialog= (props) => {
  const [openSnack, setOpenSnack] = useState(false);

  const [typeCandidats, setTypeCandidats] = useState([]);

  const [droitConcours, setDroitConcours] = useState('');
  const [fraisScolaire, setFraisScolaire] = useState('');
  const [parametres, setParametres] = useState(1);
  const [typeCandidat, setTypeCandidat] = useState();

  useEffect(() => {
    getTypeCandidats();
  }, [])

  const getTypeCandidats = () => {
    axios.get(`/typeCandidat`)
      .then(res => {
        setTypeCandidats(res.data)
      })
  }
 
  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post(`/droit`, {
      droitConcours: droitConcours,
      fraisScolaire: fraisScolaire,
      parametres: parametres,
      typeCandidat: typeCandidat
    }).then(res => {
      props.onClose();
      props.fetchData();
      setOpenSnack(true);
    })

  }


  return (
    <div>
      <Dialog open={props.open} onClose={props.onClose} maxWidth='xs'>
        <form onSubmit={(e) => handleSubmit(e)}>
          <DialogTitle>Ajouter un nouveau droit</DialogTitle>
          <DialogContent>
            
            <TextField
              margin="dense"
              id="droitConcours"
              label="Droit Concours"
              type="number"
              fullWidth
              variant="outlined"
              onChange={(e) => setDroitConcours(e.target.value)}
              required
            />
            <TextField
              margin="dense"
              id="fraisScolaire"
              label="Frais Scolaire"
              type="number"
              fullWidth
              variant="outlined"
              onChange={(e) => setFraisScolaire(e.target.value)}
              required
            />
            <TextField
            margin="dense"
              select
              label="Type Candidat"
              fullWidth
              // value={typeCandidat}
              onChange={(e) => setTypeCandidat(e.target.value)}
            >
              {typeCandidats?.map((option) => (
                <MenuItem key={option.codeTypeCand} value={option.codeTypeCand}>
                  {option.libelleTypeCand}
                </MenuItem>
              ))}
            </TextField>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onClose}>Annuler</Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
            >Ajouter</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default CreateDialog;