import React, { useEffect } from "react";
import * as styles from "../../styles/variables";
import { Avatar } from "@mui/material";
import { Button } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { reactLocalStorage } from "reactjs-localstorage";
import { useNavigate } from "react-router-dom";

export default function UserAvatarMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [user, setUser] = React.useState("");
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    reactLocalStorage.clear();
    navigate("/toor/login");
    setAnchorEl(null);
  };

  useEffect(() => {
    let st = JSON.parse(reactLocalStorage.get("utilisateur"));
    setUser(`${st.firstname} ${st.lastname}`);
  }, []);

  return (
    <>
      <Button
        id="avatar-button"
        aria-controls={open ? "avatar-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          borderRadius: "30px",
          padding: "0",
          minWidth: "initial",
        }}
      >
        <p style={{ marginRight: "10px" }}>{user}</p>
        <Avatar
          sx={{
            background: styles.colors.drawerBg,
          }}
        >
          {user?.split(" ")[0]?.slice(0,1)}{user?.split(" ")[1]?.slice(0,1)}
        </Avatar>
      </Button>
      <Menu
        id="avatar-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
        MenuListProps={{
          "aria-labelledby": "avatar-button",
        }}
      >
        <MenuItem onClick={handleClose}>Se déconnecter</MenuItem>
      </Menu>
    </>
  );
}
