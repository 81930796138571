import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Backdrop, Button, ButtonGroup, CircularProgress, IconButton, Tooltip } from "@mui/material";
import styled from "styled-components";
import { reactLocalStorage } from "reactjs-localstorage";
import * as styles from "styles/variables";
import SendIcon from "@mui/icons-material/Send";
import { useDownloadExcel } from "react-export-table-to-excel";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const GesionNote = () => {
    const [items, setItems] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [groupResultat, setGroupResultat] = React.useState([]);
    const [dataEpreuve, setDataEpreuve] = useState([]);
    const [dataChoix, setDataChoix] = useState([]);
    const [filterShowResultat, setFilterShowResultat] = useState([]);
    const [pagination, setPagination] = useState({
        debut: 0,
        fin: 1,
    });
    const [series, setSeries] = useState([]);
    const [contingent, setContigent] = useState({});
    const [resultTypeC, setResultTypeC] = useState(["PRO", "IGFnr", "IGTol"]);
    const [province, serProvince] = useState([
        "Antananarivo",
        "Antsiranana",
        "Fianarantsoa",
        "Mahajanga",
        "Toamasina",
        "Toliara",
    ]);
    const [exportExcel, setExportExcel] = useState(false);

    const tableRef = useRef(null);

    const save = () => {
        console.log(items);
    };

    /**
     * GET contaigence
     */
    const allContingenceProvinceParcours = (methodeCalcul = null) => {
        //Ouvrir loading
        setOpen(true);
        axios
            .get(`/candidat/candidatAndNotes/calcul/${methodeCalcul}`)
            .then((res) => {
                prepareDataToShow(res.data.contingent);
                //fermer loading
                setOpen(false);
            })
            .catch(() => {
                //fermer loading
                setOpen(false);
            });
    };

    useEffect(() => {
        allContingenceProvinceParcours(1);
        fetchDataEpreuve();
        fetchDataChoix();
        fetchDataSerie();
    }, []);

    const changeTypeResultat = (oldTypeResultat, newTypeResultat, candidat) => {
        //Ouvrir loading
        setOpen(true);
        setItems({
            ...items,
            [oldTypeResultat]: items[oldTypeResultat].filter((c) => c.numInscription !== candidat.numInscription),
        });

        items[newTypeResultat].unshift(candidat);

        //Ouvrir loading
        setOpen(false);
    };

    const fetchDataEpreuve = () => {
        axios.get(`/epreuve`).then((res) => {
            setDataEpreuve(res.data);
        });
    };

    const fetchDataSerie = () => {
        axios.get(`/series`).then((res) => {
            setSeries(res.data);
        });
    };

    const getNote = (c, idmatiere) => {
        let composer = c.composer.filter((d) => d.codeEpreuve.codeEpreuve === idmatiere);
        return composer.length > 0 ? composer[0].Note : 0;
    };

    const fetchDataChoix = () => {
        axios.get(`/choix`).then((res) => {
            setDataChoix(res.data);
        });
    };

    const getChoix = (candidat, idChoix) => {
        if (candidat.premierChoix.codeFormation === idChoix) return 1;
        if (candidat.deuxiemeChoix.codeFormation === idChoix) return 2;
    };

    const filterResultat = (debut, fin) => {
        setFilterShowResultat(groupResultat.slice(debut, fin));
        setPagination({
            debut: debut,
            fin: fin,
        });
    };

    useEffect(() => {
        if (groupResultat.length > 0) {
            filterResultat(pagination.debut, pagination.fin);
        }
    }, [groupResultat]);

    /**
     * Calcul total inscription par parcours et par province
     */
    const calculTotalInscriptionParcours = (parcours) => {
        let total = 0;
        contingent.filter((p, i) => {
            total += +p[parcours].total;
        });
        return Math.round(total);
    };

    /**
     * Calcul total inscription par parcours et par province ajustement
     */
    const calculTotalContigenteParcoursAjustement = (parcours) => {
        let total = 0;
        contingent.filter((p, i) => {
            total += Math.round(+p[parcours].ajustement);
        });
        return Math.round(total);
    };

    /**
     * Calcul total contigente par parcours et par province
     */
    const calculTotalContigenteParcours = (parcours) => {
        let total = 0;
        contingent.filter((p, i) => {
            total += +p[parcours].prendre;
        });
        return Math.round(total);
    };

    /**
     *
     */
    const handleChangeContigentePro = (e, parcours, province) => {
        e.preventDefault();
        const { value } = e.target;
        setContigent(
            contingent.map((c) => {
                return c.province === province
                    ? {
                          ...c,
                          [parcours]: {
                              ...c[parcours],
                              ajustement: value,
                          },
                      }
                    : { ...c };
            })
        );
    };

    /**
     * Preparer data to show
     */
    const prepareDataToShow = (contigent) => {
        let dataUpdated = [];
        province.filter((pr) => {
            //Prendre donner par province
            dataUpdated.push({
                province: pr,
                ...contigent[pr],
                PRO: {
                    ajustement: Math.round(contigent[pr].PRO.prendre),
                    ...contigent[pr].PRO,
                },
                IGFnr: {
                    ajustement: Math.round(contigent[pr].IGFnr.prendre),
                    ...contigent[pr].IGFnr,
                },
                IGTol: {
                    ajustement: Math.round(contigent[pr].IGTol.prendre),
                    ...contigent[pr].IGTol,
                },
            });
        });
        setContigent(dataUpdated);
    };

    const ajusterResultat = (methodeCalcul) => {
        //ouvrir loading
        setOpen(true);
        let ajustement = {
            Antananarivo: {},
            Fianarantsoa: {},
            Toamasina: {},
            Antsiranana: {},
            Toliara: {},
            Mahajanga: {},
        };

        province.forEach((p) => {
            let cont = contingent.filter((s) => s.province === p)[0];
            ajustement[p] = {
                PRO: cont["PRO"].ajustement,
                IGFnr: cont["IGFnr"].ajustement,
                IGTol: cont["IGTol"].ajustement,
            };
        });

        axios
            .post(`/candidat/ajuster/contingence/${methodeCalcul}`, ajustement)
            .then((res) => {
                let type = ["AP", "LP", "AF", "LF", "AT", "LT"];

                let tResult = {};

                type.forEach((element) => {
                    tResult[element] = res.data[element];
                });
                setItems(tResult);
                // //Set Data in local storage
                reactLocalStorage.setObject("calcul", tResult);

                let groupResultat = [];

                // // On construit des groupes de deux
                for (let i = 0; i < type.length; i += 2) {
                    groupResultat.push(type.slice(i, i + 2));
                }

                setGroupResultat(groupResultat);

                //fermer loading
                setOpen(false);
            })
            .catch(() => {
                //fermer loading
                setOpen(false);
            });
    };

    /**
     * Download table contigente
     */
    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: "Contigence_finale",
        sheet: "Contigence",
    });

    return (
        <Wrapper>
            <div className="btn-pagination">
                <ButtonGroup variant="contained" aria-label="outlined primary button group">
                    <Tooltip title="Afficher résultat par 1ère choix" placement="bottom">
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                ajusterResultat(1);
                            }}
                        >
                            1 ere choix
                        </Button>
                    </Tooltip>

                    <Tooltip title="Afficher résultat par 1ère choix et 2ème choix" placement="bottom">
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                ajusterResultat(2);
                            }}
                        >
                            2 eme choix
                        </Button>
                    </Tooltip>
                </ButtonGroup>
                <ButtonGroup variant="contained" aria-label="outlined primary button group">
                    <Button
                        onClick={(e) => {
                            e.preventDefault();
                            filterResultat(pagination.debut - 1, pagination.fin - 1);
                        }}
                        disabled={pagination.debut === 0}
                    >
                        Precedente
                    </Button>
                    <Button
                        onClick={(e) => {
                            e.preventDefault();
                            filterResultat(pagination.debut + 1, pagination.fin + 1);
                        }}
                        disabled={pagination.fin > 2}
                    >
                        Suivante
                    </Button>
                </ButtonGroup>
                <Tooltip title="Fonctionalité en cours de developpement" placement="bottom">
                    <Button
                        onClick={(e) => {
                            e.preventDefault();
                            console.log(items);
                        }}
                    >
                        Enregistrer
                    </Button>
                </Tooltip>
            </div>

            {Object.keys(contingent).length > 0 ? (
                <div className="contingent">
                    <h3 style={{ textAlign: "center" }}>
                        Vous pouvez ajuster les nombres des candidats à prendre par province et par parcours dans la
                        colonne <b>CONTINGENT AJUSTE</b>
                    </h3>
                    <Button
                        variant="contained"
                        onClick={(e) => {
                            e.preventDefault();
                            setExportExcel(true);
                            setTimeout(() => {
                                onDownload();
                                setExportExcel(false);
                            }, 4000);
                        }}
                        color="success"
                        style={{ margin: "10px" }}
                        endIcon={<FileDownloadIcon />}
                    >
                        Export excel
                    </Button>
                    <table ref={tableRef}>
                        <thead>
                            <tr>
                                <th colSpan="4"></th>
                                <th>{calculTotalContigenteParcours("PRO")}</th>
                                <th>{calculTotalContigenteParcours("IGFnr")}</th>
                                <th>{calculTotalContigenteParcours("IGTol")}</th>
                            </tr>

                            <tr>
                                <th></th>
                                <th colSpan="3" style={{ textAlign: "center" }}>
                                    INSCRIPTION
                                </th>
                                <th colSpan="3" style={{ textAlign: "center" }}>
                                    CONTINGENT THEORIQUE
                                </th>
                                <th colSpan="3" style={{ textAlign: "center" }}>
                                    CONTINGENT AJUSTE
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td></td>
                                {resultTypeC.map((r, i) => (
                                    <td key={`${r}_${i}`}>{r}</td>
                                ))}
                                {resultTypeC.map((r, i) => (
                                    <td key={`${r}_${i}`}>{r}</td>
                                ))}
                                {resultTypeC.map((r, i) => (
                                    <td key={`${r}_${i}`}>{r}</td>
                                ))}
                            </tr>
                            {contingent.map((p, i) => (
                                <>
                                    <tr key={`${p.numero}_${p.province}_${i}`}>
                                        <td>{p.province}</td>
                                        {/* total parcours par province */}
                                        <td>{p["PRO"]?.total}</td>
                                        <td>{p["IGFnr"]?.total}</td>
                                        <td>{p["IGTol"]?.total}</td>
                                        {/* total prendre par parcours par province */}
                                        <td>{p["PRO"]?.prendre}</td>
                                        <td>{p["IGFnr"]?.prendre}</td>
                                        <td>{p["IGTol"]?.prendre}</td>
                                        {/* contingent ajustemenrt */}
                                        <td>
                                            {exportExcel === true ? (
                                                <p> {p["PRO"]?.ajustement}</p>
                                            ) : (
                                                <div className="note">
                                                    <input
                                                        sx={{ width: "100%" }}
                                                        margin="dense"
                                                        type="number"
                                                        value={p["PRO"]?.ajustement}
                                                        onChange={(e) => {
                                                            handleChangeContigentePro(e, "PRO", p.province);
                                                        }}
                                                        required
                                                    />
                                                </div>
                                            )}
                                        </td>
                                        <td>
                                            {exportExcel === true ? (
                                                <p> {p["IGFnr"]?.ajustement}</p>
                                            ) : (
                                                <div className="note">
                                                    <input
                                                        sx={{ width: "100%" }}
                                                        margin="dense"
                                                        type="number"
                                                        value={p["IGFnr"]?.ajustement}
                                                        onChange={(e) => {
                                                            handleChangeContigentePro(e, "IGFnr", p.province);
                                                        }}
                                                        required
                                                    />
                                                </div>
                                            )}
                                        </td>
                                        <td>
                                            {exportExcel === true ? (
                                                <p> {p["IGTol"]?.ajustement}</p>
                                            ) : (
                                                <div className="note">
                                                    <input
                                                        sx={{ width: "100%" }}
                                                        margin="dense"
                                                        value={p["IGTol"]?.ajustement}
                                                        onChange={(e) => {
                                                            handleChangeContigentePro(e, "IGTol", p.province);
                                                        }}
                                                        type="number"
                                                        required
                                                    />
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                    {i === contingent.length - 1 && (
                                        <tr key={"contigent"}>
                                            <td></td>
                                            {/* total parcours par province */}
                                            <td>{calculTotalInscriptionParcours("PRO")}</td>
                                            <td>{calculTotalInscriptionParcours("IGFnr")}</td>
                                            <td>{calculTotalInscriptionParcours("IGTol")}</td>
                                            {/* total prendre par parcours par province */}
                                            <td>{calculTotalContigenteParcours("PRO")}</td>
                                            <td>{calculTotalContigenteParcours("IGFnr")}</td>
                                            <td>{calculTotalContigenteParcours("IGTol")}</td>

                                            {/* total prendre par parcours par province pour ajustement */}
                                            <td>
                                                <b>{calculTotalContigenteParcoursAjustement("PRO")}</b>
                                            </td>
                                            <td>
                                                <b>{calculTotalContigenteParcoursAjustement("IGFnr")}</b>
                                            </td>
                                            <td>
                                                <b>{calculTotalContigenteParcoursAjustement("IGTol")}</b>
                                            </td>
                                        </tr>
                                    )}
                                </>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <>Contigent non disponnible</>
            )}

            {filterShowResultat.map((typeR, index) => (
                <div className="container" key={index}>
                    {typeR.map((tR) => (
                        <div className="table" key={`${tR}_${index}`}>
                            <p>
                                {tR} <b>( {items[tR].length} ) </b>{" "}
                            </p>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Index</th>
                                        <th>Numéro</th>
                                        {dataEpreuve.map((d, i) => (
                                            <th key={d.libelleEpreuve}> {d.libelleEpreuve}</th>
                                        ))}
                                        <th>Moyenne</th>
                                        {dataChoix.map((c, i) => (
                                            <th key={c.libelleChoix}> {c.libelleChoix}</th>
                                        ))}
                                        <th>Serie</th>
                                        <th>Mention</th>
                                        <th>Annee</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items[tR]
                                        // .sort(function (a, b) {
                                        //   if (a.convocation.numero < b.convocation.numero) {
                                        //     return -1;
                                        //   } else {
                                        //     return 1;
                                        //   }
                                        // })
                                        .map((c, i) => (
                                            <tr key={`${tR}_${c.convocation.numero}_${i}`}>
                                                <td>{i + 1}</td>
                                                <td>
                                                    <b>{c.convocation.numero}</b>
                                                </td>
                                                {dataEpreuve.map((d, i) => (
                                                    <td key={d.libelleEpreuve + "_" + i}>
                                                        {getNote(c, d.codeEpreuve)}
                                                    </td>
                                                ))}
                                                <td>{c.moyenne}</td>
                                                {dataChoix.map((ch, i) => (
                                                    <td key={ch.libelleChoix + "_" + i}>{getChoix(c, ch.codeChoix)}</td>
                                                ))}
                                                <td>{c.codeSerie.libelleSerie}</td>
                                                <td>{c.mention}</td>
                                                <td>{c.anneeBacc}</td>
                                                <td>
                                                    {tR !== "AP" && (
                                                        <Tooltip
                                                            title="Envoyer AP"
                                                            placement="bottom"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                changeTypeResultat(tR, "AP", c);
                                                            }}
                                                        >
                                                            <IconButton>
                                                                <SendIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                    {tR !== "LP" && (
                                                        <Tooltip
                                                            title="Envoyer LP"
                                                            placement="bottom"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                changeTypeResultat(tR, "LP", c);
                                                            }}
                                                        >
                                                            <IconButton>
                                                                <SendIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                    {tR !== "AF" && (
                                                        <Tooltip
                                                            title="Envoyer AF"
                                                            placement="bottom"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                changeTypeResultat(tR, "AF", c);
                                                            }}
                                                        >
                                                            <IconButton>
                                                                <SendIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}

                                                    {tR !== "LF" && (
                                                        <Tooltip
                                                            title="Envoyer LF"
                                                            placement="bottom"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                changeTypeResultat(tR, "LF", c);
                                                            }}
                                                        >
                                                            <IconButton>
                                                                <SendIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}

                                                    {tR !== "AT" && (
                                                        <Tooltip
                                                            title="Envoyer AT"
                                                            placement="bottom"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                changeTypeResultat(tR, "AT", c);
                                                            }}
                                                        >
                                                            <IconButton>
                                                                <SendIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                    {tR !== "LT" && (
                                                        <Tooltip
                                                            title="Envoyer LT"
                                                            placement="bottom"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                changeTypeResultat(tR, "LT", c);
                                                            }}
                                                        >
                                                            <IconButton>
                                                                <SendIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    ))}
                </div>
            ))}
            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    .btn-pagination {
        display: flex;
        justify-content: space-between;
        text-align: center;
        margin-top: 20px;
    }
    .contingent {
        margin-top: 10px;

        .note {
            height: 100%;
            display: flex;
            justify-content: space-between;

            input {
                width: 100%;
                height: 45px;
                border: none;
                padding-left: 10px;
                font-size: 16px;
                font-weight: bold;
                color: black;
                background-color: transparent;
            }
        }

        table {
            font-family: arial, sans-serif;
            border-collapse: collapse;
            width: 100%;
        }

        td,
        th {
            border: 1px solid #dddddd;
            text-align: left;
            padding: 8px;
        }

        tr:nth-child(even) {
            // background-color: #dddddd;
        }
    }
    .container {
        display: flex;
        flex-direction: column;
        width: 100%;
        .table {
            margin-bottom: 20px;
            table,
            td,
            th {
                border: 1px solid #bababa;
                text-align: center;
            }
            table {
                border-collapse: collapse;
                width: 100%;
                th {
                    height: 45px;
                    background-color: ${styles.colors.drawerBg};
                    color: white;
                }
                tr {
                    height: 45px;
                    &:hover,
                    &:nth-child(even) {
                        background-color: #f2f2f2;
                        /* background-color: white; */
                    }
                }
            }
        }
    }
`;

export default GesionNote;
