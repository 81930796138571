import React from 'react'
import styled from 'styled-components'
import * as styles from '../../../styles/variables'

import HeroImg from '../../../assets/img/hero-img.png'
import Trapez from '../../../assets/img/geometry-form/trapez.png'
import Hexagone from '../../../assets/img/geometry-form/hexagone.png'
import OrangeTriangle from '../../../assets/img/geometry-form/triangle-orange.png'

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Link } from 'react-router-dom'

import Arrete from '../../../assets/documents/Arrete-Concours-ENI-2022-2023.pdf'



const Hero = () => {
  return (
    <HeroWrapper>
        <div  className='hero__text'>
            <div className='hero__text__mobile'>
                <h1>Intégrer la pépinière des <br /><span className='highlight'>élites informaticiennes</span> <br />
                    Malagasy
                </h1>
                <div className="btn__container">
                    <a href={Arrete} target="_blank" className='info-btn'>
                        En savoir plus
                    </a>
                    <Link to="inscription" className='go-to-form-btn'>
                        Accéder au formulaire d'inscription
                        <ChevronRightIcon />
                    </Link>
                </div>
                <img src={Hexagone} alt='hexagone' className='hexagone'  />
            </div>
        </div>
        <div className="hero__img">
            <img src={HeroImg} alt="hero" />
            <img src={Trapez} alt='trapez' className='trapez' />
        </div>
    </HeroWrapper>
  )
}

const HeroWrapper = styled.div`
    height: calc(90vh - ${styles.size.navHeight});
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    
    @media(max-width: ${styles.breakpoints.lg}){
        flex-direction: column;
        height: 65vh;
        justify-content: center;
    }
    @media(max-width: ${styles.breakpoints.sm}){
        height: 70vh;
        justify-content: space-around;
    }
    .col{
        flex: 1;
    }
    .hero__img{
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        position: relative;
        & img{
           height: auto;
        }
        @media(max-width: ${styles.breakpoints.lg}){
         display: none;
        }
        .trapez{
            position: absolute;
            width: 9vw;
            height: auto;
            bottom: 10%;
            right: 30%;
        }
    }
    .hero__text{
        
        .hexagone{
            display: none;
            @media(max-width: ${styles.breakpoints.sm}){
                width: 100px;
                height: auto;
                position: absolute;
                right: 0;
                z-index: -1;
                bottom: -5vh;
                display: block;
            }
        }

        margin: 0 5%;
        
        min-width: 400px;
        width: 100%;
        display: flex;
        justify-content: center;
        @media(max-width: ${styles.breakpoints.lg}){
            min-width: auto;
            padding: 0 5%;
        }
        &__mobile{
            @media(max-width: ${styles.breakpoints.sm}){
                width: 100%;
            }
        }
        /* border: 1px solid red; */
        h1{
            font-size: 2.5vw;
            margin-top: -10vh;
            margin-bottom: 10vh;
            @media(max-width: ${styles.breakpoints.lg}){
                font-size: 2.2em;
            }
            @media(max-width: ${styles.breakpoints.sm}){
                font-size: 1.7em;
                margin-top: inherit;
            }
            .highlight{
                position: relative;
            }
            .highlight::after{
                content: '';
                position: absolute;
                display: flex;
                width: 100%;
                height: 25%;
                bottom: 15%;
                z-index: -1;
                background-color: #48FFFF;
            }
        }
    }
    .btn__container{
        display: flex;
        @media(max-width: ${styles.breakpoints.sm}){
            flex-direction: column;
        }

        .info-btn{
            background-color: none;
            height: 44px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid ${styles.colors.darkBlue};
            border-radius: 12px;
            text-decoration: none;
            color: ${styles.colors.darkBlue};
            font-weight: 700;
            padding: 0 20px;
            text-align: center;
            margin-right: 20px;
            font-size: 12px;

            @media(max-width: ${styles.breakpoints.sm}){
                margin: 0;
                margin-bottom: 10px;
                
            }
        }

        .go-to-form-btn{
            background-color: ${styles.colors.lightBlue};
            height: 44px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            border-radius: 12px;
            text-decoration: none;
            color: #fff;
            font-size: 12px;
            padding: 0 20px;
            font-weight: 600;
        }

    }
    
`

export default Hero