import React, { useState, useEffect } from "react";
import { Button, TextField } from "@mui/material";
import CreateDialog from "components/Dashboard/CentreBacc/CreateDialog";
import CentreBaccTable from "components/Dashboard/CentreBacc/CentreBaccTable";
import axios from "axios";


const CentreBacc = () => {

  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [data, setData] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const fetchData = () => {
    axios.get('/centrebacc')
      .then(res => {
        setData(res.data);
        setFiltered(res.data)
      })
  }

  const handleSearch = () => {
    let filteredTmp = data.filter((data) => {
      if (
        data.nomCentreBacc.toLowerCase().includes(searchQuery.toLowerCase()) ||
        String(data.codeCentreBacc).includes(searchQuery)
      ) {
        return data;
      }
    });
    setFiltered(filteredTmp);
  };

  useEffect(() => {
    fetchData();
  }, [])
  

    return (
      <>
      <div className="search">
        <TextField
          margin="dense"
          id="search"
          label="Recherche par Nom Centre"
          type="text"
          fullWidth
          variant="standard"
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
          onKeyUp={handleSearch}
          sx={{
            maxWidth: "500px",
          }}
        />
      </div>
      <div>
        <Button variant='contained' onClick={() => setOpenCreateDialog(true)}>Ajouter</Button>
        <CreateDialog 
          open={openCreateDialog}
          onClose={() => setOpenCreateDialog(false)}
          fetchData={fetchData}
        />
      </div>
        <CentreBaccTable data={data} filtered={filtered} fetchData={fetchData} />
      </>
    );
}

export default CentreBacc;
