const formatDateRange = (dateStart, dateEnd) => {
  const daysOfWeek = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
  const monthsOfYear = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ];

  const startDate = new Date(dateStart);
  const endDate = new Date(dateEnd);

  const startDayOfWeek = daysOfWeek[startDate.getUTCDay()];
  const startDay = startDate.getUTCDate();
  const startMonth = monthsOfYear[startDate.getUTCMonth()];

  const endDayOfWeek = daysOfWeek[endDate.getUTCDay()];
  const endDay = endDate.getUTCDate();
  const endMonth = monthsOfYear[endDate.getUTCMonth()];
  const year = endDate.getUTCFullYear();

  if (startMonth === endMonth && startDay !== endDay) {
    return `${startDayOfWeek} ${startDay} et ${endDayOfWeek} ${endDay} ${startMonth} ${year}`;
  } else if (startMonth === endMonth && startDay === endDay) {
    return `${startDayOfWeek} ${startDay} ${startMonth} ${year}`;
  } else {
    return `${startDayOfWeek} ${startDay} ${startMonth} et ${endDayOfWeek} ${endDay} ${endMonth} ${year}`;
  }
};

const compare = (a, b) => {
  if (a.text < b.text) return -1;
  if (a.text > b.text) return 1;
  return 0;
};

export { formatDateRange, compare };
