import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import CreateDialog from "components/Dashboard/Choix/CreateDialog";
import ChoixTable from "components/Dashboard/Choix/ChoixTable";
import axios from "axios";
import { apiRoute } from "components/Dashboard/GlobalVariable";
import { useEffect } from "react";


const Choix = () => {

  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [data, setData] = useState();
  const [filtered, setFiltered] = useState();
  const [searchQuery, setSearchQuery] = useState("");

  const fetchData = () => {
    axios.get(`/choix`)
      .then(res => {
        setData(res.data)
        setFiltered(res.data)
      })
  }

  const handleSearch = () => {
    let filteredTmp = data.filter((data) => {
      if(data.libelleChoix.toLowerCase().includes(searchQuery.toLowerCase())){
        return data;
      }
    });
    setFiltered(filteredTmp);
  }

  useEffect(() => {
    fetchData();
  }, [])

    return (
      <>
      <div>
        <Button variant='contained' onClick={() => setOpenCreateDialog(true)}>Ajouter</Button>
        <CreateDialog 
          open={openCreateDialog}
          onClose={() => setOpenCreateDialog(false)}
          fetchData={fetchData}
        />
      </div>
      <div className="search">
        <TextField
          margin="dense"
          id="search"
          label="Recherche par Choix"
          type="text"
          fullWidth
          variant="standard"
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
          onKeyUp={handleSearch}
          sx={{
            maxWidth: '500px'
          }}
        />
      </div>
        <ChoixTable fetchData={fetchData} data={data} filtered={filtered} />
      </>
    );
}

export default Choix;
