import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { apiRoute } from '../GlobalVariable';
import * as moment from 'moment';
import 'moment/locale/fr';
import SuccessSnackBar from '../Snackbar/SuccessSnackBar';
import styled from 'styled-components';
moment.locale('fr');

export default function EditDialog(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [openSnack, setOpenSnack] = useState(false);
  const [detailParametre, setDetailParametre] = useState({});
  const [formData, setFormData] = useState({
    anneeUniv: '',
    dateDebutConcours: '',
    dateFermetureInscription: '',
    dateFinConcours: '',
    dateOuvertureInscription: '',
    numCpteENI: '',
  });

  //Quand on change la valeur dans l'inout
  const handleChangeFormData = (event) => {
    const { value, name, type } = event.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: type === 'number' ? parseFloat(value) : value,
      };
    });
  };

  //Sauvearder paramètre
  const handleSubmit = (e) => {
    e.preventDefault();
    axios.put(`/parametres/${props.id}`, formData).then((res) => {
      setOpenSnack(true);
      props.handleChangeDataParametre(formData, 'update');
    });
  };

  useEffect(() => {
    axios
      .get(`/parametres/${props.id}`)
      .then((res) => {
        setDetailParametre(res.data);
        setFormData({
          ...res.data,
          dateDebutConcours: moment(res.data.dateDebutConcours).format('YYYY-MM-DD'),
          dateFermetureInscription: moment(res.data.dateFermetureInscription).format('YYYY-MM-DD'),
          dateFinConcours: moment(res.data.dateFinConcours).format('YYYY-MM-DD'),
          dateOuvertureInscription: moment(res.data.dateOuvertureInscription).format('YYYY-MM-DD'),
        });
      })
      .catch((err) => {});
  }, []);

  return (
    <div>
      <Dialog open={props.open} onClose={props.onClose} fullWidth={true}>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <DialogTitle>Modification paramètre</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="anneeUniv"
              label="Année Universitaire"
              type="text"
              name="anneeUniv"
              fullWidth
              required
              value={formData.anneeUniv}
              onChange={handleChangeFormData}
            />
            <h3>Période d'inscription</h3>
            <TextField
              id="ouverture"
              label="Date ouverture inscription"
              type="date"
              fullWidth
              margin="dense"
              name="dateOuvertureInscription"
              required
              InputLabelProps={{
                shrink: true,
              }}
              value={formData.dateOuvertureInscription}
              onChange={handleChangeFormData}
            />
            <TextField
              margin="dense"
              id="fermeture"
              label="Date fermeture inscription"
              type="date"
              fullWidth
              required
              name="dateFermetureInscription"
              InputLabelProps={{
                shrink: true,
              }}
              value={formData.dateFermetureInscription}
              onChange={handleChangeFormData}
            />
            <h3>Dates concours</h3>
            <TextField
              id="dateDebutConcours"
              label="Date début concours"
              type="date"
              fullWidth
              margin="dense"
              required
              name="dateDebutConcours"
              InputLabelProps={{
                shrink: true,
              }}
              value={formData.dateDebutConcours}
              onChange={handleChangeFormData}
            />
            <TextField
              id="dateFinConcours"
              label="Date fin concours"
              type="date"
              fullWidth
              margin="dense"
              required
              name="dateFinConcours"
              InputLabelProps={{
                shrink: true,
              }}
              value={formData.dateFinConcours}
              onChange={handleChangeFormData}
            />
            <h3>Information compte ENI</h3>
            <TextField
              margin="dense"
              id="numCpteENI"
              label="N° de compte ENI"
              type="text"
              fullWidth
              name="numCpteENI"
              required
              value={formData.numCpteENI}
              onChange={handleChangeFormData}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onClose}>Annuler</Button>
            <Button type="submit" color="primary" variant="contained">
              Modifier
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
      <SuccessSnackBar open={openSnack} message="Paramètre modifié avec succès!" close={() => setOpenSnack(false)} />
    </div>
  );
}

const Form = styled.form`
  h3 {
    font-size: 0.9em;
  }
`;
