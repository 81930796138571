import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { apiRoute, token } from '../GlobalVariable';
import SuccessSnackBar from '../Snackbar/SuccessSnackBar';

const CreateDialog = (props) => {
  const [libelleSerie, setLibelleSerie] = useState('');
  const [openSnack, setOpenSnack] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(`/series`, {
        libelleSerie: libelleSerie,
      })
      .then((res) => {
        props.onClose();
        props.fetchData();
        setOpenSnack(true);
      })
      .catch((err) => {});
  };

  return (
    <div>
      <Dialog open={props.open} onClose={props.onClose}>
        <form onSubmit={(e) => handleSubmit(e)}>
          <DialogTitle>Ajouter une nouvelle série</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="libelleSerie"
              label="Libellé série"
              type="text"
              fullWidth
              onChange={(e) => setLibelleSerie(e.target.value)}
              variant="outlined"
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onClose}>Annuler</Button>
            <Button type="submit" color="primary" variant="contained">
              Ajouter
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <SuccessSnackBar open={openSnack} message="Série ajoutée avec succès!" close={() => setOpenSnack(false)} />
    </div>
  );
};

export default CreateDialog;
