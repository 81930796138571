import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import * as styles from 'styles/variables';
import FormButton from 'components/FormUI/Button';
import Select from 'components/FormUI/Select';
import TextField from 'components/FormUI/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { FormContext } from '../../FormContext';
import { useFormikContext } from 'formik';
import axios from 'axios';
import { apiRoute } from 'components/Dashboard/GlobalVariable';

export default function Inscription({
  nextAccordion,
  prevAccordion,
  setIsValids,
  series,
  centresConcours,
  deuxiemeChoix,
  choix,
  typeCandidat,
  setDeuxiemeChoix,
  infoNiveauxSelected,
  ...otherProps
}) {
  const { setExpanded } = useContext(FormContext);
  const { errors, values, setFieldValue } = useFormikContext();
  const [isShowSecondChoice, setIsShowSecondChoice] = useState(true);
  const [fitleredSecondChoice, setFilteredSecondChoice] = useState(deuxiemeChoix || []);
  const [fitleredFirstChoice, setFilteredFirstChoice] = useState(choix || []);

  /**
   * LIFECYCLE
   */
  useEffect(() => {
    if (!values.premierChoix || !infoNiveauxSelected) return;
    const filterChoice = fitleredFirstChoice.find((c) => c.value === values.premierChoix);
    if (filterChoice) {
      const isIncludeMdi = filterChoice.text.includes('Métier digital');
      if (isIncludeMdi) {
        setIsShowSecondChoice(false);
        setFilteredSecondChoice([]);
        setFieldValue('deuxiemeChoix', null);
      } else {
        setIsShowSecondChoice(true);

        setFilteredSecondChoice(() => {
          if (fitleredFirstChoice) {
            return fitleredFirstChoice.filter(
              (c) => c.value !== values.premierChoix && !c.text.includes('Métier digital')
            );
          } else return fitleredFirstChoice;
        });
      }
    }
  }, [values.premierChoix]);

  useEffect(() => {
    if ((!values.codeSerie && series?.length < 1) || !infoNiveauxSelected) return;
    const { series } = infoNiveauxSelected;
    const filterChoix = series.find((s) => s.codeSerie === values.codeSerie);
    setFilteredFirstChoice(mapToOptions(filterChoix.choix, 'codeChoix', 'libelleChoix'));
  }, [values.codeSerie, infoNiveauxSelected]);

  /**
   * FUNCTION
   */
  const mapToOptions = (items, valueKey, textKey) =>
    items.map((item) => ({
      value: item[valueKey],
      text: item[textKey],
    }));

  return (
    <Wrapper>
      <div className="content">
        <div className="premiereAnnee">
          <h3>A- Première année</h3>
          <ol>
            <li>
              Parcours: Professionnel: Génie Logiciel et Base de Données (GB) ou Administration des Systèmes et Réseaux
              (SR)
              <ul>
                <li>
                  <strong>Centre d’étude : FIANARANTSOA (possibilité 1) </strong> <br />
                  <br />{' '}
                </li>
              </ul>
            </li>
            <li>
              Parcours: Informatique Générale (Hybride)
              <ul>
                <li>
                  <strong>Centre d’étude : FIANARANTSOA (possibilité 2) </strong>
                </li>
                <li>
                  <strong>Centre d’étude : TOLIARA (possibilité 3) </strong>
                </li>
              </ul>
            </li>
          </ol>
          <br />
          <br />
          <h4>Choix du parcours</h4>
          <div className="fields">
            {fitleredFirstChoice ? (
              <Select
                name="premierChoix"
                label="Premier choix *"
                tooltip="Premier choix"
                options={fitleredFirstChoice}
                className="select"
              />
            ) : (
              ''
            )}
            {(fitleredSecondChoice && fitleredFirstChoice.length === 1) || fitleredSecondChoice.length === 0 ? (
              <></>
            ) : (
              <Select
                name="deuxiemeChoix"
                label="Deuxième choix *"
                tooltip="Deuxième choix"
                options={fitleredSecondChoice}
                className="select"
                rea
              />
            )}
          </div>
        </div>

        <br />
        <br />

        <div className="centre">
          <h3>B- CENTRE D’EXAMEN CHOISI *</h3>
          <div className="fields">
            {centresConcours ? <Select name="codeCentre" options={centresConcours} className="select" /> : ''}
          </div>
        </div>

        <div className="centre">
          <h3>B- Type de candidat *</h3>
          <div className="fields">
            {typeCandidat ? <Select name="codeTypeCand" options={typeCandidat} className="select" /> : ''}
          </div>
        </div>

        <br />
        <br />
        <div className="droit">
          <h3>C- Droit d’inscription au concours *</h3>
          <div className="fields bordereau">
            <TextField
              name="numBord"
              label="N° Bordereau de versement * "
              tooltip="N° Bordereau de versement *"
              className="numBordereau"
            />
            <TextField name="dateBord" label="Délivrée le *" tooltip="Date de délivrance du bordereau" type="date" />
            <TextField name="agenceBord" label="à *" tooltip="Lieu de délivrance du bordereau" />
          </div>
        </div>
      </div>

      <div className="btn-section">
        <FormButton
          color="secondary"
          onClick={() => {
            setExpanded(prevAccordion);
          }}
        >
          Précédent
        </FormButton>
        <FormButton
          color="primary"
          onClick={() => {
            setExpanded(nextAccordion);
          }}
        >
          Suivant
        </FormButton>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .fields {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    .field,
    .select {
      max-width: 450px;
      width: 100%;
    }
    &.bordereau {
      margin-top: 40px;
      margin-bottom: 50px;
      .field {
        width: inherit;
        &.numBordereau {
          max-width: 450px;
          width: 100%;
        }
      }
    }
  }
`;
