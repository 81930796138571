import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import * as styles from 'styles/variables';


export default function UploadProgress(props) {
  return (
    <Wrapper>
      <>
        <CircularProgress className='circular' variant="determinate" {...props} />
        <div className="percentage">
          {`${Math.round(props.value)}%`}
        </div>

        <div className="done">
          <h1>{props.value < 100 ? "Envoi en cours..." : "Inscription envoyée"}</h1>
          {
            props.value == 100 ?
              <>
                <p className='text'>
                  Après soumission de vos dossiers en ligne, il est obligatoire de faire parvenir vos dossiers physiques à la scolarité de l'ENI. A envoyer par poste si vous vous trouvez en province
                </p>
                <a href="/">Retourner à la page d'accueil</a>
              </> : ""
          }
        </div>

      </>
    </Wrapper>
  );
}

const Wrapper = styled.div`
    position: relative;
    /* width: max-content; */
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid red; 
    margin-top: -10vh;
    padding: 50px 3%;
    background: white;
    border-radius: 30px;
    border: 1px solid #d9d9d9;
    .circular{
        width: 150px !important;
        height: 150px !important;
        top: 30px;
        background: white;
        // border: 1px solid #1976D2;
        border-radius: 50%;
    }
    .percentage{
        font-size: 2.2em;
        font-weight: bold;
        position: absolute;
        top: 102px;
        left: 50%;
        transform: translateX(-50%);
    }
    .done{
      text-align: center;
      h1{
        text-align: center;
      }
      .text{
        max-width: 600px;
        text-align: center;
        margin-bottom: 50px;
        margin-top: 50px;
        font-weight: bold;
      }
      a{
        text-decoration: none;
        padding: 15px 30px;
        color: white;
        background-color: ${styles.colors.success};
        border-radius: 12px;
        transition: all .2s ease;
        &:hover{
          background-color: #1a9966;
        }
      }
    }
`;