import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import * as styles from 'styles/variables';
import FormButton from 'components/FormUI/Button';
import { FormContext } from '../../FormContext';
import InputFile from 'components/FormUI/InputFile';
import { useFormikContext } from 'formik';

export default function Inscription({ prevAccordion, attachments, ...otherProps }) {
  const { setExpanded } = useContext(FormContext);
  const { errors, setErrors } = useFormikContext();
  return (
    <Wrapper>
      <p>
        Documents scannés au format jpg, jpeg ou png (images) - 30 Mo max
        <br />
        <br />
      </p>
      <div className="files">
        {attachments &&
          attachments.map((attachment, index) => (
            <InputFile
              key={`attachment_${index}`}
              className="section"
              label={`${index + 1} - ${attachment.name} ${attachment.required && '*'} `}
              name={attachment.name_code}
              required
            />
          ))}
      </div>

      <div className="btn-section">
        <FormButton
          color="secondary"
          onClick={() => {
            setExpanded(prevAccordion);
          }}
        >
          Précédent
        </FormButton>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .files {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .section {
      width: 47%;
    }
  }
`;
