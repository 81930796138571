import React, {useContext} from 'react';
import styled from 'styled-components';
import * as styles from 'styles/variables';
import ValidationIcon from 'components/FormUI/ValidationIcon';
import { FormContext } from '../FormContext';

export default function NavItem(props) {
  const {setExpanded, expanded} = useContext(FormContext);
  const handleClick = (e) => {
    e.preventDefault();
    setExpanded(props.target);
  };
  return (
    <Item
      href={"#"+props.target}
      className={`${props.target==expanded ? "active" : ""}`}
      onClick={handleClick}

    >
      <span>
        <ValidationIcon isValid={props.isValid} className="icon" />
        <span className="text">{props.children}</span>
      </span>
      <div className="icon">
        <img src={props.icon} />
      </div>
    </Item>
  );
}

const Item = styled.a`
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    display: flex;
    gap: 20px;
    justify-content: right;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    z-index: 1;
    span{
      transition: all .2s ease;
      text-align: right;
      display: flex;
      align-items: center;
      .text{
        opacity: .66;
      }
      .icon{
        margin-right: 10px;
        /* background-color: white; */
        border-radius: 50%;
      }
    }
    &>.icon{
      width: 52px;
      height: 52px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #494A7D;
      border-radius: 50%;
      transition: background .2s ease;
      img{
        user-select: none;
      }
    }
    &.active, &:hover{
      span .text{
        opacity: 1;
      }
      &>.icon{
        background-color: ${styles.colors.success};
      }
    }
`;