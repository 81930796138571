import React from 'react';
import ImageGallery from './ImageGallery'; // Assurez-vous que le chemin est correct

const CandidatAttachments = ({ candidat, apiRouteFile }) => {
  return (
    <div>
      {candidat && candidat.attachments.length > 0 && (
        <ImageGallery attachments={candidat.attachments} apiRouteFile={apiRouteFile} />
      )}
    </div>
  );
};

export default CandidatAttachments;
