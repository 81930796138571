import React from 'react';
import styled from 'styled-components';

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
`;

const PaginationButton = styled.button`
  padding: 10px 15px;
  margin: 0 5px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const Pagination = ({
  currentPage,
  totalPages,
  handlePreviousPage,
  handleNextPage,
  handleFirstPage,
  handleLastPage,
}) => (
  <PaginationWrapper>
    <PaginationButton onClick={handleFirstPage} disabled={currentPage === 1}>
      Début
    </PaginationButton>
    <PaginationButton onClick={handlePreviousPage} disabled={currentPage === 1}>
      Précédent
    </PaginationButton>
    <span>
      Page {currentPage} sur {totalPages}
    </span>
    <PaginationButton onClick={handleNextPage} disabled={currentPage === totalPages}>
      Suivant
    </PaginationButton>
    <PaginationButton onClick={handleLastPage} disabled={currentPage === totalPages}>
      Fin
    </PaginationButton>
  </PaginationWrapper>
);

export default Pagination;
