import axios from 'axios';
import NoteTable from 'components/Dashboard/Note/NoteTable';
import React, { useEffect, useState } from 'react';
import SelectComponent from 'shared/designSystem/SelectComponent';
import styled from 'styled-components';
import * as styles from 'styles/variables';

const Note = (props) => {
  /**
   * VARIABLE
   */
  const [choix, setChoix] = useState([]);
  const [listNiveaux, setListNiveaux] = useState([]);
  const [dataEpreuve, setDataEpreuve] = useState([]);
  const [filterCandidat, setFilterCandidat] = useState({
    niveau: '',
    parcours: '',
    matiere: '',
  });

  /**
   * LIFECYCLE
   */
  useEffect(() => {
    filterCandidatForNote();
  }, [filterCandidat]);

  /**
   * FUNCTION
   */
  const handleChangeFilterNiveaux = (e) => {
    const { value } = e.target;
    const niveau = listNiveaux.find((niveau) => {
      return +niveau.id === +value;
    });
    if (!niveau) return;
    const mapValue = niveau.choix.map((c) => {
      return {
        value: c.codeChoix,
        label: c.libelleChoix,
      };
    });
    setChoix(mapValue);
    setFilterCandidat((oldValue) => {
      return {
        ...oldValue,
        niveau: value,
      };
    });
  };
  const handleChangeFilterParcours = (e) => {
    const { value } = e.target;
    setFilterCandidat((oldValue) => {
      return {
        ...oldValue,
        matiere: value,
      };
    });
  };

  const handleChangeMatiere = (e) => {
    const { value } = e.target;
    setFilterCandidat((oldValue) => {
      return {
        ...oldValue,
        matiere: value,
      };
    });
  };

  /**
   * LIFECYCLE
   */
  useEffect(() => {
    getAllNiveau();
  }, []);

  /**
   * API
   */
  const getAllNiveau = () => {
    axios.get('/niveaux').then(
      (res) => {
        setListNiveaux(res.data);
      },
      (error) => {
        console.error('error');
      }
    );
  };

  const filterCandidatForNote = () => {
    axios.post(`/candidat/filter/candidat-note`, { ...filterCandidat }).then(
      (res) => {
        console.log(res.data);
      },
      (error) => {}
    );
  };

  return (
    <Wrapper>
      <div className="header">
        {/* AFFICHER LISTE PAR NIVEAU */}
        <SelectComponent
          placeholder="Afficher par niveau"
          onChange={handleChangeFilterNiveaux}
          options={[
            { value: '', label: '-' },
            ...listNiveaux.map((niveau) => ({
              value: niveau.id,
              label: niveau.nom,
            })),
          ]}
          className="select-filter"
        />
        <SelectComponent
          placeholder="Afficher"
          onChange={handleChangeFilterParcours}
          options={[{ value: '', label: '-' }, ...choix]}
          className="select-filter"
        />

        <SelectComponent
          placeholder="Matiere"
          onChange={handleChangeMatiere}
          options={[
            { value: '', label: '-' },
            ...dataEpreuve.map((epreuve) => ({
              value: epreuve.codeEpreuve,
              label: epreuve.libelleEpreuve,
            })),
          ]}
          className="select-filter"
        />
      </div>
    </Wrapper>
  );
};

export default Note;

const Wrapper = styled.div`
  max-width: 1000px;
  margin: auto;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 30px;
  }
  padding: 10px;
  /* table,
td,
th {
  border: 1px solid black;
}

.table {
  text-align: center;
}

table {
  border-collapse: collapse;
}

td {
  text-align: center;
  height: 30px;
  width: 33%;
  padding: 5px;
}

th {
  height: 50px;
} */
  .select-matiere {
    min-width: 20%;
    margin-bottom: 10px;
  }
  .bouton-valider {
    margin: 10px;
    display: flex;
    justify-content: space-between;
  }
  table,
  td,
  th {
    border: 1px solid #bababa;
    text-align: center;
  }
  table {
    border-collapse: collapse;
    width: 100%;
    th {
      height: 45px;
      background-color: ${styles.colors.drawerBg};
      color: white;
    }
    tr {
      &:hover,
      &:nth-child(even) {
        background-color: #f2f2f2;
        /* background-color: white; */
      }
    }
    .note {
      height: 100%;
      display: flex;
      justify-content: space-between;

      input {
        width: 100%;
        height: 45px;
        border: none;
        padding-left: 10px;
        font-size: 16px;
        font-weight: bold;
        color: black;
        background-color: transparent;
      }
    }
  }
  .btn-pagination {
    margin-top: 30px;
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
  }
`;
