import React, { useState, useEffect } from 'react';
import { StyledOption, StyledSelect } from 'styles/candidat/Select.styled';

const SelectComponent = ({ defaultValue = '', options, onChange, ...props }) => {
  /**
   * VARIABLE
   */
  const [value, setValue] = useState(defaultValue);

  /**
   * LIFECYCLE
   * Met à jour la valeur de l'état si `defaultValue` change
   */
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  /**
   * FUNCTION
   */
  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);

    // Appelle également la fonction onChange du parent, si fournie
    if (onChange) {
      onChange(e); // Passer l'événement complet pour plus de flexibilité
    }
  };

  return (
    <StyledSelect {...props} value={value} onChange={handleChange}>
      {options.map((option, index) => (
        <StyledOption key={index} value={option.value}>
          {option.label}
        </StyledOption>
      ))}
    </StyledSelect>
  );
};

export default SelectComponent;
