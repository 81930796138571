import React from 'react';
import styled from 'styled-components';
import {
  FormControl,
  Checkbox,
  FormControlLabel,
  FormHelperText
} from '@mui/material';
import { useField, useFormikContext } from 'formik';

export default function CheckboxWrapper({ name, setDateNaissInconnue, ...otherProps }) {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = evt => {
    const { checked } = evt.target;
    setFieldValue(name, checked);
  };
  const configCheckbox = {
    ...otherProps,
    ...field,
    onChange: handleChange,
  };

  const configFormControl = {};
  if (meta && meta.touched && meta.error) {
    configFormControl.error = true;
    configFormControl.helperText = meta.error;
  }
  return (
    <Wrapper>
      <FormControl {...configFormControl}>
        <FormControlLabel
          control={<Checkbox {...configCheckbox} />}
          label={configCheckbox.label}
        />
        {
          configFormControl.error ? <FormHelperText>{configFormControl.helperText}</FormHelperText> : ''
        }
      </FormControl>
    </Wrapper>
  );
}

const Wrapper = styled.div`

`;