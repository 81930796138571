import { Button } from '@mui/material';
import axios from 'axios';
import React, { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';
import styled from 'styled-components';
import TableEffectifPerProvince from './components/TableEffectifPerProvince';
import TableContigence from './components/TableContigence';
import TableContigenceAdjuste from './components/TableContigenceAdjuste';

const CalculContigence = (props) => {
  /**
   * VARIABLE
   */
  const [contigences, setContigences] = useState();
  const [loading, setLoading] = useState(false);

  /**
   * LIFECYCLE
   */
  useEffect(() => {
    fetchContigence();
  }, []);

  /**
   * API
   */
  const fetchContigence = () => {
    setLoading(true);
    axios
      .get(`/candidat/contigence/province`)
      .then((res) => {
        setContigences(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Wrapper>
      {loading ? (
        <>Chargement ...</>
      ) : (
        contigences && (
          <div style={{ paddingBottom: 25 }}>
            <h2>Effectif par province et par premiere choix</h2>
            <TableEffectifPerProvince contigences={contigences.dataArray} totalPerChoice={contigences.totalPerChoice} />
            <h2>Contigent théorique</h2>
            <TableContigence
              contigences={contigences.dataArray}
              totalContigencePerChoice={contigences.totalContigencePerChoice}
            />
            <h2>Contigent ajustement</h2>
            <TableContigenceAdjuste contigences={contigences} />
          </div>
        )
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
    // background-color: #dddddd;
  }
`;

export default CalculContigence;
