import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import * as styles from 'styles/variables';
import FormButton from 'components/FormUI/Button';
import Select from 'components/FormUI/Select';
import TextField from 'components/FormUI/TextField';
import { FormContext } from '../../FormContext';
import { useFormikContext } from 'formik';
import axios from 'axios';
import { apiRoute } from 'components/Dashboard/GlobalVariable';
import { TextMaskCustomNumeroBacc } from 'utility/maskInput';

export default function Bacc({
  setExpandedAccordion,
  nextAccordion,
  prevAccordion,
  setIsValids,
  series,
  centres,
  ...otherProps
}) {
  const [mentions, setMentions] = useState([
    {
      value: 'passable',
      text: 'Passable',
    },
    {
      value: 'AB',
      text: 'Assez-bien',
    },
    {
      value: 'B',
      text: 'Bien',
    },
    {
      value: 'TB',
      text: 'Très bien',
    },
  ]);

  return (
    <Wrapper>
      <div className="fields">
        {series ? (
          <Select name="codeSerie" label="Série *" tooltip="Série *" options={series} className="select" />
        ) : (
          ''
        )}
        <TextField
          name="numBacc"
          label="Numéro *"
          tooltip="Numéro"
          type="number"
          inputComponent={TextMaskCustomNumeroBacc}
        />
        <TextField
          name="anneeBacc"
          label="Session *"
          tooltip="Session (année)"
          type="number"
          placeholder="numéro à 7 chiffres"
        />
        {centres ? (
          <Select name="codeCentreBacc" label="Centre *" tooltip="Centre *" options={centres} className="select" />
        ) : (
          ''
        )}
        {mentions ? (
          <Select
            name="mention"
            label="Mention *"
            tooltip="Mention obtenue au Bac"
            options={mentions}
            className="select"
          />
        ) : (
          ''
        )}
      </div>

      <div className="btn-section">
        <FormButton
          color="secondary"
          onClick={() => {
            setExpandedAccordion(prevAccordion);
          }}
        >
          Précédent
        </FormButton>
        <FormButton
          color="primary"
          onClick={() => {
            setExpandedAccordion(nextAccordion);
          }}
        >
          Suivant
        </FormButton>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .fields {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    .field,
    .select {
      max-width: 450px;
      width: 100%;
    }
  }
`;
