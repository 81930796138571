// CustomDataTable.js
import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';

// Création d'un composant stylé pour le champ de recherche
const SearchInput = styled.input`
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  max-width: 300px;
`;

// Composant réutilisable pour la DataTable avec gestion de la recherche
const CustomDataTable = ({ data, columns, conditionalRowStyles }) => {
  const [filterText, setFilterText] = useState('');

  // Filtrer les données selon le texte de recherche
  const filteredData = data.filter((item) => {
    // Vous pouvez adapter cette logique pour inclure d'autres champs
    return Object.values(item).join(' ').toLowerCase().includes(filterText.toLowerCase());
  });

  return (
    <div>
      <SearchInput
        type="text"
        placeholder="Rechercher..."
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
      />
      <DataTable
        columns={columns}
        data={filteredData}
        pagination
        highlightOnHover
        striped
        responsive
        fixedHeader
        conditionalRowStyles={conditionalRowStyles}
      />
    </div>
  );
};

export default CustomDataTable;
