import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { token } from '../GlobalVariable';
import SuccessSnackBar from '../Snackbar/SuccessSnackBar';

const CreateDialog = (props) => {
  const [openSnack, setOpenSnack] = useState(false);

  const [libelleEpreuve, setLibelleEpreuve] = useState('');
  const [coef, setCoef] = useState();
  const [duree, setDuree] = useState();
  const [dateEpreuve, setDateEpreuve] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post('/epreuve', {
        libelleEpreuve: libelleEpreuve,
        coef: coef,
        duree: duree,
        dateEpreuve: dateEpreuve,
      })
      .then((res) => {
        setOpenSnack(true);
        props.onClose();
        props.fetchData();
      })
      .catch((err) => {});
  };

  return (
    <div>
      <Dialog open={props.open} onClose={props.onClose} maxWidth="xs">
        <form onSubmit={(e) => handleSubmit(e)}>
          <DialogTitle>Ajouter une nouvelle épreuve</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="libelleEpreuve"
              label="Libellé Epreuve"
              type="text"
              fullWidth
              variant="standard"
              value={libelleEpreuve}
              onChange={(e) => setLibelleEpreuve(e.target.value)}
              required
            />
            <TextField
              margin="dense"
              id="coef"
              label="Coefficient"
              type="number"
              fullWidth
              variant="standard"
              value={coef}
              onChange={(e) => setCoef(e.target.value)}
              required
            />
            <TextField
              margin="dense"
              id="duree"
              label="Durée"
              type="number"
              fullWidth
              variant="standard"
              value={duree}
              onChange={(e) => setDuree(e.target.value)}
              required
            />
            <TextField
              margin="dense"
              id="dateEpreuve"
              label="Date Epreuve"
              InputLabelProps={{ shrink: true }}
              type="date"
              fullWidth
              variant="standard"
              value={dateEpreuve}
              onChange={(e) => setDateEpreuve(e.target.value)}
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onClose}>Annuler</Button>
            <Button type="submit" color="primary" variant="contained">
              Ajouter
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <SuccessSnackBar open={openSnack} message="Epreuve ajoutée avec succès!" close={() => setOpenSnack(false)} />
    </div>
  );
};

export default CreateDialog;
