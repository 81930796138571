import React, { useState } from "react";
import { Button,TextField } from "@mui/material";
import CreateDialog from "components/Dashboard/Serie/CreateDialog";
import SerieTable from "components/Dashboard/Serie/SerieTable";
import axios from "axios";
import { apiRoute } from "components/Dashboard/GlobalVariable";
import { useEffect } from "react";


const Serie = () => {

  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  const [data, setData] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

    

  const handleSearch = () => {
    let filteredTmp = data.filter((data) => {
      if(data.libelleSerie.toLowerCase().includes(searchQuery.toLowerCase())){
        return data;
      }
    });
    setFiltered(filteredTmp);
  }

  const fetchData = () => {
    axios.get(`/series`)
      .then(res => {
        setData(res.data);
        setFiltered(res.data);
      })
  }

  useEffect(() => {
    fetchData();
  }, [])

    return (
      <>
        <div>
          <Button variant='contained' onClick={() => setOpenCreateDialog(true)}>Ajouter</Button>
          <CreateDialog 
            open={openCreateDialog}
            onClose={() => setOpenCreateDialog(false)}
            fetchData={fetchData}
          />
        </div>
        <div className="search">
        <TextField
          margin="dense"
          id="search"
          label="Recherche par Série"
          type="text"
          fullWidth
          variant="standard"
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
          onKeyUp={handleSearch}
          sx={{
            maxWidth: '500px'
          }}
        />
      </div>
        <SerieTable fetchData={fetchData} data={data} filtered={filtered} />
      </>
    );
}

export default Serie;
