import axios from 'axios';
import React, { useEffect, useState } from 'react';
import SelectComponent from 'shared/designSystem/SelectComponent';
import '../../styles/statistic.css';
import StatisticsTable from 'components/Dashboard/Statistic/StatisticsTable';
import { apiRouteFile } from 'components/Dashboard/GlobalVariable';
import LoadingOverlay from 'shared/components/LoadingOverlay';
const Statistic = () => {
  /**
   * VARIABLE
   */
  const [choix, setChoix] = useState([]);
  const [listNiveaux, setListNiveaux] = useState([]);
  const [centreConcours, setCentreConcours] = useState([]);
  const [filterCandidat, setFilterCandidat] = useState({
    niveau: '',
    centre: '',
    parcours: '',
  });
  const [statistics, setStatistics] = useState();
  const [isLoading, setIsLoading] = useState(false);
  /**
   * FUNCTION
   */
  const handleChangeFilterNiveaux = (e) => {
    const { value } = e.target;
    const niveau = listNiveaux.find((niveau) => +niveau.id === +value);
    if (!niveau) return;
    setCentreConcours(niveau.centreConcours);
    setFilterCandidat((oldValue) => {
      return {
        ...oldValue,
        niveau: value,
      };
    });
    const mapValue = niveau.choix.map((c) => {
      return {
        value: c.codeChoix,
        label: c.libelleChoix,
      };
    });
    setChoix(mapValue);
  };

  const handleChangeFilterCentre = (e) => {
    const { value } = e.target;
    setFilterCandidat((oldValue) => {
      return {
        ...oldValue,
        centre: value,
      };
    });
  };

  const handleChangeFilterParcours = (e) => {
    const { value } = e.target;

    // Trouver le choix sélectionné
    const choixSelected = choix.find((c) => +c.value === +value);

    // Si aucun choix n'est trouvé, ne rien faire
    if (!choixSelected) return;

    // Déterminer la valeur du parcours en fonction du label du choix
    const parcours = ['IG - ', 'PRO -'].some((val) => choixSelected.label.includes(val)) ? 'info' : value;

    // Mettre à jour le filtre candidat avec le parcours déterminé
    setFilterCandidat((oldValue) => ({
      ...oldValue,
      parcours,
    }));
  };

  /**
   * LIFECYCLE
   */
  useEffect(() => {
    getAllNiveau();
    getStatistics();
  }, []);

  /**
   * API
   */
  const getAllNiveau = () => {
    axios.get('/niveaux').then(
      (res) => {
        setListNiveaux(res.data);
      },
      (error) => {
        console.error('error');
      }
    );
  };

  const getStatistics = () => {
    setIsLoading(true);
    axios.get('/candidat/statistics/list').then(
      (res) => {
        const { statistics } = res.data;
        setStatistics(statistics);
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
        console.error(error);
      }
    );
  };

  const filterCandidatByNiveauParcoursCentre = () => {
    axios.post(`/candidat/filter/niveauParcoursCentre`, { ...filterCandidat }).then(
      (res) => {
        if (!res.data.error && res.data.fileName) {
          const a = document.createElement('a');
          a.style.display = 'none';
          document.body.appendChild(a);
          let url = `${apiRouteFile}/${res.data.fileName}`;
          a.href = url;
          a.download = res.data.fileName;
          a.setAttribute('target', '_blank');
          a.click();
          window.URL.revokeObjectURL(url);
        } else {
        }
      },
      (error) => {}
    );
  };

  return (
    <>
      <LoadingOverlay isLoading={isLoading} />
      <div className="filter-container">
        <h1 className="filter-title">Filtrer et imprimer</h1>
        {/* AFFICHER LISTE PAR NIVEAU */}
        <SelectComponent
          placeholder="Afficher par niveau"
          onChange={handleChangeFilterNiveaux}
          options={[
            { value: '', label: '-' },
            ...listNiveaux.map((niveau) => ({
              value: niveau.id,
              label: niveau.nom,
            })),
          ]}
          className="select-filter"
        />
        <SelectComponent
          placeholder="Afficher"
          onChange={handleChangeFilterParcours}
          options={[{ value: '', label: '-' }, ...choix]}
          className="select-filter"
        />

        {/* AFFICHER PAR CENTRE */}
        <SelectComponent
          placeholder="Afficher par centre"
          onChange={handleChangeFilterCentre}
          options={[
            { value: '', label: '-' },
            ...centreConcours.map((d) => ({
              value: d.codeCentre,
              label: d.nomCentre,
            })),
          ]}
          className="select-filter"
        />
        <button onClick={filterCandidatByNiveauParcoursCentre}>Imprimer</button>
      </div>
      <div className="statistic-container">
        <p className="statistic-title">Statistiques</p>
        <StatisticsTable statistics={statistics} />
      </div>
    </>
  );
};

export default Statistic;
