import React, { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import EditRounded from '@mui/icons-material/EditRounded';
import DeleteRounded from '@mui/icons-material/DeleteRounded';
import EditDialog from '../Resultat/EditDialog';

import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import WarningSnackBar from '../Snackbar/WarningSnackBar';

const ResultatTable = (props) => {
  const [codeResultat, setCodeResultat] = useState();
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const [openSnack, setOpenSnack] = useState(false);

  const handleOpenEditDialog = (codeResultat) => {
    setCodeResultat(codeResultat);
    setOpenEditDialog(true);
  };
  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setCodeResultat(null);
    props.fetchData();
  };

  const handleDelete = (codeResultat) => {
    if (window.confirm('Voulez-vous vraiment supprimer ce résultat?')) {
      axios
        .delete(`/resultat/${codeResultat}`)
        .then((res) => {
          props.fetchData();
          setOpenSnack(true);
        })
        .catch((err) => {});
    }
  };

  const columns = [
    { field: 'codeResultat', headerName: 'Code Résultat', flex: 1 },
    { field: 'libelleResultat', headerName: 'Résultat', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: (params) => (
        <>
          <IconButton title="modifier" sx={{ color: 'blue' }} onClick={() => handleOpenEditDialog(params.id)}>
            <EditRounded />
          </IconButton>
          <IconButton title="supprimer" sx={{ color: 'red' }} onClick={() => handleDelete(params.id)}>
            <DeleteRounded />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <>
      {codeResultat != null ? (
        <EditDialog open={openEditDialog} codeResultat={codeResultat} onClose={handleCloseEditDialog} />
      ) : (
        ''
      )}
      {props.data && (
        <DataGrid
          autoHeight
          getRowId={(row) => row.codeResultat}
          rows={props.data}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10, 20, 30]}
          localeText={{
            MuiTablePagination: {
              labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} sur ${count}`,
              labelRowsPerPage: 'Lignes par page',
            },
          }}
        />
      )}
      <WarningSnackBar open={openSnack} message="Série supprimée!" close={() => setOpenSnack(false)} />
    </>
  );
};

export default ResultatTable;
