import React from "react";
import { IMaskInput } from "react-imask";
import PropTypes from "prop-types";

export const TextMaskCustomCIN = React.forwardRef(function TextMaskCustomCIN(
  props,
  ref
) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="000 000 000 000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustomCIN.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};


export const TextMaskCustomNumeroTelephone = React.forwardRef(function TextMaskCustomNumeroTelephone(
    props,
    ref
  ) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="000 00 000 00"
        definitions={{
          "#": /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  });
  
  TextMaskCustomNumeroTelephone.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  export const TextMaskCustomNumeroBacc = React.forwardRef(function TextMaskCustomNumeroBacc(
    props,
    ref
  ) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="0000000"
        definitions={{
          "#": /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  });
  
  TextMaskCustomNumeroBacc.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  export const TextMaskCustomVersAnneNaissance = React.forwardRef(function TextMaskCustomVersAnneNaissance(
    props,
    ref
  ) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="0000"
        definitions={{
          "#": /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  });
  
  TextMaskCustomVersAnneNaissance.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  export const TextMaskCustomNote = React.forwardRef(function TextMaskCustomNote(
    props,
    ref
  ) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="00,00"
        definitions={{
          "#": /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  });
  
  TextMaskCustomNote.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };
