import React, { useEffect } from 'react';
import { MenuItem } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import styled from 'styled-components';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';

export default function SelectWrapper({ name, label, options, ...otherProps }) {
  const { setFieldValue, values } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (evt) => {
    const { value } = evt.target;
    setFieldValue(name, value);
  };

  const configSelect = {
    ...field,
    ...otherProps,
    fullwidth: 'true',
    variant: 'outlined',
    size: 'small',
    onChange: handleChange,
  };

  if (meta && meta.touched && meta.error) {
    configSelect.error = true;
  }

  return (
    <Wrapper className={otherProps.className}>
      <FormControl error={configSelect.error} className="formControl" fullWidth>
        <div className="description">
          {label ? <FormLabel htmlFor={name}>{label}</FormLabel> : ''}
          {otherProps.tooltip ? (
            <Tooltip title={otherProps.tooltip} arrow className="toolTip">
              <InfoOutlinedIcon />
            </Tooltip>
          ) : (
            ''
          )}
        </div>
        <Select {...configSelect} id={name} className="select">
          {options.map((option, index) => {
            return (
              <MenuItem key={index} value={option.value}>
                {option.text}
              </MenuItem>
            );
          })}
        </Select>
        {configSelect.error ? <FormHelperText className="helperText">{meta.error}</FormHelperText> : ''}
      </FormControl>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .formControl {
  }
  .description {
    display: flex;
    align-items: center;
    margin-bottom: 7px;
    label {
      font-family: 'Montserrat';
      font-weight: 500;
      margin-right: 10px;
    }
    .toolTip {
      opacity: 0.3;
      font-size: 1.4em;
    }
  }
  .helperText {
    font-weight: bold;
  }
`;
