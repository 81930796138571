import React, { useState, useEffect } from "react";
import EditDialog from "../Parametre/EditDialog";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { Button, IconButton, TextField } from "@mui/material";
import EditRounded from "@mui/icons-material/EditRounded";
import DeleteRounded from "@mui/icons-material/DeleteRounded";
import * as moment from "moment";
import "moment/locale/fr";
import CreateDialog from "./CreateDialog";
import AlertDialogSlide from "../DeleteConfirmation/DeleteConfirmation";
import WarningSnackBar from "../Snackbar/WarningSnackBar";
import styled from "styled-components";
moment.locale("fr");

const ParametreTable = (props) => {
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [id, setId] = useState();
  const [data, setData] = useState();
  const [filtered, setFiltered] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [pageSize, setPageSize] = useState(15);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  useEffect(() => {
    axios.get(`/parametres`).then((res) => {
      setData(res.data);
      setFiltered(res.data);
    });
  }, []);

  const handleSearch = () => {
    let filteredTmp = data.filter((data) => {
      if (data.anneeUniv.toLowerCase().includes(searchQuery.toLowerCase())) {
        return data;
      }
    });
    setFiltered(filteredTmp);
  };

  const handleOpenEditDialog = (id) => {
    setId(id);
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setId(null);
  };

  //Handle changement donner parametre ( action === new donc c'est du nouveau parametre)
  const handleChangeDataParametre = (dataUpdated, action) => {
    if (action === "new") {
      setData(data.concat(dataUpdated));
      setOpenCreateDialog(false);
    } else {
      setOpenEditDialog(false);
      setData(
        data.map((p) => {
          return p.id === dataUpdated.id ? dataUpdated : p;
        })
      );
    }
  };

  const handleDeleteDialog = (is_validate) => {
    if (is_validate) {
      setOpenDeleteDialog(false);
      axios.delete(`/parametres/${id}`).then((res) => {
        setData(data.filter((p) => p.id !== id));
        setFiltered(
          data.filter((p) => p.id !== id)
        );
        setOpenSnack(true);
      });
    } else {
      setOpenDeleteDialog(false);
    }
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 0.4, headerClassName: 'tableHeader'  },
    { field: "anneeUniv", headerName: "Année Universitaire", flex: 1, headerClassName: 'tableHeader'  },
    { field: "dateDebutConcours", headerName: "Date début concours", flex: 1, headerClassName: 'tableHeader'  },
    { field: "dateFinConcours", headerName: "Date fin concours", flex: 1, headerClassName: 'tableHeader'  },
    {
      field: "dateOuvertureInscription",
      headerName: "Date Ouverture Inscription",
      flex: 1,
      headerClassName: 'tableHeader'
    },
    {
      field: "dateFermetureInscription",
      headerName: "Date Fermeture Inscription",
      flex: 1,
      headerClassName: 'tableHeader'
    },
    { field: "numCpteENI", headerName: "N°Compte ENI", flex: 1, headerClassName: 'tableHeader' },
    {
      field: "actions",
      headerName: "Action",
      renderCell: (params) => (
        <>
          <IconButton
            title="modifier"
            sx={{ color: "blue" }}
            onClick={() => handleOpenEditDialog(params.id)}
          >
            <EditRounded />
          </IconButton>
          <IconButton
            title="supprimer"
            sx={{ color: "red" }}
            onClick={() => {
              setId(params.id);
              setOpenDeleteDialog(true);
            }}
          >
            <DeleteRounded />
          </IconButton>
        </>
      ),
      headerClassName: 'tableHeader'
    },
  ];

  return (
    <Wrapper>
      <h2>Informations générales</h2>
      {id != null ? (
        <EditDialog
          open={openEditDialog}
          id={id}
          onClose={handleCloseEditDialog}
          handleChangeDataParametre={handleChangeDataParametre}
        />
      ) : (
        ""
      )}
      <div>
        <CreateDialog
          open={openCreateDialog}
          onClose={() => setOpenCreateDialog(false)}
          handleChangeDataParametre={handleChangeDataParametre}
        />
      </div>
      <div className="search">
        <TextField
          margin="dense"
          id="search"
          label="Rechercher"
          type="text"
          fullWidth
          variant="outlined"
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
          onKeyUp={handleSearch}
          sx={{
            maxWidth: "500px",
          }}
        />
        <Button variant="contained" onClick={() => setOpenCreateDialog(true)}>
          Ajouter
        </Button>
      </div>
      {data && (
        <DataGrid
          autoHeight
          // getRowId={(row) => row.codeFaritany}
          rows={data.map((p) => {
            return {
              ...p,
              dateDebutConcours: moment(p.dateDebutConcours).format(
                "DD/MM/YYYY"
              ),
              dateFinConcours: moment(p.dateFinConcours).format("DD/MM/YYYY"),
              dateOuvertureInscription: moment(
                p.dateOuvertureInscription
              ).format("DD/MM/YYYY"),
              dateFermetureInscription: moment(
                p.dateFermetureInscription
              ).format("DD/MM/YYYY"),
            };
          })}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[15, 30, 45, 60]}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          pagination
          localeText={{
            MuiTablePagination: {
              labelDisplayedRows: ({ from, to, count }) =>
                `${from} - ${to} sur ${count}`,
              labelRowsPerPage: "Lignes par page",
            },
          }}
        />
      )}
      <AlertDialogSlide
        open={openDeleteDialog}
        message="Voulez vous supprimer ce paramètre ?"
        handleDeleteDialog={handleDeleteDialog}
      />
      <WarningSnackBar
        message="Paramètre supprimé"
        open={openSnack}
        close={() => setOpenSnack(false)}
      />
    </Wrapper>
  );
};

export default ParametreTable;

const Wrapper = styled.div`
  .tableHeader{
    background-color: #acacac;
    color: white;
  }
  .search{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 7vh;
  }
`;
