import React, { useState } from 'react';
import styled from 'styled-components';

import { FilledButton, OutlineButton } from '../../../styles/candidat/Button.styled';
import * as styles from '../../../styles/variables';

import DownloadIcon from '@mui/icons-material/Download';

import Points from '../../../assets/img/geometry-form/points.png';

const Attachments = () => {
  const [listeParcours] = useState({
    'L1 Métier Digital': [
      '/documents/Demande-inscp-L1eni-CONCOURS-ENI-IA.doc',
      '/documents/Fiche-Rens-L1-CONCOURS-ENI.doc',
    ],
    'L1 Informatique': [
      '/documents/Demande-inscp-L1eni-CONCOURS-ENI-Info.doc',
      '/documents/Fiche-Rens-L1-CONCOURS-ENI.doc',
    ],
    'M1 IA': ['/documents/DemandeM1-ASI.docx', '/documents/DemandeM1-OCC-GID.docx', '/documents/FicheRensM1.docx'],
  });

  const downloadFiles = (files) => {
    files.forEach((file) => {
      const link = document.createElement('a');
      link.href = file;
      link.download = file.split('/').pop();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  return (
    <DocumentsWrapper>
      <div className="header">
        <h2>Télecharger ici</h2>
        <img src={Points} alt="points" className="points" />
      </div>
      <div className="list">
        {Object.entries(listeParcours).map(([title, files], index) => (
          <div className="item" key={index}>
            {/* <h1>{index + 1} -</h1> */}
            <div className="content">
              <p className="title">{title}</p>

              <div style={{ textDecoration: 'none', display: 'inline-block' }}>
                <OutlineButton
                  borderColor={styles.colors.lightBlue}
                  rounded
                  color={styles.colors.lightBlue}
                  onClick={() => downloadFiles(files)}
                >
                  Télécharger la fiche
                  <DownloadIcon color={styles.colors.lightBlue} />
                </OutlineButton>
              </div>
            </div>
          </div>
        ))}
      </div>
    </DocumentsWrapper>
  );
};

const DocumentsWrapper = styled.div`
  margin-top: 100px;
  max-width: 1280px;
  width: 100%;

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .points {
      position: absolute;
      z-index: -1;
      width: 12vw;
      height: auto;
      right: -70px;

      @media (max-width: ${styles.breakpoints.sm}) {
        width: 80px;
        height: auto;
        right: -30px;
      }
    }

    h2 {
      color: ${styles.colors.lightBlue};
    }

    p,
    strong {
      font-size: 14px;
      max-width: 600px;
    }
  }

  .list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 80px;
    .item {
      flex-basis: 45%;
      display: flex;
      margin-bottom: 15px;
      h1 {
        margin: 0;
        color: ${styles.colors.lightBlue};
      }
      .content {
        margin-left: 10px;
      }
      .title {
        font-size: 16px;
        font-weight: bold;
      }
      .text {
        font-size: 14px;
      }
    }
    @media (max-width: ${styles.breakpoints.md}) {
      flex-direction: column;
    }
  }
`;

export default Attachments;
