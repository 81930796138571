import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
  selectedNiveau: null,
};

export const niveauSlice = createSlice({
  name: 'niveau',
  initialState,
  reducers: {
    setNiveaux: (state, action) => {
      state.list = action.payload;
    },
    setSelectedNiveau: (state, action) => {
      state.selectedNiveau = action.payload;
    },
  },
});

// Actions exportées
export const { setNiveaux, setSelectedNiveau } = niveauSlice.actions;

// Reducer exporté
export default niveauSlice.reducer;
