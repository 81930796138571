import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { apiRoute, token } from '../GlobalVariable';
import SuccessSnackBar from '../Snackbar/SuccessSnackBar';

const EditDialog = (props) => {
  const [nomCentre, setNomCentre] = useState('');
  const [openSnack, setOpenSnack] = useState(false);

  useEffect(() => {
    axios
      .get(`/centreConcours/${props.codeCentre}`)
      .then((res) => {
        setNomCentre(res.data.nomCentre);
      })
      .catch((err) => {});
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put(`/centreConcours/${props.codeCentre}`, {
        nomCentre: nomCentre,
      })
      .then((res) => {
        setOpenSnack(true);
        setTimeout(() => {
          props.onClose();
        }, 2000);
      });
  };

  return (
    <div>
      <Dialog
        maxWidth="sm"
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form onSubmit={(e) => handleSubmit(e)}>
          <DialogTitle id="alert-dialog-title">Modifier le centre</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="lieu"
              label="Nom Centre"
              value={nomCentre}
              onChange={(e) => setNomCentre(e.target.value)}
              type="text"
              fullWidth
              variant="standard"
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onClose}>Annuler</Button>
            <Button type="submit" color="primary" variant="contained">
              Modifier
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <SuccessSnackBar
        open={openSnack}
        message="Centre concours modifié avec succès!"
        close={() => setOpenSnack(false)}
      />
    </div>
  );
};

export default EditDialog;
