import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import CreateDialog from "components/Dashboard/Epreuve/CreateDialog";
import EpreuveTable from "components/Dashboard/Epreuve/EpreuveTable";
import { useEffect } from "react";
import axios, { Axios } from "axios";


const Epreuve = () => {

  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [data, setData] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchData = () => {
    axios.get(`/epreuve`)
      .then(res => {
        setData(res.data);
        setFiltered(res.data);
      })
  }

  const handleSearch = () => {
    let filteredTmp = data.filter((data) => {
      if(data.libelleEpreuve.toLowerCase().includes(searchQuery.toLowerCase())){
        return data;
      }
    });
    setFiltered(filteredTmp);
  }

  useEffect(() => {
    fetchData();
  }, [])

    return (
      <>
      <div>
        <Button variant='contained' onClick={() => setOpenCreateDialog(true)}>Ajouter</Button>
        <div className="search">
          <TextField
            margin="dense"
            id="search"
            label="Recherche par Nom Centre"
            type="text"
            fullWidth
            variant="standard"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
            onKeyUp={handleSearch}
            sx={{
              maxWidth: '500px'
            }}
          />
        </div>
        <CreateDialog 
          open={openCreateDialog}
          onClose={() => setOpenCreateDialog(false)}
          fetchData={fetchData}
        />
      </div>
        <EpreuveTable data={data} filtered={filtered} fetchData={fetchData} />
      </>
    );
}

export default Epreuve;
