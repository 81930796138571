export const colors = {
    drawerBg: '#111827',
    drawerBgActive: '#242A38',
    aeeniOrange: '#FFB475',
    drawerColor: '#CBCCD5',
    appBackground: '#F9FAFC',
    green: "#10B981",


    //form page
    darkBlue: '#02044A',
    success: '#2DB27C',
    error: '#EB5757',

    //CANDIDAT
    lightBlue: '#2F80ED', 
};

export const size = {
    borderRadius: '10px',
    drawerWidth: 280,

    //CANDIDAT
    navHeight: '80px',
    resultatMaxWidth: '1000px'
};

export const breakpoints = {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1400px'
}