import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import * as styles from '../../../styles/variables';

import Calendar from '../../../assets/img/calendar.png';
import { FilledButton } from '../../../styles/candidat/Button.styled';
import CircleIcon from '@mui/icons-material/Circle';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { apiRoute } from 'components/Dashboard/GlobalVariable';
import { render } from 'react-dom';
import ImageViewer from 'react-simple-image-viewer';
import * as moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');

const Date = () => {
  const [infoParametrage, setInforParametrage] = useState([]);
  // const [currentImage, setCurrentImage] = useState(0);
  // const [isViewerOpen, setIsViewerOpen] = useState(false);
  // const images = [
  //   '/images/edt.png'
  // ];

  // const openInNewTab = (url) => {
  //   const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  //   if (newWindow) newWindow.opener = null;
  // };

  // const openImageViewer = useCallback((index) => {
  //   setCurrentImage(index);
  //   setIsViewerOpen(true);
  // }, []);

  // const closeImageViewer = () => {
  //   setCurrentImage(0);
  //   setIsViewerOpen(false);
  // };

  useEffect(() => {
    axios.get(`/parametres`).then((res) => {
      setInforParametrage(
        res.data.map((data) => {
          let dateOuverture = moment(data.dateOuvertureInscription).format('LLLL');
          let dateFin = moment(data.dateFermetureInscription).format('LLLL');
          let dateDebutConcours = moment(data.dateDebutConcours).format('LLLL');
          let dateFinConcours = moment(data.dateFinConcours).format('LLLL');
          return {
            ...res.data[0],
            dateOuvertureInscription: splitDate(dateOuverture[0].toUpperCase() + dateOuverture.slice(1)),
            dateFermetureInscription: splitDate(dateFin[0].toUpperCase() + dateFin.slice(1)),
            dateDebutConcours: splitDate(dateDebutConcours[0].toUpperCase() + dateDebutConcours.slice(1)).split(' '),
            dateFinConcours: splitDate(dateFinConcours[0].toUpperCase() + dateFinConcours.slice(1)).split(' '),
          };
        })
      );
    });
  }, []);

  const splitDate = (dates) => {
    let date = dates.split(' ');
    return `${date[0]} ${date[1]} ${date[2]} ${date[3]}`;
  };

  return (
    <DateWrapper>
      {/* <div>
        {isViewerOpen && (
          <ImageViewer
            src={images}
            currentIndex={currentImage}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
          />
        )}
      </div> */}
      <div className="left-cards">
        <img src={Calendar} alt="Calendar" className="calendar-img" />
        <div className="">Les dates à venir</div>
      </div>
      {infoParametrage.length > 0
        ? infoParametrage.map((data, index) => (
            <div className="right-cards" key={index}>
              <div className="top-cards">
                <div className="first-card">
                  <div className="content">
                    <div className="circleIcons">
                      <CircleIcon
                        sx={{
                          width: 16,
                          height: 16,
                          marginRight: '6px',
                          color: '#2BFF4D',
                        }}
                      />
                      <CircleIcon
                        sx={{
                          width: 16,
                          height: 16,
                          marginRight: '6px',
                          color: '#D9D9D9',
                        }}
                      />
                    </div>
                    <p>Début des inscriptions</p>
                    <h2>{data.dateOuvertureInscription}</h2>
                  </div>
                </div>
                <div className="second-card">
                  <div className="content">
                    <div className="circleIcons">
                      <CircleIcon
                        sx={{
                          width: 16,
                          height: 16,
                          marginRight: '6px',
                          color: '#D9D9D9',
                        }}
                      />
                      <CircleIcon
                        sx={{
                          width: 16,
                          height: 16,
                          marginRight: '6px',
                          color: '#FF4F4F',
                        }}
                      />
                    </div>
                    <p>Fin des inscriptions</p>
                    <h2>{data.dateFermetureInscription}</h2>
                  </div>
                </div>
              </div>
              <div className="bottom-cards">
                <div className="second-card">
                  <div>
                    <EventNoteIcon sx={{ color: styles.colors.lightBlue }} />
                  </div>
                  <p>Date de concours</p>
                  <h2>
                    {data.dateDebutConcours[0]} {data.dateDebutConcours[1]} et {data.dateFinConcours[0]}{' '}
                    {data.dateFinConcours[1]} {data.dateFinConcours[2]} {data.dateFinConcours[3]}{' '}
                  </h2>
                </div>
              </div>
            </div>
          ))
        : ''}
    </DateWrapper>
  );
};

const DateWrapper = styled.div`
  display: flex;
  max-width: 1280px;
  width: 100%;
  margin-top: 40px;

  @media (max-width: ${styles.breakpoints.lg}) {
    flex-direction: column;
  }
  .left-cards {
    height: 600px;
    width: 280px;
    min-width: 280px;
    border-radius: 28px;
    margin-right: 50px;
    background: rgb(12, 111, 167);
    background: linear-gradient(0deg, rgba(12, 111, 167, 1) 0%, rgba(23, 189, 225, 1) 100%);
    flex: 1;

    @media (max-width: ${styles.breakpoints.lg}) {
      width: 100%;
      align-items: center;
      margin-bottom: 20px;
    }

    //CONTENT
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 0 10px 20px;
    color: #fff;
    font-size: 40px;
    font-weight: bold;
    .calendar-img {
      height: 180px;
      width: 180px;
    }
  }
  .right-cards {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 3;
    .top-cards {
      display: flex;
      justify-content: space-between;
      @media (max-width: ${styles.breakpoints.lg}) {
        margin-bottom: 20px;
      }
      @media (max-width: ${styles.breakpoints.md}) {
        flex-direction: column;
      }

      .content {
        height: 100%;
      }
      .first-card {
        height: 280px;
        width: 50%;
        border: 2px solid ${styles.colors.lightBlue};
        border-radius: 28px;
        margin-right: 2vw;
        padding: 20px 0;

        @media (max-width: ${styles.breakpoints.md}) {
          width: 100%;
          height: auto;
          margin-bottom: 20px;
        }

        //CONTENT
        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 10px 30px;
        }

        p {
          text-transform: uppercase;
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 20px;
        }

        h2 {
          font-size: 34px;
          margin: 0;
          color: ${styles.colors.lightBlue};

          @media (max-width: ${styles.breakpoints.sm}) {
            font-size: 28px;
          }
        }
      }
      .second-card {
        height: 280px;
        width: 50%;
        border: 2px solid ${styles.colors.lightBlue};
        border-radius: 28px;
        padding: 20px 0;

        @media (max-width: ${styles.breakpoints.md}) {
          width: 100%;
          height: auto;
        }

        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 10px 30px;
        }

        p {
          text-transform: uppercase;
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 20px;
        }

        h2 {
          font-size: 34px;
          margin: 0;
          color: ${styles.colors.lightBlue};

          @media (max-width: ${styles.breakpoints.sm}) {
            font-size: 28px;
          }
        }
      }

      .circleIcons {
        margin-bottom: -7px;
      }
    }

    .bottom-cards {
      width: 100%;
      height: 280px;
      border: 2px solid ${styles.colors.lightBlue};
      border-radius: 28px;
      //CONTENT
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 30px;

      p {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: bold;
      }

      h2 {
        font-size: 34px;
        margin: 0;
        color: ${styles.colors.lightBlue};

        @media (max-width: ${styles.breakpoints.sm}) {
          font-size: 28px;
        }
      }
    }
  }
`;

export default Date;
