import FormButton from 'components/FormUI/Button';
import { useContext } from 'react';
import styled from 'styled-components';
import { FormContext } from '../../FormContext';

import { formatDateRange } from 'utility/utils';
import Arrete from '../../../../../assets/documents/Arrete-Concours-ENI-2022-2023.pdf';

export default function Avis({ nextAccordion, infoParametrage, ...otherProps }) {
  const { setExpanded } = useContext(FormContext);

  return (
    <Wrapper>
      {infoParametrage && (
        <>
          <p>
            {/* Il est ouvert au titre de l'année universitaire {infoParametrage.anneeUniv}-{+infoParametrage.anneeUniv + 1}{' '}
            un concours d'entrée en Première Année de Formation en Informatique de niveau Licence aboutissant au Master,
            parcours : (1) Génie Logiciel et Base de Données (GB), (2) Administration des Systèmes et Réseaux (SR) et
            (3) Informatique Générale (IG), à l'Ecole Nationale d'Informatique de l’Université de Fianarantsoa le{' '}
            {formatDateRange(infoParametrage.dateDebutConcours, infoParametrage.dateFinConcours)}. */}
          </p>
          {/* <a href={Arrete} target="_blank">
            Voir arrêté
          </a> */}
          <div className="btn-section">
            <FormButton
              color="primary"
              onClick={() => {
                setExpanded(nextAccordion);
              }}
            >
              Suivant
            </FormButton>
          </div>
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div``;
