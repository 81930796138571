import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { padding } from "@mui/system";
import axios from "axios";
import React from "react";
import styled from "styled-components";
import SuccessSnackBar from "../Snackbar/SuccessSnackBar";

const EditerBacc = (props) => {
  const [openSnack, setOpenSnack] = React.useState(false);
  const [formData, setFormData] = React.useState({
    mention: "passable",
    anneeBacc: "",
  });

  const [mentions, setMentions] = React.useState([
    {
      value: "Passable",
      text: "Passable",
    },
    {
      value: "Assez-bien",
      text: "Assez-bien",
    },
    {
      value: "Bien",
      text: "Bien",
    },
    {
      value: "Très bien",
      text: "Très bien",
    },
  ]);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.put(`/candidat/mention/${props.id}`, formData).then((res) => {
      if (res.data.error === false) {
        setOpenSnack(true);
        setTimeout(() => {
          props.onClose({id: props.id, ...formData});
        }, 1000);
      }
    });
  };

  //Quand on change la valeur dans l'inout
  const handleChangeFormData = (event) => {
    const { value, name, type } = event.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: type === "number" ? parseFloat(value) : value,
      };
    });
  };

  return (
    <Wrapper>
      <Dialog open={props.open} onClose={props.onClose}>
        <form onSubmit={(e) => handleSubmit(e)}>
          <DialogTitle>Modifier bacc </DialogTitle>
          <DialogContent style={{ padding: "10px" }}>
            {mentions ? (
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Mention</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formData.mention}
                  name="mention"
                  label="Mention"
                  onChange={handleChangeFormData}
                >
                  {mentions.map((d, i) => (
                    <MenuItem key={i} value={d.value}>
                      {d.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              ""
            )}
            <TextField
              margin="dense"
              label="Annee"
              type="number"
              name="anneeBacc"
              fullWidth
              value={formData.anneeBacc}
              onChange={handleChangeFormData}
              variant="standard"
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onClose}>Annuler</Button>
            <Button type="submit" color="primary" variant="contained">
              Modifier
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <SuccessSnackBar
        open={openSnack}
        message="Information bacc modifié avec succès"
        close={() => setOpenSnack(false)}
      />
    </Wrapper>
  );
};

export default EditerBacc;

const Wrapper = styled.div``;
