import React from 'react';
import styled from 'styled-components';

import { documents } from '../../../constants/homeData';
import { OutlineButton } from '../../../styles/candidat/Button.styled';
import * as styles from '../../../styles/variables';

import DownloadIcon from '@mui/icons-material/Download';

import Points from '../../../assets/img/geometry-form/points.png';

const Documents = () => {
  return (
    <DocumentsWrapper>
      <div className="header">
        <h2>
          Liste des dossiers de <br />
          candidature
        </h2>
        <p>
          Les dossiers suivants sont nécessaires pour l'inscription au concours. Que ce soit pour l'inscription en ligne
          ou le dépôt auprès de la scolarité.
        </p>
        <strong>
          *A noter que même après l'inscription en ligne, il faut déposer les dossiers physiques avant la date du
          concours.
        </strong>
        <img src={Points} alt="points" className="points" />
      </div>
      <div className="list">
        {documents.map((document) => (
          <div className="item" key={document.id}>
            <h1>0{document.id}.</h1>
            <div className="content">
              <p className="title">{document.title}</p>
              <p className="text">{document.text}</p>
              {/* {document.button==='renseignement' && (
                                <a href="/documents/Fiche-Rens-CONCOURS-ENI.pdf" download style={{textDecoration: 'none', display:'inline-block'}}>
                                    <OutlineButton borderColor={styles.colors.lightBlue} rounded color={styles.colors.lightBlue}>
                                        Télécharger la fiche
                                        <DownloadIcon color={styles.colors.lightBlue} />
                                    </OutlineButton>
                                </a>
                            )}
                            {document.button==='demande' && (
                                <a href="/documents/Demande-Inscri-CONCOURS-ENI.pdf" download style={{textDecoration: 'none',display: 'inline-block'}}>
                                    <OutlineButton borderColor={styles.colors.lightBlue} rounded color={styles.colors.lightBlue}>
                                        Télécharger le modèle
                                        <DownloadIcon color={styles.colors.lightBlue} />
                                    </OutlineButton>
                                </a>
                            )} */}
            </div>
          </div>
        ))}
      </div>
    </DocumentsWrapper>
  );
};

const DocumentsWrapper = styled.div`
  margin-top: 100px;
  max-width: 1280px;
  width: 100%;
  /* position: relative; */

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    /* position: relative; */

    .points {
      position: absolute;
      z-index: -1;
      width: 12vw;
      height: auto;
      right: -70px;

      @media (max-width: ${styles.breakpoints.sm}) {
        width: 80px;
        height: auto;
        right: -30px;
      }
    }

    h2 {
      color: ${styles.colors.lightBlue};
    }

    p,
    strong {
      font-size: 14px;
      max-width: 600px;
    }
  }

  .list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 80px;
    .item {
      flex-basis: 45%;
      display: flex;
      margin-bottom: 80px;
      h1 {
        margin: 0;
        color: ${styles.colors.lightBlue};
      }
      .content {
        margin-left: 10px;
      }
      .title {
        font-size: 16px;
        font-weight: bold;
      }
      .text {
        font-size: 14px;
      }
    }
    @media (max-width: ${styles.breakpoints.md}) {
      flex-direction: column;
    }
  }
`;

export default Documents;
