// Select.styled.js
import styled from 'styled-components';

// Styles de base pour le Select
export const StyledSelect = styled.select`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: ${({ theme }) => theme.backgroundColor || '#fff'};
  color: ${({ theme }) => theme.textColor || '#333'};
  cursor: pointer;

  &:focus {
    border-color: ${({ theme }) => theme.primaryColor || '#1976d2'};
    outline: none;
  }
`;

// Exemple de style pour une option
export const StyledOption = styled.option`
  padding: 8px;
  background-color: ${({ theme }) => theme.optionBgColor || '#fff'};
  color: ${({ theme }) => theme.optionTextColor || '#333'};
`;
