import React, { useEffect } from 'react';
import styled from 'styled-components';
import * as styles from 'styles/variables';
import { useField, useFormikContext, Field } from 'formik';

export default function InputFile({ name, label, ...otherProps }) {
    const [field, meta] = useField(name);
    const { setErrors, errors, setFieldValue } = useFormikContext();


    const handleChange = (e) => {
        let newError = Object.assign({}, errors);
        // Check if the number of files
        // is not zero
        if (e.target.files.length) {
            const file = e.target.files[0];
            const fileExtension = file.name.split('.').at(-1);
            const allowedFileTypes = ['jpg', 'png', 'jpeg'];

            if (!allowedFileTypes.includes(fileExtension.toLowerCase())) {
                window.alert(`Fichier non supporté, veuillez choisir un fichier ${allowedFileTypes.join(', ')}`);
                e.target.value = null;
                newError[name] = "Fichier non supporté"
                setErrors(newError);
                return false;
            }
            if (file.size > 3e+7) {
                window.alert(`Fichier trop lourd, veuillez choisir un fichier inférieur à 30Mo`);
                e.target.value = null;
                newError[name] = "Fichier trop lourd"
                setErrors(newError);
                return false;
            }
            //the file is valid => delete the error file from the errors by giving it a random value
            setFieldValue(name, 'foo');
        }
        // Alert the user if the number
        // of file is zero
        else {
            if (otherProps.required) {
                setFieldValue(name, '');
                return false
            }
        }
    }
    const inputConfig = {
        name: name,
        id: name,
        onChange: handleChange,
    }

    return (
        <Wrapper>
            <h3>{label}</h3>
            {/* <InputFile name="photo" required onChange={photoChangeHandler}/> */}
            <label htmlFor={name} className="file" title="Choisir un fichier">
                <input type="file" {...inputConfig} accept="image/png, image/jpeg, image/jpg" />
                {
                    (errors[name] && otherProps.required) ? <span className="error">Fichier requis</span> : ""
                }
            </label>
        </Wrapper>
    )
}

const Wrapper = styled.div`
width: 47%;
margin-bottom: 50px;
h3{
  font-size: .9em;
  font-weight: 600;
  color: #000000;
}
label{
  // display: block;
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #F5F5F8;
  border-radius: 15px;
  position: relative;
  input{
  }
  .error{
    color: red;
    font-size: .8em;
    font-weight: 500;
    position: absolute;
    bottom: 10px;
  }
}
@media screen and (max-width: ${styles.breakpoints.md}){
  width: 100%;
}

`;