import React, { useState, useEffect } from "react";
import { DataGrid } from '@mui/x-data-grid';
import CentreConcoursTable from "components/Dashboard/CentreConcours/CentreConcoursTable";
import { Button } from "@mui/material";
import CreateDialog from "components/Dashboard/TypeCandidat/CreateDialog";
import TypeCandidatTable from "components/Dashboard/TypeCandidat/TypeCandidatTable";
import axios from "axios";
import styled from "styled-components";


const TypeCandidat = () => {

  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  const [data, setData] = useState();

  const fetchData = () => {
    axios.get('/typecandidat')
      .then(res => {
        setData(res.data);
      })
  }

  useEffect(() => {
    fetchData()
  }, []);


    return (
      <Wrapper>
      <h2>Types de candidat</h2>
      <div className="search">
        <Button variant='contained' onClick={() => setOpenCreateDialog(true)}>Ajouter</Button>
        <CreateDialog 
          open={openCreateDialog}
          onClose={() => setOpenCreateDialog(false)}
          fetchData={fetchData}
        />
      </div>
        <TypeCandidatTable data={data} fetchData={fetchData} />
      </Wrapper>
    );
}

export default TypeCandidat;

const Wrapper = styled.div`
  h2{
    margin-bottom: 30px;
    margin-top: 5vh;
  }
  .search{
    text-align: right;
    margin-bottom: 20px;
  }
`;
