import React, {useContext} from 'react'
import styled from 'styled-components'
import * as styles from 'styles/variables';
import NavItem from './NavItem';
import megaphone from 'assets/icons/megaphone.svg';
import user from 'assets/icons/user.svg';
import diploma from 'assets/icons/diploma.svg';
import pen from 'assets/icons/pen.svg';
import paperClip from 'assets/icons/paper-clip.svg';
import { FormContext } from '../FormContext';

export default function FormNav() {
  const {PANELS, isSectionValid} = useContext(FormContext);
  return (
    <Main>
      <div className="items">
        <NavItem target={PANELS.AVIS} icon={megaphone} isValid={isSectionValid.avis}>
          Avis de concours
        </NavItem>
        <NavItem target={PANELS.INFOSPERSOS} icon={user} isValid={isSectionValid.infosPersos}>
          Informations <br/>personnelles
        </NavItem>
        <NavItem target={PANELS.BACC} icon={diploma} isValid={isSectionValid.bacc}>
          Bacc
        </NavItem>
        <NavItem target={PANELS.INSCRIPTION}  icon={pen} isValid={isSectionValid.inscription}>
          Inscription
        </NavItem>
        <NavItem target={PANELS.PIECESJOINTES} icon={paperClip} isValid={isSectionValid.piecesJointes}>
          Pièces jointes
        </NavItem>
      </div>
    </Main>
  )
}

const Main = styled.div`
  background-color: ${styles.colors.darkBlue};
  color: white;
  height: 100vh;
  width: 33%;
  max-width: 460px;
  min-width: 270px;
  position: sticky;
  top: ${styles.size.navHeight};
  .items{
    --pLeft: 12%;
    margin-top: 15vh;
    padding-right: var(--pLeft);
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    position: relative;
    &::after{
      content: '';
      position: absolute;
      top: 0;
      right: calc(var(--pLeft) + 25px);
      width: 2px;
      height: 100%;
      background-color: #494A7D;
    }
  }
  @media screen and (max-width: ${styles.breakpoints.xl}) {
    display: none;
  }
`;