import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { apiRoute } from '../GlobalVariable';
import SuccessSnackBar from '../Snackbar/SuccessSnackBar';

export default function EditDialog(props) {
  const [isLoading, setIsLoading] = useState(true);

  const [libelleTypeCand, setLibelleTypeCand] = useState('');
  const [openSnack, setOpenSnack] = useState(false);

  useEffect(() => {
    axios.get(`/typecandidat/${props.codeTypeCand}`)
      .then(res => {
        setLibelleTypeCand(res.data.libelleTypeCand);
      })
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.put(`/typecandidat/${props.codeTypeCand}`,{
      libelleTypeCand: libelleTypeCand
    }).then(res => {
      setOpenSnack(true)
      setTimeout(() => {
        props.onClose();
      }, 2000);
    })
  }

  return (
    <div>
      <Dialog open={props.open} onClose={props.onClose}>
        <form onSubmit={(e) => handleSubmit(e)}>
          <DialogTitle>Modifier le type de Candidat: <strong>{libelleTypeCand}</strong></DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="libelleTypeCand"
              label="Nouveau Libellé"
              type="text"
              fullWidth
              variant="outlined"
              value={libelleTypeCand}
              onChange={(e) => setLibelleTypeCand(e.target.value)}
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onClose}>Annuler</Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
            >Modifier</Button>
          </DialogActions>
        </form>
      </Dialog>
      <SuccessSnackBar open={openSnack} message="Type candidat modifié!" close={() => setOpenSnack(false)} />
    </div>
  );
}