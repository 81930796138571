import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as styles from 'styles/variables';
import Form from './Form';
import { Box, Tab, Tabs } from '@mui/material';
import axios from 'axios';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function FormSection() {
  const [value, setValue] = React.useState(null);
  const [infoNiveaux, setInfoNiveaux] = useState([]);
  const [infoNiveauxSelected, setInfoNiveauxSelected] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setInfoNiveauxSelected(infoNiveaux.find((niveau) => niveau.id === newValue + 1));
  };

  /**
   * LIFECYCLE
   */
  useEffect(() => {
    getInfoNiveau();
  }, []);

  useEffect(() => {
    setInfoNiveauxSelected(infoNiveaux.find((niveau) => niveau.id === value + 1));
  }, [infoNiveaux]);

  useEffect(() => {
    setValue(0);
  }, []);

  /**
   * API
   */
  const getInfoNiveau = () => {
    axios.get(`/niveaux`).then((res) => {
      setInfoNiveaux(res.data);
    });
  };

  return (
    <MainWrapper>
      <main>
        <div className="header">
          <h1>Formulaire d’inscription au concours d’entrée à l’Ecole Nationale d’Informatique</h1>
          <Box style={{ width: '100%' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="scrollable force tabs example"
            >
              {infoNiveaux.map((niveau, index) => (
                <Tab key={`Tab_${index}`} label={niveau.nom} />
              ))}
            </Tabs>
          </Box>
        </div>
        <div className="form">
          <TabPanel value={value} index={0}>
            <Form infoNiveauxSelected={infoNiveauxSelected} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Form infoNiveauxSelected={infoNiveauxSelected} />
          </TabPanel>
        </div>
      </main>
    </MainWrapper>
  );
}

const MainWrapper = styled.div`
  min-height: 200vh;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: ${styles.size.navHeight};
  main {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    .header {
      margin-bottom: 10vh;
    }
  }
`;
