import React from 'react';
import styled from 'styled-components';
import {
  FormControl,
  OutlinedInput,
  FormHelperText,
  FormLabel
} from '@mui/material';
import { useField } from 'formik';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip } from '@mui/material';


export default function TextFieldWrapper({ name, label, className, ...otherProps }) {

  const [field, meta] = useField(name);

  const configInput = {
    ...field,
    ...otherProps,
    size: "small",
    id: name,
    sx: {
      borderRadius: '5px',
      width: '100%',
    }
  }

  const configFormControl = {
    variant: "standard"
  }

  let helperText;
  if (meta && meta.touched && meta.error) {
    configFormControl.error = true;
    helperText = meta.error;
  }

  return (
    <Wrapper className={`field ${className} ${otherProps.disabled ? "disabled" : ""}`}>
      <FormControl {...configFormControl} className="formControl" fullWidth>

        <div className="description">
          {
            label ? <FormLabel htmlFor={name}>{label}</FormLabel> : ""
          }
          {
            otherProps.tooltip ?
              <Tooltip title={otherProps.tooltip} arrow className="toolTip">
                <InfoOutlinedIcon />
              </Tooltip>
              : ""
          }
        </div>

        <OutlinedInput {...configInput} className="input"/>

        <FormHelperText className='helperText'>{helperText} </FormHelperText>
      </FormControl>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .description{
    display: flex;
    align-items: center;
    margin-bottom: 7px;
    label{
      font-family: 'Montserrat';
      font-weight: 500;
      margin-right: 10px;
    }
    .toolTip{
      opacity: .3;
      font-size: 1.4em;
    }
  }
  .helperText{
    font-weight: bold;
  }
  &.disabled{
    opacity: .3;
    .toolTip{
      pointer-events: none;
    }
  }
`;