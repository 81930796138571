import { Backdrop, Button, CircularProgress, InputAdornment, TextField } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { FileUploader } from 'react-drag-drop-files';
import PersonSearchRoundedIcon from '@mui/icons-material/PersonSearchRounded';
import WarningSnackBar from 'components/Dashboard/Snackbar/WarningSnackBar';
import SuccessSnackBar from 'components/Dashboard/Snackbar/SuccessSnackBar';
import { useDownloadExcel } from 'react-export-table-to-excel';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const fileTypes = ['xls', 'xlsx'];

const ResultatImportExcel = () => {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [openSnackSuccess, setOpenSnackSuccess] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const tableRef = useRef(null);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: 'resultat_2023_contact',
    sheet: 'REsultat 2023',
  });

  const typeResultat = [
    { abrev: 'A', libelle: 'ASI' },
    { abrev: 'P', libelle: 'PRO' },
    { abrev: 'F', libelle: 'IG Fianarantsoa' },
    { abrev: 'T', libelle: 'IG Toliara' },
    { abrev: 'M', libelle: 'MDI' },
    { abrev: 'G', libelle: 'GID' },
    { abrev: 'O', libelle: 'OCC' },
    { abrev: 'S', libelle: 'PRO' },
  ];

  const handleFileChange = (file) => {
    setFile(file);
  };

  const handleUpload = () => {
    if (file) {
      setOpen(true);
      const formData = new FormData();
      formData.set('file', file);
      axios
        .post('/resultat/uploadresultat', formData)
        .then((res) => {
          setOpen(false);
          setSuccessMessage('Le fichier a bien été importé');
          setOpenSnackSuccess(true);
          fetchData(res.data.filename);
        })
        .catch((error) => {
          setOpen(false);
          setErrorMessage("Erreur lors de l'importation du fichier");
          setOpenError(true);
        });
    } else {
      setErrorMessage('Veuillez sélectionner un fichier Excel.');
      setOpenError(true);
    }
  };

  const fetchData = (filename) => {
    setOpen(true);
    axios
      .get(`/resultat/generate/resultat/contact/${filename}`)
      .then((res) => {
        setOpen(false);
        if (res.data.resultat) {
          setColumns(Object.keys(res.data.data[0]));
          setData(res.data.data);
          setFilteredData(res.data.data);
        } else {
          setErrorMessage('Aucun résultat trouvé');
          setOpenError(true);
        }
      })
      .catch((error) => {
        setOpen(false);
        setErrorMessage('Erreur lors de la récupération des données');
        setOpenError(true);
      });
  };

  const decoderTypeResultat = (abrev) => {
    const result = typeResultat.find((d) => d.abrev === abrev);
    return result ? result.libelle : abrev;
  };

  useEffect(() => {
    fetchData('resultat_2024_2025');
  }, []);

  // Pagination Logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handleNextPage = () => {
    if (currentPage < Math.ceil(filteredData.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Recherche unique pour toutes les colonnes
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    if (query) {
      const filtered = data.filter((item) => columns.some((col) => item[col].toString().toLowerCase().includes(query)));
      setFilteredData(filtered);
      setCurrentPage(1); // Réinitialiser à la première page après la recherche
    } else {
      setFilteredData(data);
    }
  };

  return (
    <Wrapper>
      <div className="container">
        <div className="import-file">
          <h3>Importer résultat</h3>
          <div className="input">
            <FileUploader
              handleChange={handleFileChange}
              name="file"
              types={fileTypes}
              label="Importer le fichier Excel"
            />
            <Button onClick={handleUpload} variant="contained" color="success" style={{ marginLeft: '10px' }}>
              Importer
            </Button>
          </div>
        </div>
        <Button
          variant="contained"
          onClick={onDownload}
          color="success"
          style={{ margin: '10px' }}
          endIcon={<FileDownloadIcon />}
        >
          Exporter en Excel
        </Button>
        {/* <div className="search-bar">
          <TextField
            margin="dense"
            label="Recherche par n'importe quel champ"
            variant="outlined"
            fullWidth
            value={searchQuery}
            onChange={handleSearch}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <PersonSearchRoundedIcon />
                </InputAdornment>
              ),
            }}
            sx={{ maxWidth: '500px', margin: '10px' }}
          />
        </div> */}
        <PaginationWrapper>
          <Button disabled={currentPage === 1} onClick={handlePreviousPage}>
            Précédent
          </Button>
          <span>
            Page {currentPage} sur {Math.ceil(filteredData.length / itemsPerPage)}
          </span>
          <Button disabled={currentPage === Math.ceil(filteredData.length / itemsPerPage)} onClick={handleNextPage}>
            Suivant
          </Button>
        </PaginationWrapper>
        <table ref={tableRef}>
          <thead>
            <tr>
              {columns.map((col, i) => (
                <th key={i}>{col}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentItems.length > 0 ? (
              currentItems.map((item, i) => (
                <tr key={i}>
                  {columns.map((col, index) => (
                    <td key={index}>
                      {col === 'RESULTAT' ? `${decoderTypeResultat(item[col])} (${item[col]})` : item[col]}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={columns.length} style={{ textAlign: 'center' }}>
                  Aucun résultat disponible
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <PaginationWrapper>
          <Button disabled={currentPage === 1} onClick={handlePreviousPage}>
            Précédent
          </Button>
          <span>
            Page {currentPage} sur {Math.ceil(filteredData.length / itemsPerPage)}
          </span>
          <Button disabled={currentPage === Math.ceil(filteredData.length / itemsPerPage)} onClick={handleNextPage}>
            Suivant
          </Button>
        </PaginationWrapper>
      </div>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <WarningSnackBar open={openError} message={errorMessage} close={() => setOpenError(false)} />
      <SuccessSnackBar open={openSnackSuccess} message={successMessage} close={() => setOpenSnackSuccess(false)} />
    </Wrapper>
  );
};

export default ResultatImportExcel;

const Wrapper = styled.div`
  .container {
    padding: 20px;
    .import-file {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }
    table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 20px;
      th,
      td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: left;
      }
      th {
        background-color: #f4f4f4;
        font-weight: bold;
      }
    }
    .search-bar {
      margin-bottom: 20px;
    }
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;
