export const onlineDocuments = [
  {
    id: 1,
    title: 'Bordereau de versement',
    text:
      "Un bordereau de versement pour les frais de scolarité de <b>472 500 Ariary</b> pour les étudiants en L1 et de <b>682 500 Ariary</b> pour les niveaux Master devra être effectué sur le compte bancaire de l'École Nationale d'Informatique (E.N.I), N° <b>21000135638 01</b>, à l'agence BFV-SG Fianarantsoa, code 00340, ou à l'Accès Banque Madagascar, agence Fianarantsoa, code 00340, N° <b>2410000826237</b>.",
  },
  {
    id: 2,
    title: "Fiche d'inscription",
    text: 'Veuillez télécharger la fiche d’inscription correspondant à votre parcours.',
    button: true,
    documents: [
      {
        parcours: 'L1',
        link: '/documents/rentree/Fiche_inscription_L1_2024.pdf',
      },
      {
        parcours: 'MDI',
        link: '/documents/rentree/Fiche_inscription_MDI.pdf',
      },
      {
        parcours: 'M1 ASI',
        link: '/documents/rentree/Fiche_inscription_M1_ASI.pdf',
      },
      {
        parcours: 'M1',
        link: '/documents/rentree/Fiche_inscription_M1.pdf',
      },
    ],
  },
];

export const physicalDocuments = [
  {
    id: 3,
    title: 'Diplôme du Baccalauréat ou relevé des notes + Diplôme de Licence pour les MASTER',
    text: '',
  },
  //   {
  //     id: 2,
  //     title: "Copie dActe d'Etat civil",
  //     text: "Une (01) copied’Acte d'Etat civil datant de moins d'un an, ou le cas échéant (sous réserve), un bulletin de naissance datant de moins d’un an ou d’une photocopie certifiée conforme de la CIN ou d’un passeport en cours de validité,",
  //   },
  {
    id: 4,
    title: "Une photo d'identité",
    text: 'Une photo d’identité récente et identique en couleur.',
  },
  {
    id: 5,
    title: 'Une enveloppe A4',
    text: 'Non timbrée',
  },
];
