import { Backdrop, Button, CircularProgress } from '@mui/material';
import axios from 'axios';
import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';
import styled from 'styled-components';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SelectComponent from 'shared/designSystem/SelectComponent';
import * as moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');

const Contigence = (props) => {
  /**
   * VARIABLE
   */
  const tableRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [candidats, setCandidats] = useState([]);
  const [choix, setChoix] = useState([]);
  const [listNiveaux, setListNiveaux] = useState([]);
  const [epreuves, setEpreuves] = useState([]);
  const [parcours, setParcours] = useState([]);
  const [filter, setFilter] = useState({
    niveau: '',
    parcour: '',
    choix: null,
    epreuve: null,
  });

  /**
   * LIFECYCLE
   */

  useEffect(() => {
    getAllNiveau();
  }, []);

  useEffect(() => {
    if (!filter) return;
    const niveau = listNiveaux.find((niveau) => {
      return niveau.id === filter.niveau;
    });

    if (!niveau) return;
    const { parcours } = niveau;
    const parcoursValue = parcours.map((p) => {
      return {
        value: p.id,
        label: p.name,
      };
    });
    setParcours(parcoursValue);

    if (filter.parcour) {
      const parcour = parcours.find((parcour) => {
        return parcour.id === filter.parcour;
      });
      if (parcour) {
        const choixValue = parcour.choix.map((c) => {
          return {
            value: c.codeChoix,
            label: c.libelleChoix,
          };
        });
        setChoix(parcour?.choix);
      }
      handleShowContigent();
    }
  }, [filter]);

  /**
   * API
   */
  const getAllNiveau = () => {
    setOpen(true);
    axios.get('/niveaux').then(
      (res) => {
        setListNiveaux(res.data);
        setOpen(false);
      },
      (error) => {
        setOpen(false);
        console.error('error');
      }
    );
  };

  const getContigence = () => {
    setOpen(true);
    try {
      axios
        .post('candidat/contigences/excel', filter)
        .then((response) => {
          const { candidats } = response.data;
          setCandidats(
            candidats.sort(function (a, b) {
              if (a.convocation.numero < b.convocation.numero) {
                return -1;
              } else {
                return 1;
              }
            })
          );

          setOpen(false);
        })
        .catch((error) => {})
        .finally(() => {
          setOpen(false);
        });
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * FUNCTION
   */
  const getChoix = (candidat, idChoix) => {
    if (candidat.premierChoix?.codeChoix === idChoix) return 1;
    if (candidat.deuxiemeChoix?.codeChoix === idChoix) return 2;
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `Contigence`,
    sheet: 'Contigence',
  });

  const handleChangeFilterNiveaux = (e) => {
    const { value } = e.target;
    setFilter((oldValue) => {
      return {
        ...oldValue,
        niveau: +value,
        parcour: '',
      };
    });
  };

  const handleChangeParcours = (e) => {
    const { value } = e.target;
    setFilter((oldValue) => {
      return {
        ...oldValue,
        parcour: +value,
      };
    });
  };

  const handleShowContigent = () => {
    getContigence();
  };

  const filterNiveau = () => {
    if (!filter.niveau) return '';
    const niveau = listNiveaux.find((niveau) => niveau.id === filter.niveau);
    if (!niveau) return '';
    return niveau.nom;
  };

  const filterParcour = () => {
    if (!filter.parcour) return '';

    const parcour = parcours.find((parcour) => {
      return parcour.value === filter.parcour;
    });
    if (!parcour) return '';

    return parcour.label;
  };

  const transformDate = (date) => {
    return moment(date).format('DD/MM/YYYY');
  };

  return (
    <Wrapper>
      <div className="container">
        <Button
          variant="contained"
          onClick={onDownload}
          color="success"
          className="exportexcel"
          endIcon={<FileDownloadIcon />}
        >
          Export excel
        </Button>
        {/* AFFICHER LISTE PAR NIVEAU */}
        <SelectComponent
          onChange={handleChangeFilterNiveaux}
          placeholder="Afficher par niveau"
          options={[
            { value: '', label: '-' },
            ...listNiveaux.map((niveau) => ({
              value: niveau.id,
              label: niveau.nom,
            })),
          ]}
          className="select-filter"
        />

        <SelectComponent
          onChange={handleChangeParcours}
          placeholder="Afficher"
          options={[{ value: '', label: '-' }, ...parcours]}
          className="select-filter"
          value={filter.parcour}
        />

        {/* <SelectComponent
          placeholder="Afficher"
          options={[{ value: '', label: '-' }, ...choix]}
          className="select-filter"
        />

        <SelectComponent
          placeholder="Matiere"
          options={[
            { value: '', label: '-' },
            ...epreuves.map((epreuve) => ({
              value: epreuve.codeEpreuve,
              label: epreuve.libelleEpreuve,
            })),
          ]}
          className="select-filter"
        /> */}
        <Button onClick={handleShowContigent} variant="contained" color="success" className="exportexcel">
          Afficher contigent
        </Button>
        <span></span>
        {/* {loading ? (
          <p>Chargement ...</p>
        ) : (
          <> */}
        <div>
          <p>
            <b>Niveau : </b>
            {filterNiveau()}
          </p>
          <p>
            <b>Parcours:</b> {filterParcour()}
          </p>
          <p>
            <b>{candidats.length}</b> candidats
          </p>
        </div>
        <table ref={tableRef}>
          <thead>
            <tr>
              <td>Numéro</td>
              <td>Nom et prénom</td>
              <td>Téléphone</td>
              {choix.map((c, i) => (
                <th key={c.libelleChoix}> {c.libelleChoix}</th>
              ))}
              <td>Serie</td>
              <td>Mention</td>
              <td>Année</td>
              <td>Centre bacc</td>
              <td>Numéro borderaux</td>
              <td>Date borderaux</td>
              <td>Agence</td>
              <td>Date de naissance</td>
              <td>Lieu de naissance</td>
              <td>Centre concours</td>
              <td>Nom du père</td>
              <td>Nom de la mère</td>
              <td>Adresse parents</td>
            </tr>
          </thead>
          <tbody>
            {candidats.map((c, i) => (
              <tr key={`${c.convocation.numero}_${i}`}>
                <td>
                  <b>{c.convocation.numero}</b>
                </td>
                <td>
                  <b>
                    {c.nom} {c.prenoms}
                  </b>
                </td>
                <td>{c.telephone}</td>
                {choix.map((ch, i) => (
                  <td key={ch.libelleChoix + '_' + i}>{getChoix(c, ch.codeChoix)}</td>
                ))}
                <td>{c.codeSerie.libelleSerie}</td>
                <td>{c.mention}</td>
                <td>{c.anneeBacc}</td>
                <td>{c?.codeCentreBacc?.codeFaritany.toString()[0]}</td>
                <td>{c?.numBord}</td>
                <td>{transformDate(c?.dateBord)}</td>
                <td>{c?.agenceBord}</td>
                <td>{transformDate(c?.dateNaissExacte)}</td>
                <td>{c?.lieuNaissance}</td>
                <td>{c?.codeCentre?.plage}</td>
                <td>{c?.nomPere}</td>
                <td>{c?.nomMere}</td>
                <td>{c?.adresseParent}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Wrapper>
  );
};

export default Contigence;
const Wrapper = styled.div`
  .container {
    padding: 10px;
    .exportexcel {
      margin: 10px;
    }
    table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
    }

    td,
    th {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }

    tr:nth-child(even) {
      // background-color: #dddddd;
    }
  }
`;
