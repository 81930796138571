import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { useEffect } from 'react';
import { MenuItem } from '@mui/material';
import SuccessSnackBar from '../Snackbar/SuccessSnackBar';

const EditDialog = (props) => {
  const [openSnack, setOpenSnack] = useState(false);

  const [typeCandidats, setTypeCandidats] = useState([]);

  const [droitConcours, setDroitConcours] = useState('');
  const [fraisScolaire, setFraisScolaire] = useState('');
  const [parametres, setParametres] = useState(1);
  const [typeCandidat, setTypeCandidat] = useState(0);

  useEffect(() => {
    getTypeCandidats();
    axios
      .get(`/droit/relation/${props.id}`)
      .then((res) => {
        setDroitConcours(res.data.droitConcours);
        setFraisScolaire(res.data.fraisScolaire);
        setTypeCandidat(res.data.typeCandidat.codeTypeCand);
      })
      .catch((err) => {});
  }, []);

  const getTypeCandidats = () => {
    axios.get(`/typeCandidat`).then((res) => {
      setTypeCandidats(res.data);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put(`/droit/${props.id}`, {
        droitConcours: droitConcours,
        fraisScolaire: fraisScolaire,
        parametres: parametres,
        typeCandidat: typeCandidat,
      })
      .then((res) => {
        setOpenSnack(true);
        setTimeout(() => {
          props.onClose();
        }, 2000);
      });
  };

  return (
    <div>
      <Dialog open={props.open} onClose={props.onClose} maxWidth="xs">
        <form onSubmit={(e) => handleSubmit(e)}>
          <DialogTitle>Modifier droit</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="droitConcours"
              label="Droit Concours"
              type="number"
              fullWidth
              variant="outlined"
              value={droitConcours}
              onChange={(e) => setDroitConcours(e.target.value)}
              required
            />
            <TextField
              margin="dense"
              id="fraisScolaire"
              label="Frais Scolaire"
              type="number"
              fullWidth
              variant="outlined"
              value={fraisScolaire}
              onChange={(e) => setFraisScolaire(e.target.value)}
              required
            />
            <TextField
              margin="dense"
              select
              label="Type Candidat"
              fullWidth
              value={typeCandidat}
              onChange={(e) => setTypeCandidat(e.target.value)}
            >
              {typeCandidats?.map((option) => (
                <MenuItem key={option.codeTypeCand} value={option.codeTypeCand}>
                  {option.libelleTypeCand}
                </MenuItem>
              ))}
            </TextField>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onClose}>Annuler</Button>
            <Button type="submit" color="primary" variant="contained">
              Modifier
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <SuccessSnackBar open={openSnack} message="Droit modifié avec succès!" close={() => setOpenSnack(false)} />
    </div>
  );
};

export default EditDialog;
