import React from 'react';
import styled from 'styled-components';
import * as styles from 'styles/variables';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

import ValidationIcon from 'components/FormUI/ValidationIcon';


export default function FormAccordion({ title, children, isValid, ...otherProps }) {
  return (
    <Wrapper>
      <Accordion {...otherProps}
        className="accordion"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreRoundedIcon className="expandMoreIcon" />}
          id={title.replace(/\s/g, '')}
          className="title"
        >

          <span>
            {title} 
            <ValidationIcon isValid={isValid} className="icon"/>
          </span>

        </AccordionSummary>
        <AccordionDetails className='body'>

          {/* form ui goes here */}
          {children}

        </AccordionDetails>
      </Accordion>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  div.accordion{
      border-radius: 15px;
      border: 1px solid #E0E0E0;
      box-shadow: none;
    .title{
      color: ${styles.colors.darkBlue};
      font-size: 20px;
      font-weight: bold;
      height: 44px;
      margin: 7px;
      border-radius: 15px;
      transition: all .2s ease;
      span{
        display: flex;
        align-items: center;
        .icon{
          margin-left: 10px;
        }
      }
      &.Mui-expanded{
        background: rgba(2, 4, 74, 0.04);
      }
      .expandMoreIcon{
        font-size: 2.3em;
        color: ${styles.colors.darkBlue};
      }
    }

    div.body{
      
    }
  }
  
`;