import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import NoteInput from './NoteInput';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';
import Pagination from './Pagination';

const TableNote = ({ filter, setOpen }) => {
  // État pour la pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [candidats, setCandidats] = useState([]);
  const itemsPerPage = 10; // Nombre d'éléments à afficher par page
  const [filters, setFilters] = useState(null); // Initialisation de l'état des filtres

  // Calcul du nombre total de pages
  const totalPages = Math.ceil(candidats.length / itemsPerPage);

  /**
   * LIFECYCLE
   */
  useEffect(() => {
    // S'assurer que `filter` est bien défini avant de le mettre dans `filters`
    if (filter.parcour !== '' && filter.epreuve !== '' && filter.niveau !== '') {
      setFilters(filter); // Mettre à jour `filters` avec le nouveau `filter`
      setCurrentPage(1); // Réinitialiser à la première page lors du changement de filtre
      getCandidat(filter); // Passer le nouveau filtre à la fonction `getCandidat`
    }
  }, [filter]);

  // Fonction pour aller à la page précédente
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Fonction pour aller à la page suivante
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleFirstPage = () => {
    setCurrentPage(1); // Aller à la première page
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages); // Aller à la dernière page
  };

  const handleNoteChange = (e, c) => {
    const { value } = e.target;
    // Passer `filters` comme argument pour éviter d'utiliser une ancienne valeur
    debouncedHandleAddNote(value, c, filters);
  };

  // Fonction de gestion de l'ajout de note avec debouncing
  const debouncedHandleAddNote = useCallback(
    debounce((value, c, currentFilters) => {
      if (currentFilters) {
        handleAddNote(value, c, currentFilters); // Passer `currentFilters` à la fonction `handleAddNote`
      }
    }, 600), // 600 ms de délai
    []
  );

  /**
   * API
   */
  const getCandidat = (currentFilters) => {
    setOpen(true);
    axios
      .post(`/candidat/filter/niveau`, currentFilters) // Utiliser le `currentFilters` passé
      .then((res) => {
        setCandidats(res.data.data); // Charger tous les candidats
        setOpen(false);
      })
      .catch((error) => {
        setOpen(false);
        console.error('Erreur lors de la récupération des candidats:', error);
      });
  };

  const handleAddNote = (value, candidat, currentFilters) => {
    axios
      .post(`/composer`, {
        codeEpreuve: currentFilters.epreuve,
        niveauId: currentFilters.niveau,
        note: value,
        numInscription: candidat.id,
        parcoursId: currentFilters.parcour,
        presence: true,
      })
      .then((res) => {
        const { composer, numInscription } = res.data;
        // Mettre à jour la note du candidat dans l'état `candidats`
        setCandidats((prevCandidats) =>
          prevCandidats.map((cand) => (cand.id === numInscription ? { ...cand, note: composer.note } : cand))
        );
      })
      .catch((error) => {
        toast.error("Erreur lors de l'ajout de la note:", error);
      });
  };

  // Calculer les éléments de la page actuelle
  const currentCandidats = candidats.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <TableWrapper>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        handlePreviousPage={handlePreviousPage}
        handleNextPage={handleNextPage}
        handleFirstPage={handleFirstPage}
        handleLastPage={handleLastPage}
      />
      <StyledTable>
        <thead>
          <tr>
            <th>Numéro</th>
            <th>Note</th>
            <th>Absence</th>
          </tr>
        </thead>
        <tbody>
          {currentCandidats.map((c, i) => (
            <tr key={`${c.id}-${currentPage}`}>
              {' '}
              {/* Utilisation d'une clé unique pour chaque ligne */}
              <td>
                <b>{c.convocation.numero}</b>
              </td>
              <td>
                <InputWrapper>
                  <NoteInput
                    key={`${c.id}-${c.note}`} // Clé unique pour forcer le re-rendering si la note change
                    convocationNumero={c.convocation.numero}
                    onNoteChange={(e) => handleNoteChange(e, c)}
                    defaultValue={c.note !== null ? c.note : ''} // Valeur par défaut si null
                  />
                </InputWrapper>
              </td>
              <td>{/* Ajoutez ici le gestionnaire pour l'absence si nécessaire */}</td>
            </tr>
          ))}
        </tbody>
      </StyledTable>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        handlePreviousPage={handlePreviousPage}
        handleNextPage={handleNextPage}
        handleFirstPage={handleFirstPage} // Prop pour la première page
        handleLastPage={handleLastPage}
      />
    </TableWrapper>
  );
};

export default TableNote;

// Styled components
const TableWrapper = styled.div`
  margin: 20px;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;

  th,
  td {
    border: 1px solid #ddd;
    text-align: left;
  }

  th {
    background-color: #f4f4f4;
    font-weight: bold;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  tr:hover {
    background-color: #f1f1f1;
  }

  td {
    font-size: 16px;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

const PaginationButton = styled.button`
  padding: 10px 20px;
  margin: 0 5px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const InputWrapper = styled.div`
  .note input {
    width: 100%;
    padding: 8px;
    margin: 8px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
`;
