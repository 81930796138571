import React from 'react';
import styled from 'styled-components';

import { documents } from '../../../constants/homeData';
import { FilledButton, OutlineButton } from '../../../styles/candidat/Button.styled';
import * as styles from '../../../styles/variables';

import DownloadIcon from '@mui/icons-material/Download';

import Points from '../../../assets/img/geometry-form/points.png';

const TimeTables = () => {
  return (
    <DocumentsWrapper>
      <div className="header">
        <h2>Emplois du temps</h2>
        <img src={Points} alt="points" className="points" />
      </div>
      <div className="list">
        <div className="item">
          <div className="content">
            <p className="title"> L1 Métier Digital</p>
            <a href="images/L1_mdi.png" target="_blank" style={{ textDecoration: 'none' }}>
              <FilledButton style={{ marginTop: 20 }}>Voir l'emploi du temps</FilledButton>
            </a>
          </div>
        </div>
        <div className="item">
          <div className="content">
            <p className="title"> L1 Informatique</p>
            <a href="images/L1_info.png" target="_blank" style={{ textDecoration: 'none' }}>
              <FilledButton style={{ marginTop: 20 }}>Voir l'emploi du temps</FilledButton>
            </a>
          </div>
        </div>
        <div className="item">
          <div className="content">
            <p className="title"> M1 (IA)</p>
            <a href="images/M1.png" target="_blank" style={{ textDecoration: 'none' }}>
              <FilledButton style={{ marginTop: 20 }}>Voir l'emploi du temps</FilledButton>
            </a>
          </div>
        </div>
      </div>
    </DocumentsWrapper>
  );
};

const DocumentsWrapper = styled.div`
  margin-top: 100px;
  max-width: 1280px;
  width: 100%;
  /* position: relative; */

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    /* position: relative; */

    .points {
      position: absolute;
      z-index: -1;
      width: 12vw;
      height: auto;
      right: -70px;

      @media (max-width: ${styles.breakpoints.sm}) {
        width: 80px;
        height: auto;
        right: -30px;
      }
    }

    h2 {
      color: ${styles.colors.lightBlue};
    }

    p,
    strong {
      font-size: 14px;
      max-width: 600px;
    }
  }

  .list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 80px;
    .item {
      flex-basis: 45%;
      display: flex;
      margin-bottom: 10px;
      h1 {
        margin: 0;
        color: ${styles.colors.lightBlue};
      }
      .content {
        margin-left: 10px;
      }
      .title {
        font-size: 16px;
        font-weight: bold;
      }
      .text {
        font-size: 14px;
      }
    }
    @media (max-width: ${styles.breakpoints.md}) {
      flex-direction: column;
    }
  }
`;

export default TimeTables;
