import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { apiRoute, token } from '../GlobalVariable';
import SuccessSnackBar from '../Snackbar/SuccessSnackBar';
import { FormControl } from '@mui/material';

export default function EditDialog(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [openSnack, setOpenSnack] = useState(false);

  const [libelleChoix, setLibelleChoix] = useState('');
  const [limiteAge, setLimiteAge] = useState(0);
  const [limiteBacc, setLimiteBacc] = useState(0);
  const [nbPlaces, setNbPlaces] = useState(0);
  const [nbListeAttente, setNbListeAttente] = useState(0);

  useEffect(() => {
    axios
      .get(`/choix/${props.codeChoix}`)
      .then((res) => {
        setLibelleChoix(res.data.libelleChoix);
        setLimiteAge(res.data.limiteAge);
        setLimiteBacc(res.data.limiteBacc);
        setNbPlaces(res.data.nbPlaces);
        setNbListeAttente(res.data.nbListeAttente);
      })
      .catch((err) => {});
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put(`/choix/${props.codeChoix}`, {
        libelleChoix: libelleChoix,
        limiteAge: limiteAge,
        limiteBacc: limiteBacc,
        nbPlaces: nbPlaces,
        nbListeAttente: nbListeAttente,
      })
      .then((res) => {
        setOpenSnack(true);
        setTimeout(() => {
          props.onClose();
        }, 2000);
      })
      .catch((err) => {});
  };

  return (
    <div>
      <Dialog open={props.open} onClose={props.onClose} maxWidth="xs">
        <form onSubmit={(e) => handleSubmit(e)}>
          <DialogTitle>Modifier choix</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="libelleChoix"
              label="Libellé choix"
              type="text"
              fullWidth
              variant="standard"
              value={libelleChoix}
              onChange={(e) => setLibelleChoix(e.target.value)}
              required
            />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <TextField
                margin="dense"
                id="limiteAge"
                label="Limite Age"
                type="number"
                variant="standard"
                style={{ width: '40%' }}
                value={limiteAge}
                onChange={(e) => setLimiteAge(e.target.value)}
                required
              />
              <TextField
                margin="dense"
                id="limiteBacc"
                label="Limite Bacc"
                type="number"
                variant="standard"
                value={limiteBacc}
                style={{ width: '40%' }}
                onChange={(e) => setLimiteBacc(e.target.value)}
                required
              />
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <TextField
                margin="dense"
                id="nbPlaces"
                label="Nombre de places"
                type="number"
                style={{ width: '40%' }}
                variant="standard"
                value={nbPlaces}
                onChange={(e) => setNbPlaces(e.target.value)}
                required
              />
              <TextField
                margin="dense"
                id="nbListeAttente"
                label="Nombre liste d'attente"
                type="text"
                style={{ width: '40%' }}
                variant="standard"
                value={nbListeAttente}
                onChange={(e) => setNbListeAttente(e.target.value)}
                required
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onClose}>Annuler</Button>
            <Button type="submit" color="primary" variant="contained">
              Modifier
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <SuccessSnackBar open={openSnack} message="Choix modifié" onClose={() => setOpenSnack(false)} />
    </div>
  );
}
