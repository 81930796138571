import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import SuccessSnackBar from '../Snackbar/SuccessSnackBar';

const CreateDialog= (props) => {
  const [openSnack, setOpenSnack] = useState(false);
  const [libelleTypeCand, setLibelleTypeCand] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('/typecandidat',{
      libelleTypeCand: libelleTypeCand
    }).then(res => {
      setOpenSnack(true);
      props.onClose();
      props.fetchData();
    })
  }

  return (
    <div>
      <Dialog open={props.open} onClose={props.onClose}>
        <form onSubmit={(e) => handleSubmit(e)}>
          <DialogTitle>Ajouter un nouveau type de candidat</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="libelleTypeCand"
              label="Libellé type candidat"
              type="text"
              fullWidth
              variant="outlined"
              required
              value={libelleTypeCand}
              onChange={(e) => setLibelleTypeCand(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onClose}>Annuler</Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
            >Ajouter</Button>
          </DialogActions>
        </form>
      </Dialog>
      <SuccessSnackBar open={openSnack} message="Type candidat crée" close={() => setOpenSnack(false)} />
    </div>
  );
}

export default CreateDialog;