import React, { useState } from "react";
import { Button } from "@mui/material";
import CreateDialog from "components/Dashboard/Droit/CreateDialog";
import ChoixTable from "components/Dashboard/Choix/ChoixTable";
import DroitTable from "components/Dashboard/Droit/DroitTable";
import axios from "axios";
import { useEffect } from "react";


const Droit = () => {

  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  const [data, setData] = useState([]);

  const fetchData = () => {
    axios.get('/droit')
      .then(res => {
        setData(res.data)
      }) 
  }

  useEffect(() => {
    fetchData();
  }, [])
 

    return (
      <>
      <div>
        <Button variant='contained' onClick={() => setOpenCreateDialog(true)}>Ajouter</Button>
        <CreateDialog 
          open={openCreateDialog}
          onClose={() => setOpenCreateDialog(false)}
          fetchData={fetchData}
        />
      </div>
        <DroitTable fetchData={fetchData} data={data} />
      </>
    );
}

export default Droit;
