import axios from 'axios';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as styles from 'styles/variables';
import { apiRouteFile, apiRouteFileConvocation } from '../GlobalVariable';

import { useNavigate } from 'react-router-dom';

import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import ArticleIcon from '@mui/icons-material/Article';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import SendIcon from '@mui/icons-material/Send';
import { Button, IconButton, Tooltip } from '@mui/material';
import { DataGrid, GridPagination, GridToolbar } from '@mui/x-data-grid';
import * as moment from 'moment';
import 'moment/locale/fr';
import InfoSnackBar from '../Snackbar/InfoSnackBar';
import SuccessSnackBar from '../Snackbar/SuccessSnackBar';
import WarningSnackBar from '../Snackbar/WarningSnackBar';
import EditerBacc from './EditerBacc';
import CustomDataTable from 'components/shared/CustomDataTable';

moment.locale('fr');

const CandidatTable = (props) => {
  const navigate = useNavigate();
  const [openUndefinedSnack, setOpenUndefinedSnack] = useState(false);
  const [openInfoSnack, setOpenInfoSnack] = useState(false);
  const [succesMessage, setSuccesMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [infoMessage, setInfoMessage] = useState('');
  const [openSnack, setOpenSnack] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [id, setId] = useState(null);
  const [disabledBoutonSend, setDisabledBoutonSend] = useState(false);

  const downloadPieceJointCandidate = (id, candidat) => {
    axios.get(`/candidat/downloadDocument/${id}`).then((res) => {
      const a = document.createElement('a');
      a.style.display = 'none';
      document.body.appendChild(a);
      let url = `${apiRouteFile}/${candidat.convocation.numero}.zip`;
      a.href = url;
      a.download = `${candidat.convocation.numero}.zip`;
      a.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const sendConvocationManually = (candidate) => {
    setDisabledBoutonSend(true);
    setOpenInfoSnack(true);
    setOpenSnack(false);
    setOpenUndefinedSnack(false);
    setInfoMessage('Envoie convocation en cours ...');
    axios
      .post(`/convocation/sendconvocationmanually`, {
        numero: candidate.convocation.numero,
        idCandidat: candidate.numInscription,
        nomCentreConcours: candidate.convocation.nomCentreConcours,
        download: false,
      })
      .then((res) => {
        if (res.data.error === false) {
          setOpenInfoSnack(false);
          setOpenSnack(true);
          setDisabledBoutonSend(false);
          setSuccesMessage('Convocation envoyé avec succèes');
        } else {
          setOpenInfoSnack(false);
          setOpenUndefinedSnack(true);
          setDisabledBoutonSend(false);
          setErrorMessage("Erreur lors de l'envoi de la convocation");
        }
      });
  };

  useEffect(() => {
    if (succesMessage !== '') {
      setOpenSnack(true);
    }
  }, [succesMessage]);

  useEffect(() => {
    if (errorMessage !== '') {
      setOpenUndefinedSnack(true);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (infoMessage !== '') {
      setOpenInfoSnack(true);
    }
  }, [infoMessage]);

  // Définir les styles conditionnels pour colorer les lignes
  const conditionalRowStyles = [
    {
      when: (row) => row.convocation?.numero, // Condition pour colorer la ligne
      style: {
        backgroundColor: '#b8f2c8', // Couleur de fond si la condition est vraie
      },
    },
  ];

  const columns = [
    {
      name: '#id',
      selector: (row) => row.numInscription,
      sortable: true,
      headerClassName: 'tableHeader',
      width: '80px', // Définir une largeur plus petite pour l'en-tête ID
    },
    {
      name: 'Convocation',
      selector: (row) => row.convocation?.numero,
      cell: (row) => <strong>{row.convocation?.numero}</strong>,
      headerClassName: 'tableHeader',
      sortable: true,
    },
    {
      name: 'Nom',
      selector: (row) => row.nom,
      sortable: true,
      headerClassName: 'tableHeader',
      grow: 1,
    },
    {
      name: 'Prénoms',
      selector: (row) => row.prenoms,
      headerClassName: 'tableHeader',
      sortable: true,
    },
    {
      name: 'Téléphone',
      selector: (row) => row.telephone,
      headerClassName: 'tableHeader',
      grow: 1,
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row) => row.mel,
      headerClassName: 'tableHeader',
      grow: 1,
      sortable: true,
    },
    {
      name: 'N°CIN',
      selector: (row) => row.numCIN,
      headerClassName: 'tableHeader',
      grow: 1,
      sortable: true,
    },
    {
      name: 'Mention',
      selector: (row) => row.mention,
      headerClassName: 'tableHeader',
      grow: 1,
      sortable: true,
    },
    {
      name: 'Annee',
      selector: (row) => row.anneeBacc,
      headerClassName: 'tableHeader',
      grow: 1,
      sortable: true,
    },
    {
      name: 'Action',
      cell: (row) => (
        <Action>
          <Tooltip title="Plus d'informations" placement="bottom">
            <IconButton onClick={() => navigate(`${row.numInscription}`)}>
              <ArrowForwardRoundedIcon />
            </IconButton>
          </Tooltip>
          {row.convocation !== null && (
            <Tooltip title="Télécharger convocation" placement="bottom">
              <IconButton onClick={() => imprimerConvocation(row)}>
                <ArticleIcon />
              </IconButton>
            </Tooltip>
          )}
          {row.convocation !== null && row.mel ? (
            <Tooltip title="Envoyer convocation" placement="bottom">
              <IconButton disabled={disabledBoutonSend} onClick={() => sendConvocationManually(row)}>
                <SendIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <></>
          )}
          {row.convocation !== null ? (
            <Tooltip title="Télécharger les pieces jointes de ce candidat" placement="bottom">
              <IconButton onClick={() => downloadPieceJointCandidate(row.numInscription, row)}>
                <CloudDownloadIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <></>
          )}
        </Action>
      ),
      headerClassName: 'tableHeader',
      grow: 1,
    },
  ];

  const imprimerConvocation = (candidate) => {
    axios
      .post(`/convocation/sendconvocationmanually`, {
        numero: candidate.convocation.numero,
        idCandidat: candidate.numInscription,
        nomCentreConcours: candidate.convocation.nomCentreConcours,
        download: true,
      })
      .then(
        (res) => {
          const a = document.createElement('a');
          a.style.display = 'none';
          document.body.appendChild(a);
          const convocation = spliceConvocation(candidate.convocation.numero);
          let url = `${apiRouteFileConvocation}/Convocation_${convocation}.pdf`;
          a.href = url;
          a.download = `Convocation_${convocation}.pdf`;
          a.setAttribute('target', '_blank');
          a.click();
          window.URL.revokeObjectURL(url);
        },
        (error) => {
          setErrorMessage('Erreur lors du téléchargement convocation');
        }
      );
  };

  const spliceConvocation = (data) => {
    return `${data.slice(0, 2)} ${data.slice(2)}`;
  };

  const handleCloseEditDialog = (data) => {
    props.modificationCandidat(data);
    setOpenEditDialog(false);
    setId(null);
  };

  return (
    <Wrapper>
      {id != null && <EditerBacc open={openEditDialog} onClose={handleCloseEditDialog} id={id} />}
      {props.filtered && (
        <CustomDataTable data={props.filtered} columns={columns} conditionalRowStyles={conditionalRowStyles} />
      )}
      <SuccessSnackBar open={openSnack} message={succesMessage} close={() => setOpenSnack(false)} />
      <WarningSnackBar open={openUndefinedSnack} message={errorMessage} close={() => setOpenUndefinedSnack(false)} />
      <InfoSnackBar open={openInfoSnack} message={infoMessage} close={() => setOpenInfoSnack(false)} />
    </Wrapper>
  );
};

export default CandidatTable;
const Wrapper = styled.div`
  .tableHeader {
    background-color: ${styles.colors.drawerBg};
    background-color: #acacac;
    color: white;
  }
  .valide-color {
    background-color: #b8f2c8;
  }
`;

const Action = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  width: 100%;
`;
