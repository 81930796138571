import React, { useState, useEffect } from "react";
import EditDialog from "../CentreBacc/EditDialog";
import axios from "axios";
import { apiRoute } from "../GlobalVariable";

import { DataGrid } from "@mui/x-data-grid";
import { IconButton, TextField } from "@mui/material";
import EditRounded from "@mui/icons-material/EditRounded";
import DeleteRounded from "@mui/icons-material/DeleteRounded";
import { CustomToolbar } from "utility/customToolbar";
import WarningSnackBar from "../Snackbar/WarningSnackBar";

const CentreBaccTable = (props) => {
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [codeCentreBacc, setCodeCentreBacc] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [openSnack, setOpenSnack] = useState(false);

  const handleOpenEditDialog = (codeCentreBacc) => {
    setCodeCentreBacc(codeCentreBacc);
    setOpenEditDialog(true);
  };
  const handleCloseEditDialog = (dataUpdated) => {
    props.fetchData();
    setOpenEditDialog(false);
    setCodeCentreBacc(null);
  };

  //Suppression centre bacc
  const handleDelete = (id) => {
    if (window.confirm("Voulez-vous vraiment supprimer ce centre ?")) {
      axios.delete(`/centrebacc/${id}`).then((res) => {
        props.fetchData();
        setOpenSnack(true);
      });
    }
  };

  const columns = [
    { field: "codeCentreBacc", headerName: "Code Centre Bacc", flex: 1 },
    { field: "nomCentreBacc", headerName: "Nom Centre Bacc", flex: 1 },
    { field: "codeFaritany", headerName: "Faritany", flex: 1 },
    {
      field: "actions",
      headerName: "Action",
      renderCell: (params) => (
        <>
          <IconButton
            title="modifier"
            sx={{ color: "blue" }}
            onClick={() => handleOpenEditDialog(params.id)}
          >
            <EditRounded />
          </IconButton>
          <IconButton
            title="supprimer"
            sx={{ color: "red" }}
            onClick={() => handleDelete(params.id)}
          >
            <DeleteRounded />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <>
      {codeCentreBacc != null ? (
        <EditDialog
          open={openEditDialog}
          codeCentreBacc={codeCentreBacc}
          onClose={handleCloseEditDialog}
        />
      ) : (
        ""
      )}

      {props.data && (
        <DataGrid
          autoHeight
          getRowId={(row) => row.codeCentreBacc}
          rows={props.filtered}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 30, 40]}
          pagination
          onPageChange={(newPageSize) => setPageSize(newPageSize)}
          localeText={{
            MuiTablePagination: {
              labelDisplayedRows: ({ from, to, count }) =>
                `${from} - ${to} sur ${count}`,
              labelRowsPerPage: "Lignes par page",
            },
          }}
        />
      )}
      <WarningSnackBar
        open={openSnack}
        message="Centre bacc supprimé!"
        close={() => setOpenSnack(false)}
      />
    </>
  );
};

export default CentreBaccTable;
