import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { apiRoute } from '../GlobalVariable';
import SuccessSnackBar from '../Snackbar/SuccessSnackBar';

export default function EditDialog(props) {
  const [openSnack, setOpenSnack] = useState(false)

  const [nomFaritany, setNomFaritany] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put(`/faritany/${props.codeFaritany}`, {
        nomFaritany: nomFaritany,
      })
      .then((res) => {
        setOpenSnack(true);
        setTimeout(() => {
          props.onClose()
        }, 2000);
      });
  };

  useEffect(() => {
    axios.get(`/faritany/${props.codeFaritany}`)
      .then(res => {
        setNomFaritany(res.data.nomFaritany)
      })
  }, []);

  return (
    <div>
      <Dialog open={props.open} onClose={props.onClose}>
        <form onSubmit={(e) => handleSubmit(e)}>
          <DialogTitle>Modifier Faritany</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="codeFaritany"
              label="Nom Faritany"
              type="text"
              fullWidth
              value={nomFaritany}
              onChange={(e) => setNomFaritany(e.target.value)}
              variant="standard"
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onClose}>Annuler</Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
            >Modifier</Button>
          </DialogActions>
        </form>
      </Dialog>
      <SuccessSnackBar open={openSnack} message="Faritany modifié avec succès" close={() => setOpenSnack(false)} />
    </div>
  );
}