
import FormAccordion from 'components/FormUI/Accordion'
import { questions } from 'constants/homeData'
import React from 'react'
import styled from 'styled-components'

import * as styles from '../../../styles/variables'

import OrangeTriangle from '../../../assets/img/geometry-form/triangle-orange.png';

const Question = () => {
  return (
    <QuestionWrapper>
      
      <div className="content">
        <div className="title">
          <h1>Les questions fréquemment posées</h1>
        </div>
        <div className='questions'>
          {
            questions.map((question) => (
              <div className="item" key={question.id}>
                <FormAccordion title={question.question}>
                  {question.answer}
                </FormAccordion>
              </div>
            ))
          }
        </div>
      </div>
      
    </QuestionWrapper>
  )
}

const QuestionWrapper = styled.div`


  .content{
    margin-top: 120px;
    margin-bottom: 360px;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }


  .item{
    margin: 60px 0;
  }

  .questions{
    z-index: 50;
    max-width: 900px;
  }

`

export default Question