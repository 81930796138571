import React from 'react';
import styled from 'styled-components';
import Date from '../../components/candidat/Home/Date';
import Documents from '../../components/candidat/Home/Documents';
import Hero from '../../components/candidat/Home/Hero';
import OnlineInscription from '../../components/candidat/Home/OnlineInscription';
import Question from '../../components/candidat/Home/Question';

import * as styles from '../../styles/variables';

import BlueTriangle from '../../assets/img/geometry-form/triangle-blue.png';
import OrangeTriangle from '../../assets/img/geometry-form/triangle-orange.png';
import TimeTables from 'components/candidat/Home/Employ';
import Attachments from 'components/candidat/Home/Attachment';

export default function Home() {
  return (
    <>
      <HomeWrapper>
        <img src={BlueTriangle} alt="blueTriangle" className="blue-triangle" />
        <img src={OrangeTriangle} alt="Triangle" className="orange-triangle" />
        <Hero />
        <div className="center">
          <Date />
          <TimeTables />
          <Attachments />
          <Documents />
          <OnlineInscription />
          <Question />
        </div>
      </HomeWrapper>
    </>
  );
}

const HomeWrapper = styled.div`
  margin-top: ${styles.size.navHeight};
  overflow-x: hidden;
  position: relative;

  .blue-triangle {
    position: absolute;
    left: -5vw;
    top: 10;
    z-index: -1;
    width: 15vw;
    height: auto;
  }

  .orange-triangle {
    position: absolute;
    width: 25vw;
    height: auto;
    z-index: -1;
    right: -5vw;
    bottom: 10vw;

    @media (max-width: ${styles.breakpoints.md}) {
      width: 30vw;
      bottom: 30vw;
    }
    @media (max-width: ${styles.breakpoints.sm}) {
      display: none;
    }
  }

  .center {
    padding: 0 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
