import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import * as styles from 'styles/variables';
import FormButton from 'components/FormUI/Button';
import TextField from 'components/FormUI/TextField';
import Checkbox from 'components/FormUI/Checkbox';
import Select from 'components/FormUI/Select';
import { FormContext } from '../../FormContext';
import { useFormikContext } from 'formik';
import { TextMaskCustomCIN, TextMaskCustomNumeroTelephone, TextMaskCustomVersAnneNaissance } from 'utility/maskInput';

//La date de naisssance precise pourrait etre inconnue
//a la place on affiche un champ de saisi de l'annee de naissance
const renderDateNaiss = (dateNaissInconnue) => {
  if (dateNaissInconnue) {
    return (
      <div className="anneeNaiss">
        <label htmlFor="dateNaiss">Né(e) vers</label>
        <TextField
          name="dateNaiss"
          placeholder="entrer votre année de naissance"
          type="number"
          inputComponent={TextMaskCustomVersAnneNaissance}
        />
      </div>
    );
  }

  return <TextField name="dateNaissExacte" label="Date de naissance *" tooltip="Date de naissance" type="date" />;
};

const fields = [
  'nom',
  'prenoms',
  'dateNaiss',
  'dateNaissExacte',
  'lieuNaissance',
  'telephone',
  'mel',
  'situationMatrimoniale',
  'nomPere',
  'nomMere',
  'adresseParent',
];

export default function InfosPersos({ nextAccordion, prevAccordion, setIsValids, ...otherProps }) {
  const [dateNaissInconnue, setDateNaissInconnue] = useState(false);
  const { setExpanded } = useContext(FormContext);
  const { setFieldValue, values, errors } = useFormikContext();
  const [isNumCINempty, setIsNumCINempty] = useState(true);
  const [isDateDuplicataEmpty, setIsDateDuplicataEmpty] = useState(true);
  const [isLieuDuplicataDisabled, setIsLieuDuplicataDisabled] = useState(true);

  useEffect(() => {
    if (values.dateNaissInconnue) {
      setFieldValue('dateNaissExacte', '');
    } else {
      setFieldValue('dateNaiss', '');
    }
  }, [values.dateNaissInconnue]);

  useEffect(() => {
    setIsNumCINempty(values.numCIN.length == 0);
    setIsLieuDuplicataDisabled(!(values.numCIN.length > 0 && values.dateDuplicata != ''));
    if (values.numCIN.length == 0) {
      setFieldValue('dateCIN', '');
      setFieldValue('lieuCIN', '');
      setFieldValue('dateDuplicata', '');
    }
  }, [values.numCIN]);

  useEffect(() => {
    setIsDateDuplicataEmpty(values.dateDuplicata == '');
    setIsLieuDuplicataDisabled(!(values.numCIN.length > 0 && values.dateDuplicata != ''));
    if (values.dateDuplicata == '') {
      setFieldValue('lieuDuplicata', '');
    }
  }, [values.dateDuplicata]);

  return (
    <Wrapper>
      <div className="fields">
        <TextField name="nom" label="Nom *" tooltip="Votre nom" />
        <TextField name="prenoms" label="Prénoms " tooltip="Votre prénom (laisser vide si vous n'en avez pas)" />

        <div className="dateNaiss">
          {renderDateNaiss(values.dateNaissInconnue)}
          <div className="dateInconnue">
            <Checkbox name="dateNaissInconnue" label="Date de naissance précise inconnue" />
          </div>
        </div>
        <TextField name="lieuNaissance" label="Lieu de naissance *" tooltip="Lieu de naissance *" />
        <div className="telephones">
          <TextField
            name="telephone"
            placeholder="03X XX XXX XX"
            label="Numéro de téléphone *"
            tooltip="Numéro de téléphone "
            inputComponent={TextMaskCustomNumeroTelephone}
          />
        </div>
        <TextField name="mel" label="Adresse email *" tooltip="Votre adresse email" type="email" />

        <Select
          name="situationMatrimoniale"
          label="Situation matrimoniale *"
          tooltip="Situation matrimoniale"
          options={[
            { value: 'C', text: 'Célibataire' },
            { value: 'M', text: 'Marié(e)' },
            { value: 'D', text: 'Divorcé(e)' },
            { value: 'V', text: 'Veuf(ve)' },
          ]}
          className="situationMatrimoniale"
        />

        {/* infos parents */}
        <TextField name="nomPere" label="Nom et prénoms du père " tooltip="Nom et prénoms du père" />
        <TextField name="nomMere" label="Nom et prénoms de la mère *" tooltip="Nom et prénoms de la mère" />
        <TextField name="adresseParent" label="Adresse actuelle des parents *" tooltip="Adresse actuelle des parents" />

        {/* infos CIN */}
        <div className="cinSection">
          <h3>CIN</h3>
          <div className="cin">
            <TextField
              name="numCIN"
              label="Carte d’Identité Nationale N° "
              tooltip="Carte d’Identité Nationale N° si vous etes majeur"
              type="text"
              className="numCin"
              maxLength="12"
              inputComponent={TextMaskCustomCIN}
            />
            <TextField
              name="dateCIN"
              label="Délivrée le *"
              tooltip="Date de délivrance de la carte"
              type="date"
              disabled={isNumCINempty}
            />
            <TextField name="lieuCIN" label="à *" tooltip="Lieu de délivrance de la carte" disabled={isNumCINempty} />
          </div>
          <div className="duplicata">
            <TextField
              name="dateDuplicata"
              label="Duplicata délivré le "
              tooltip="Date de délivrance du duplicata"
              type="date"
              disabled={isNumCINempty}
            />
            <TextField
              name="lieuDuplicata"
              label="à "
              tooltip="Lieu de délivrance du duplicata"
              disabled={isLieuDuplicataDisabled}
            />
          </div>
        </div>
      </div>

      <div className="btn-section">
        <FormButton
          color="secondary"
          onClick={() => {
            setExpanded(prevAccordion);
          }}
        >
          Précédent
        </FormButton>
        <FormButton
          color="primary"
          onClick={() => {
            setExpanded(nextAccordion);
          }}
        >
          Suivant
        </FormButton>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .fields {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    .field,
    .dateNaiss,
    .telephones,
    .situationMatrimoniale {
      /* max-width: 450px; */
      width: 47%;
      @media screen and (max-width: ${styles.breakpoints.md}) {
        width: 100%;
      }
    }
    .dateNaiss {
      .field {
        width: 100%;
      }
      .anneeNaiss {
        display: flex;
        /* align-items: center; */
        label {
          background-color: #d8d8d8;
          border-radius: 5px 0 0 5px;
          min-width: 120px;
          display: flex;
          align-items: center;
          padding-left: 10px;
          height: 40px;
          margin-top: 4px;
        }
        div.input {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          margin-top: 4px;
        }
        .description {
          display: none;
        }
        .helperText {
          /* position: absolute; */
          bottom: 0;
        }
      }
    }
    .telephones {
      .field {
        width: 100%;
      }
      .addPhone {
        color: ${styles.colors.lightBlue};
        cursor: pointer;
      }
    }
    .cinSection {
      width: 100%;
      .cin {
        .field {
          width: inherit;
          &.numCin {
            max-width: 450px;
            width: 100%;
          }
        }
      }
      .cin,
      .duplicata {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 20px;
      }
      .duplicata {
        .field {
          max-width: 450px;
          width: 100%;
        }
      }
    }
  }
`;
