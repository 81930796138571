import React, { useEffect, useState } from 'react';

const TableContigence = ({ contigences, totalContigencePerChoice }) => {
  const [columnHeaders, setColumnHeaders] = useState([]);

  useEffect(() => {
    if (contigences && contigences.length > 0) {
      // Extraire les en-têtes de colonne en prenant les clés du premier objet
      // moins la clé 'Province' qui est toujours présente
      const headers = Object.keys(contigences[0]).filter((key) => key !== 'Province');
      setColumnHeaders(headers);
    }
  }, [contigences]);

  return (
    <>
      <table>
        <thead>
          <tr>
            <th>Province / Parcours</th>
            {columnHeaders.map((header) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {contigences.map((provinceData, index) => (
            <tr key={index}>
              <td>{provinceData.Province}</td>
              {columnHeaders.map((header, i) => (
                <td key={i}>{provinceData[header]?.contigence.toFixed(2)}</td> // Afficher la valeur de "contigence"
              ))}
            </tr>
          ))}
          {/* Ajouter une ligne pour les totaux, si nécessaire */}
          <tr>
            <td>Total</td>
            {columnHeaders.map((header, i) => (
              <td key={i}>{totalContigencePerChoice[header].toFixed(2)}</td>
            ))}
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default TableContigence;
