import React, { useState, useEffect } from 'react';
import EditDialog from '../Choix/EditDialog';

import { DataGrid } from '@mui/x-data-grid';
import { IconButton, TextField } from '@mui/material';
import EditRounded from '@mui/icons-material/EditRounded';
import DeleteRounded from '@mui/icons-material/DeleteRounded';
import axios from 'axios';
import { apiRoute, token } from '../GlobalVariable';
import WarningSnackBar from '../Snackbar/WarningSnackBar';

const ChoixTable = (props) => {
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [codeChoix, setCodeChoix] = useState();

  const [openSnack, setOpenSnack] = useState(false);

  const handleOpenEditDialog = (codeChoix) => {
    setCodeChoix(codeChoix);
    setOpenEditDialog(true);
  };
  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setCodeChoix(null);
    props.fetchData();
  };

  const handleDelete = (id) => {
    if (window.confirm('Voulez-vous vraiment supprimer ce choix?')) {
      axios
        .delete(`/choix/${id}`)
        .then((res) => {
          setOpenSnack(true);
          props.fetchData();
        })
        .catch((err) => {});
    }
  };

  const columns = [
    { field: 'codeChoix', headerName: 'Code Choix', flex: 0.7 },
    { field: 'libelleChoix', headerName: 'Choix', flex: 1 },
    { field: 'limiteAge', headerName: "Limite d'âge", flex: 1 },
    { field: 'limiteBacc', headerName: 'Limite Bacc (ans)', flex: 1 },
    { field: 'nbPlaces', headerName: 'Nombre de places', flex: 1 },
    { field: 'nbListeAttente', headerName: "Liste d'attente", flex: 1 },
    { field: 'plage', headerName: 'Plage', flex: 1 },
    {
      field: 'actions',
      headerName: 'Action',
      renderCell: (params) => (
        <>
          <IconButton title="modifier" sx={{ color: 'blue' }} onClick={() => handleOpenEditDialog(params.id)}>
            <EditRounded />
          </IconButton>
          <IconButton title="supprimer" sx={{ color: 'red' }} onClick={() => handleDelete(params.id)}>
            <DeleteRounded />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <>
      {codeChoix != null ? (
        <EditDialog open={openEditDialog} codeChoix={codeChoix} onClose={handleCloseEditDialog} />
      ) : (
        ''
      )}

      {props.data && (
        <DataGrid
          autoHeight
          getRowId={(row) => row.codeChoix}
          rows={props.filtered}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10, 20, 30]}
          localeText={{
            MuiTablePagination: {
              labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} sur ${count}`,
              labelRowsPerPage: 'Lignes par page',
            },
          }}
        />
      )}
      <WarningSnackBar message="Choix supprimé" open={openSnack} close={() => setOpenSnack(false)} />
    </>
  );
};

export default ChoixTable;
