import React from 'react';
import styled from 'styled-components';
import * as styles from 'styles/variables';
import eniBg from 'assets/img/eni-bg.jpg';

export default function Header() {
  return (
    <Wrapper>
      <div className="inner">
        <h1>Résultats au concours</h1>
        <p>
          Liste des candidats admis au concours d’entrée en première année de Formation en Licence Professionnelle et
          Master à l’Ecole Nationale d’Informatique au titre de l’année universitaire 2024/2025
        </p>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: ${styles.size.navHeight};
  padding: 9vh 5%;
  color: white;
  background: ${styles.colors.darkBlue};
  background-image: url(${eniBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  .inner {
    max-width: ${styles.size.resultatMaxWidth};
    margin: auto;
    p {
      font-size: 1.2em;
    }
  }
`;
