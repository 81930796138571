import React from 'react'
import styled from 'styled-components';
import FormNav from 'components/candidat/Inscription/FormNav';
import FormSection from 'components/candidat/Inscription/FormSection';
import { ValidationProvider } from 'components/candidat/Inscription/FormContext';


export default function Inscription() {
  return (
    <Main>
      <ValidationProvider>
        {/* left navigation  */}
        <FormNav classname="formNav" />
        {/* main content */}
        <FormSection classname="formSection" />
      </ValidationProvider>
    </Main>
  );
}

const Main = styled.div`
    display: flex;
    background-color: white;
`;