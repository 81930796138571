import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { apiRoute, token } from '../GlobalVariable';
import SuccessSnackBar from '../Snackbar/SuccessSnackBar';

export default function EditDialog(props) {
  const [libelleEpreuve, setLibelleEpreuve] = useState('');
  const [coef, setCoef] = useState(0);
  const [duree, setDuree] = useState(0);
  const [dateEpreuve, setDateEpreuve] = useState('');

  const [openSnack, setOpenSnack] = useState(false);

  useEffect(() => {
    if (props.codeEpreuve) {
      axios
        .get(`/epreuve/${props.codeEpreuve}`)
        .then((res) => {
          setLibelleEpreuve(res.data.libelleEpreuve);
          setCoef(res.data.coef);
          setDuree(res.data.duree);
          setDateEpreuve(res.data.dateEpreuve.split('T')[0]);
        })
        .catch((err) => {});
    }
  }, [props.codeEpreuve]);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put(`/epreuve/${props.codeEpreuve}`, {
        libelleEpreuve: libelleEpreuve,
        coef: coef,
        duree: duree,
        dateEpreuve: dateEpreuve,
      })
      .then((res) => {
        setOpenSnack(true);
        setTimeout(() => {
          props.onClose();
        }, 2000);
      });
  };

  return (
    <div>
      <Dialog open={props.open} onClose={props.onClose} maxWidth="xs">
        <form onSubmit={(e) => handleSubmit(e)}>
          <DialogTitle>Modifier Faritany</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="libelleEpreuve"
              label="Libellé Epreuve"
              type="text"
              fullWidth
              variant="standard"
              value={libelleEpreuve}
              onChange={(e) => setLibelleEpreuve(e.target.value)}
              required
            />
            <TextField
              margin="dense"
              id="coef"
              label="Coefficient"
              type="number"
              fullWidth
              variant="standard"
              value={coef}
              onChange={(e) => setCoef(e.target.value)}
              required
            />
            <TextField
              margin="dense"
              id="duree"
              label="Durée(en heure)"
              type="number"
              fullWidth
              variant="standard"
              value={duree}
              onChange={(e) => setDuree(e.target.value)}
              required
            />
            <TextField
              margin="dense"
              id="dateEpreuve"
              label="Date Epreuve"
              type="date"
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="standard"
              value={dateEpreuve}
              onChange={(e) => setDateEpreuve(e.target.value)}
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onClose}>Annuler</Button>
            <Button type="submit" color="primary" variant="contained">
              Modifier
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <SuccessSnackBar open={openSnack} message="Epreuve modifiée avec succès!" close={() => setOpenSnack(false)} />
    </div>
  );
}
