export const documents = [
  {
    id: 1,
    title: "Bordereau de versement",
    text: "Un bordereau de versement d’un montant du droit d’inscription au concours, au compte N°21 000 135 638 01, Société Générale Madagasikara (ex BFV-SG) Agence de Fianarantsoa, Code 00340. 75 000Ar pour les candidats nationaux y compris les élèves-officiers et 150 000Ar pour les candidats étrangers.",
  },
  {
    id: 2,
    title: "Fiche de renseignement",
    text: "Une fiche de renseignements remplie en bonne et due forme (photo, choix de formation, centre de formation, centre d’examen).",
    button: "renseignement",
  },
  {
    id: 3,
    title: "Demande d'inscription",
    text: "Une demande d’inscription sur papier libre (voir modèle) à adresser à Monsieur Le Directeur  de l’Ecole Nationale d’Informatique de Fianarantsoa indiquant le centre d’examen choisi.",
    button: "demande",
  },
  {
    id: 4,
    title: "Diplôme de Baccalauréat",
    text: "Une (01) copie certifiée conforme du Diplôme du Baccalauréat ou un relevé des notes, et le cas échéant, l’étudiant peut concourir sous réserve de fournir la pièce demandée en moment opportun. ",
  },
  {
    id: 5,
    title: "Copie d'acte d'Etat civil",
    text: "Une (01) copie d’Acte d'Etat civil datant de moins d'un an, ou le cas échéant (sous réserve), un bulletin de naissance datant de moins d’un an ou d’une photocopie certifiée conforme de la CIN ou d’un passeport en cours de validité.",
  },
  {
    id: 6,
    title: "Deux enveloppes, petit format",
    text: "Deux enveloppes, petit format,  timbrées à 1 000Ar portant l’adresse précise du (de la) candidat(e) et l'adresse postale de l'ENI BP 1487 (Lors du dépôt physique ou envoi par poste).",
  },
  {
    id: 7,
    title: "Une enveloppe, grand format",
    text: "Une enveloppe, grand format, timbrée à 2 000 Ar portant l’adresse précise du (de la) candidat(e) (Lors du dépôt physique ou envoi par poste).",
  },
  {
    id: 8,
    title: "Photos d'identité",
    text: "Deux photos d’identité récentes et identiques en couleur.",
  },
];

export const questions = [
  {
    id: 1,
    question: "Où pourriez-vous passer les examens?",
    answer:
      "Les examens auront lieu à: Fianarantsoa, Antananarivo, Toliara, Toamasina, Antsiranana, Mahajanga , Tolagnaro, Ambovombe Androy, Ihosy et Manakara.",
  },
  {
    id: 2,
    question: "Quelles seront les épreuves ?",
    answer:
      "Mathématiques - Physique - Test psychotechnique - Français - Anglais.",
  },
  {
    id: 3,
    question:
      "Comment savoir votre inscription en ligne ait bien été prise en compte?",
    answer:
      "Après la soumission de vos dossiers, vous recevrez un e-mail à l'adresse que vous avez utilisée lors de votre inscription.",
  },
  {
    id: 4,
    question: "Les dossiers physiques sont-ils nécessaires?",
    answer:
      "Oui, après soumission de vos dossiers en ligne, il est obligatoire de faire parvenir vos dossiers physiques à la scolarité de l'ENI. A envoyer par poste si vous vous trouvez en province.",
  },
];
