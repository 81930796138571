import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {
  AppBar,
  Avatar,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  Toolbar,
  Typography,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { apiRoute, apiRouteFile } from '../GlobalVariable';
import styled from 'styled-components';
import moment from 'moment';

export default function EditDialog(props) {
  const [numInscription, setNumInscription] = useState(props.numInscription);
  const [situationMa, setSituationMa] = useState([
    { value: 'C', text: 'Célibataire' },
    { value: 'M', text: 'Marié(e)' },
    { value: 'D', text: 'Divorcé(e)' },
    { value: 'V', text: 'Veuf(ve)' },
  ]);
  const [formData, setFormData] = useState({
    ...props.candidat,
    dateNaissExacte: moment(props.candidat.dateNaissExacte).format('YYYY-MM-DD'),
    dateDuplicata:
      props.candidat.dateDuplicata !== null ? moment(props.candidat.dateDuplicata).format('YYYY-MM-DD') : null,
    dateBord: moment(props.candidat.dateBord).format('YYYY-MM-DD'),
  });

  useEffect(() => {
    axios.get(`/candidat/relation/${props.numInscription}`).then((res) => {});
  }, []);

  //Quand on change la valeur dans l'inout
  const handleChangeFormData = (event) => {
    const { value, name, type } = event.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: type === 'number' ? parseFloat(value) : value,
      };
    });
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} fullScreen className="dialog">
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={props.onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Annuler
          </Typography>
          <Button autoFocus color="inherit" onClick={props.onClose}>
            Modifier
          </Button>
        </Toolbar>
      </AppBar>
      <Wrapper>
        <DialogContent className="dialog">
          <Avatar sx={{ width: 120, height: 120, marginRight: '20px' }}>Photo</Avatar>
          <strong>N°Inscription: {numInscription}</strong>
          <p>
            <strong>Informations générales</strong>
          </p>
          <div>
            <TextField
              sx={{ marginRight: 10 }}
              margin="dense"
              id="nom"
              label="Nom"
              name="nom"
              type="text"
              variant="outlined"
              value={formData.nom}
              onChange={handleChangeFormData}
              required
            />
            <TextField
              margin="dense"
              id="codeFaritany"
              label="Prénoms"
              name="prenoms"
              type="text"
              variant="outlined"
              value={formData.prenoms}
              onChange={handleChangeFormData}
              required
            />
          </div>
          <div>
            <TextField
              sx={{ marginRight: 10 }}
              margin="dense"
              id="lieuNaissance"
              name="lieuNaissance"
              label="Lieu de naissance"
              type="text"
              variant="outlined"
              value={formData.prenoms}
              onChange={handleChangeFormData}
              required
            />
            <TextField
              margin="dense"
              id="dateNaissExacte"
              label="Date de naissance"
              type="date"
              name="dateNaissExacte"
              variant="outlined"
              value={formData.dateNaissExacte}
              onChange={handleChangeFormData}
              required
            />
          </div>
          <div>
            {/* <TextField
              sx={{ marginRight: 10 }}
              margin="dense"
              id="situationMatrimoniale"
              label="Situation matrimoniale"
              type="text"
              variant="outlined"
              name="situationMatrimoniale"
              value={formData.situationMatrimoniale}
              onChange={handleChangeFormData}
              required
            /> */}
            <FormControl>
              <InputLabel id="demo-simple-select-label">Situation matrimoniale</InputLabel>
              <Select
                margin="dense"
                labelId="demo-simple-select-label"
                label="Situation matrimoniale"
                name="situationMatrimoniale"
                value={formData.situationMatrimoniale}
                onChange={handleChangeFormData}
              >
                {situationMa.map((d, i) => (
                  <MenuItem key={i} value={d.value}>
                    {d.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <TextField
              sx={{ marginRight: 10 }}
              margin="dense"
              id="numCIN"
              label="Numéro CIN"
              type="text"
              variant="outlined"
              name="numCIN"
              value={formData.numCIN}
              onChange={handleChangeFormData}
              required
            />
            <TextField
              sx={{ marginRight: 10 }}
              margin="dense"
              id="lieuCIN"
              label="Lieu CIN"
              type="text"
              variant="outlined"
              name="lieuCIN"
              value={formData.lieuCIN}
              onChange={handleChangeFormData}
              required
            />
            <TextField
              sx={{ marginRight: 10 }}
              margin="dense"
              id="dateCIN"
              label="Date CIN"
              type="date"
              variant="outlined"
              name="dateCIN"
              value={formData.dateCIN}
              onChange={handleChangeFormData}
              required
            />
          </div>
          <div>
            <TextField
              sx={{ marginRight: 10 }}
              margin="dense"
              id="dateDuplicata"
              label="Date duplicata"
              type="text"
              variant="outlined"
              name="dateDuplicata"
              value={formData.dateDuplicata}
              onChange={handleChangeFormData}
              required
            />
            <TextField
              sx={{ marginRight: 10 }}
              margin="dense"
              id="lieuDuplicata"
              label="Lieu duplicata"
              type="text"
              variant="outlined"
              name="lieuDuplicata"
              value={formData.lieuDuplicata}
              onChange={handleChangeFormData}
              required
            />
          </div>
          <div>
            <p>
              <strong>Contact</strong>
            </p>
            <TextField
              sx={{ marginRight: 10 }}
              margin="dense"
              id="telephone"
              label="Téléphone"
              type="text"
              variant="outlined"
              name="telephone"
              value={formData.telephone}
              onChange={handleChangeFormData}
              required
            />
            <TextField
              sx={{ marginRight: 10 }}
              margin="dense"
              id="mel"
              label="Mail"
              type="text"
              variant="outlined"
              name="mel"
              value={formData.mel}
              onChange={handleChangeFormData}
              required
            />
          </div>
          <div>
            <p>
              <strong>Parents</strong>
            </p>
            <TextField
              sx={{ marginRight: 10 }}
              margin="dense"
              id="nomPere"
              label="Nom du père"
              type="text"
              variant="outlined"
              name="nomPere"
              value={formData.nomPere}
              onChange={handleChangeFormData}
              required
            />
            <TextField
              sx={{ marginRight: 10 }}
              margin="dense"
              id="nomMere"
              label="Nom de la mère"
              type="text"
              variant="outlined"
              name="nomMere"
              value={formData.nomMere}
              onChange={handleChangeFormData}
              required
            />
            <TextField
              sx={{ marginRight: 10 }}
              margin="dense"
              id="adresseParent"
              label="Adresse des parents"
              type="text"
              variant="outlined"
              name="adresseParent"
              value={formData.adresseParent}
              onChange={handleChangeFormData}
              required
            />
          </div>
          <div>
            <p>
              <strong>Bordereau</strong>
            </p>
            <TextField
              sx={{ marginRight: 10 }}
              margin="dense"
              id="numBord"
              label="Numéro Bordereau"
              type="text"
              variant="outlined"
              name="numBord"
              value={formData.numBord}
              onChange={handleChangeFormData}
              required
            />
            <TextField
              sx={{ marginRight: 10 }}
              margin="dense"
              id="dateBord"
              label="Date Bordereau"
              type="date"
              variant="outlined"
              name="dateBord"
              value={formData.dateBord}
              onChange={handleChangeFormData}
              required
            />
            <TextField
              sx={{ marginRight: 10 }}
              margin="dense"
              id="agenceBord"
              label="Agence"
              type="text"
              variant="outlined"
              name="agenceBord"
              value={formData.agenceBord}
              onChange={handleChangeFormData}
              required
            />
            <TextField
              sx={{ marginRight: 10 }}
              margin="dense"
              id="montantBord"
              label="Montant bordereau"
              type="text"
              variant="outlined"
              name="montantBord"
              value={formData.montantBord}
              onChange={handleChangeFormData}
              required
            />
          </div>
        </DialogContent>
      </Wrapper>
    </Dialog>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  .dialog {
    /* background-color: skyblue; */
    max-width: 1280px;
  }
`;
