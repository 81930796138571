import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as styles from 'styles/variables';
import Header from 'components/candidat/Resultats/Header';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import ScrollspyNav from 'react-scrollspy-nav';
import axios from 'axios';
import { Backdrop, CircularProgress } from '@mui/material';

export default function Resultats() {
  const [open, setOpen] = useState(false);
  const [resultats, setResultats] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [query, setQuery] = useState('');
  const [key, setkey] = useState([]);
  const [typeResultat] = React.useState([
    { abrev: 'A', libelle: 'Admis en Audit des Systèmes d’Information', abrevisationScroll: 'ASI' },
    {
      abrev: 'P',
      libelle: 'Admis en Génie logiciel et base de données, administration des systèmes et réseaux',
      abrevisationScroll: 'PRO Fianarantsoa',
    },
    { abrev: 'F', libelle: 'Admis en Informatique Générale Fianarantsoa', abrevisationScroll: 'IG Fianarantsoa' },
    { abrev: 'T', libelle: 'Admis en Informatique Générale Toliara', abrevisationScroll: 'IG Toliara' },
    { abrev: 'M', libelle: 'Admis en Métier digital', abrevisationScroll: 'MDI' },
    { abrev: 'G', libelle: 'Admis en Gouvernance et Ingénierie de données', abrevisationScroll: 'GID' },
    { abrev: 'O', libelle: 'Admis en Objet Connecté et Cybersécurité ', abrevisationScroll: 'OCC' },
  ]);

  const fetchData = () => {
    setOpen(true);
    axios.get('/resultat/readresult/resultat/resultat_2024_2025').then(
      (res) => {
        if (res.data.resultat === true) {
          setkey(Object.keys(res.data.data[0]));
          regrouperCandidats(Object.keys(res.data.data[0]), res.data.data);
          setOpen(false);
        } else {
          setOpen(false);
        }
      },
      (error) => {
        setOpen(false);
      }
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  /**
   * regrouper les candidats / type de resultat
   */
  const regrouperCandidats = (key, data) => {
    //retrieve data from the API
    let foo = [];
    typeResultat.forEach((t) => {
      foo.push({
        admis: data.filter((d) => d['RESULTAT'] === t.abrev),
        parcours: t.libelle,
      });
    });
    setResultats(foo);
  };

  useEffect(() => {
    setFiltered(JSON.parse(JSON.stringify(resultats)));
  }, [resultats]);

  const handleSearch = () => {
    if (query.length > 2 || query === '') {
      let foo = [];
      for (let i = 0; i < resultats.length; i++) {
        const res = JSON.parse(JSON.stringify(resultats[i]));
        const admis = res.admis.filter((candidat) => {
          return (
            candidat['Numéro'].toString().includes(query) ||
            candidat['Nom et prénom'].toLowerCase().includes(query.toLowerCase())
          );
        });
        if (admis.length > 0) {
          res.admis = JSON.parse(JSON.stringify(admis));
          foo.push(res);
        }
      }
      setFiltered(foo);
    }
  };
  return (
    <Main>
      <Header />

      <div className="body">
        <div className="content">
          <div className="searchBar">
            <label htmlFor="searchQuery">
              <SearchRoundedIcon />
            </label>
            <input
              type="text"
              name="query"
              id="searchQuery"
              placeholder="Recherchez par nom ou numéro d’inscription"
              autoFocus
              onKeyUp={handleSearch}
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
          <div className="stickyNav">
            <ScrollspyNav
              scrollTargetIds={resultats.map((res, index) => `parcours-${index}`)}
              scrollDuration="300"
              activeNavClass="active"
              offset={100}
            >
              <ul>
                {typeResultat.map((tp, index) => (
                  <li key={index}>
                    <a href={`#parcours-${index}`} className="">
                      {tp.abrevisationScroll}
                    </a>
                  </li>
                ))}
              </ul>
            </ScrollspyNav>
          </div>
          {resultats.length > 0 ? (
            filtered.length > 0 ? (
              filtered.map((resultat, index) => (
                <div className="parcours" key={index} id={`parcours-${index}`}>
                  <h3>
                    {resultat.parcours}
                    {/* ({resultat.admis.length}) */}
                  </h3>
                  <table>
                    <thead>
                      <tr>
                        <th style={{ textAlign: 'center' }}>Numéro</th>
                        <th>Nom et prénoms</th>
                      </tr>
                    </thead>
                    <tbody>
                      {resultat.admis.map((c, cIndex) => (
                        <tr key={c['Numéro']}>
                          <td style={{ textAlign: 'center', paddingLeft: '27px' }}>{c['Numéro']}</td>
                          <td>{c['Nom et prénom']}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ))
            ) : query != '' ? (
              <h3>
                Aucun résultat pour <strong>"{query}"</strong>
              </h3>
            ) : (
              'Chargement de la liste en cours...'
            )
          ) : (
            <p>Le résultat n'est pas encore publié </p>
          )}
        </div>
      </div>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Main>
  );
}

const Main = styled.div`
  .body {
    padding: 0 5%;
    margin-top: -25px;
    margin-bottom: 40vh;
    .content {
      max-width: ${styles.size.resultatMaxWidth};
      margin: auto;
      position: relative;
      .searchBar {
        background-color: white;
        height: 50px;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.17);
        border-radius: 5px;
        display: flex;
        overflow: hidden;
        margin-bottom: 10vh;
        label {
          width: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.3em;
          color: #a1a1a1;
        }
        input {
          width: 100%;
          border: none;
          font-size: 1em;
          &:focus {
            border: none;
            outline: none;
          }
        }
      }
      .stickyNav {
        position: sticky;
        top: 20vh;
        width: max-content;
        margin-right: -300px;
        margin-left: auto;
        margin-bottom: -200px;
        ul {
          list-style-type: none;
          li {
            margin-bottom: 10px;
            a {
              color: #a1a1a1;
              text-decoration: none;
              font-size: 0.9em;
              &.active {
                font-weight: bold;
                color: ${styles.colors.darkBlue};
              }
            }
          }
        }
        @media screen and (max-width: 1000px) {
          display: none;
        }
      }
      .parcours {
        h3 {
          position: sticky;
          top: 80px;
          background-color: #f9fafc;
          padding: 20px 3%;
        }
        table {
          border-collapse: collapse;
          width: 100%;
          thead {
            th {
              border-bottom: 1px solid #a1a1a1;
              color: #a1a1a1;
              font-weight: bold;
              letter-spacing: 2px;
              text-transform: uppercase;
              height: 42px;
              font-size: 0.9em;
              padding-left: 10px;
              text-align: left;
              &:first-child {
                /* padding-right: 30px; */
              }
            }
          }
          tbody {
            tr {
              &:hover,
              &:nth-child(even) {
                background-color: #ececec;
              }
              td {
                height: 40px;
                padding-left: 10px;
                &:first-child {
                  text-align: right;
                  padding-right: 5%;
                }
              }
            }
          }
        }

        @media screen and (max-width: 1000px) {
          h3 {
            font-size: 16px;
          }
        }
      }
    }
  }
`;
