import React, { useState } from "react";
import ParametreTable from "components/Dashboard/Parametre/ParemetreTable";


const Parametre = () => {
    return (
      <>
        <ParametreTable />
      </>
    );
}

export default Parametre;
