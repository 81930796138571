import React, { useState, useEffect } from 'react';
import EditDialog from '../TypeCandidat/EditDialog';
import axios from 'axios';
import { apiRoute } from '../GlobalVariable';

import { DataGrid } from '@mui/x-data-grid';
import { IconButton, TextField } from '@mui/material';
import EditRounded from '@mui/icons-material/EditRounded';
import DeleteRounded from '@mui/icons-material/DeleteRounded';
import WarningSnackBar from '../Snackbar/WarningSnackBar';
import styled from "styled-components";


const TypeCandidatTable = (props) => {

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [codeTypeCand, setCodeTypeCand] = useState();

  const [openSnack, setOpenSnack] = useState(false)


  const handleOpenEditDialog = (codeTypeCand) => {
    setCodeTypeCand(codeTypeCand);
    setOpenEditDialog(true)
  }
  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    props.fetchData();
    setCodeTypeCand(null);

  }

  const handleDelete = (id) => {
    if(window.confirm('Voulez-vous vraiment supprimer ce type de candidat ?')){
      axios.delete(`/typecandidat/${id}`)
        .then(res => {
          setOpenSnack(true)
          props.fetchData();
        })
    }
  }

  const columns = [
    {field: 'codeTypeCand', headerName: 'Code Type Candidat', flex: 1, headerClassName: 'tableHeader'},
    {field: 'libelleTypeCand', headerName: 'Candidat', flex: 1, headerClassName: 'tableHeader'},
    {
      field: 'actions',
      headerName: 'Action',
      renderCell: (params) => (
        <>
          <IconButton title="modifier" sx={{ color: 'blue' }} onClick={() => handleOpenEditDialog(params.id)} >
            <EditRounded />
          </IconButton>
          <IconButton title="supprimer" sx={{ color: 'red' }} onClick={() => handleDelete(params.id)}>
            <DeleteRounded />
          </IconButton>
        </>
      ),
      headerClassName: 'tableHeader'
    } 

  ]

  return (
    <Wrapper>
    {
      codeTypeCand != null ? <EditDialog open={openEditDialog} codeTypeCand={codeTypeCand} onClose={handleCloseEditDialog} /> : ""
    }
    {props.data && (
      <DataGrid autoHeight
        getRowId={(row) => row.codeTypeCand}
        rows={props.data}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[10,20,30]}
        localeText={{
          MuiTablePagination: {
            labelDisplayedRows: ({ from, to, count }) =>
              `${from} - ${to} sur ${count}`,
            labelRowsPerPage: "Lignes par page",
          },
        }}
      />
    )}
    <WarningSnackBar open={openSnack} message="Type candidat supprimé!" close={() => setOpenSnack(false)} />
    </Wrapper>
  );
}

export default TypeCandidatTable;

const Wrapper = styled.div`
  .tableHeader{
    background-color: #acacac;
    color: white;
  }
`;