import React from 'react';
import styled from 'styled-components';
import ModalImage from 'react-modal-image';

// Conteneur principal pour la galerie
const GalleryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px; // Espacement entre les images
`;

// Style pour chaque élément de la galerie
const GalleryItem = styled.div`
  width: 200px; // Largeur de chaque image
  text-align: center; // Centrer le texte sous les images
`;

// Style pour l'image
const StyledModalImage = styled(ModalImage)`
  cursor: pointer; // Changer le curseur pour indiquer la possibilité de cliquer
`;

const ImageGallery = ({ attachments, apiRouteFile }) => {
  return (
    <GalleryContainer>
      {attachments.map((attachment, index) => (
        <GalleryItem key={index}>
          <StyledModalImage
            small={`${apiRouteFile}/${attachment?.link}`}
            large={`${apiRouteFile}/${attachment?.link}`}
            alt={attachment?.attachment?.name || 'Image'}
            hideDownload={true} // Masquer le bouton de téléchargement
            hideZoom={false} // Afficher le bouton de zoom
          />
          <p>{attachment?.attachment?.name}</p>
        </GalleryItem>
      ))}
    </GalleryContainer>
  );
};

export default ImageGallery;
