import React, { useState, useContext, useEffect } from 'react';
import { useFormikContext } from 'formik';
const infospersosFields = [
  'nom',
  'prenoms',
  'dateNaiss',
  'dateNaissExacte',
  'lieuNaissance',
  'telephone',
  'mel',
  'situationMatrimoniale',
  'nomPere',
  'nomMere',
  'adresseParent',
  'numCIN',
  'dateCIN',
  'lieuCIN',
  'dateDuplicata',
  'lieuDuplicata',
];
const baccFields = ['libelleSerie', 'numBacc', 'anneeBacc', 'centreBacc', 'mention', 'codeCentreBacc'];
const inscriptionFields = [
  'premierChoix',
  'deuxiemeChoix',
  'troisiemeChoix',
  'centre',
  'codeTypeCand',
  'montant',
  'numBord',
  'agenceBord',
  'dateBord',
  'catCandidat',
];

const licenceFiels = ['etablissementLicence', 'anneeLicence', 'mentionLicence'];

export default function SectionValidation({ setIsValids, infoNiveauxSelected, ...otherProps }) {
  const { errors, setErrors, resetForm } = useFormikContext();
  const [first, setFirst] = useState(true);
  const [piecesJointesFields, setPiecesJointesFields] = useState([]);

  useEffect(() => {
    let infosPersosValid = false;
    let baccValid = false;
    let inscriptionValid = false;
    let licenceValid = false;
    let piecesJointesValid = false;

    if (!first) {
      infosPersosValid = infospersosFields.every((field) => {
        return !(field in errors);
      });
      baccValid = baccFields.every((field) => {
        return !(field in errors);
      });
      inscriptionValid = inscriptionFields.every((field) => {
        return !(field in errors);
      });

      licenceValid = licenceFiels.every((field) => {
        return !(field in errors);
      });
      piecesJointesValid = piecesJointesFields.every((field) => {
        return !(field in errors);
      });

      setIsValids((prev) => {
        let isValids = Object.assign({}, prev);
        isValids.infosPersos = infosPersosValid;
        isValids.bacc = baccValid;
        isValids.inscription = inscriptionValid;
        isValids.licence = licenceValid;
        isValids.piecesJointes = piecesJointesValid;
        return isValids;
      });
    }
    setFirst(false);
  }, [errors, piecesJointesFields]);

  useEffect(() => {
    if (infoNiveauxSelected?.attachments) {
      setPiecesJointesFields(infoNiveauxSelected?.attachments.map((attachment) => attachment.name_code));
    }
  }, [infoNiveauxSelected]);
}
