import PersonSearchRoundedIcon from '@mui/icons-material/PersonSearchRounded';
import PrintIcon from '@mui/icons-material/Print';
import { Alert, Button, TextField, Tooltip } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import axios from 'axios';
import CandidatTable from 'components/Dashboard/Candidat/CandidatTable';
import { apiRouteFile } from 'components/Dashboard/GlobalVariable';
import WarningSnackBar from 'components/Dashboard/Snackbar/WarningSnackBar';
import { useEffect, useState } from 'react';
import SelectComponent from 'shared/designSystem/SelectComponent';
import styled from 'styled-components';
import * as styles from 'styles/variables';
import IndexedDbService from 'utility/indexedDbService';

const Candidat = () => {
  /**
   * VARIABLE
   */
  const [filtered, setFiltered] = useState([]);
  const [allCandidat, setAllCandidat] = useState([]);
  const [filter, setFilter] = useState('all');
  const [centreConcours, setCentreConcours] = useState([]);
  const [centreSelected, setCentreSelected] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [openSnack, setOpenSnack] = useState(false);
  const [showNumberCandidat, setShowNumberCandidat] = useState(false);
  const [statistical, setStatistical] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [filterOption, setFilterOption] = useState('Centre');
  const [searchQuery, setSearchQuery] = useState('');
  const dbService = new IndexedDbService('eni', 'candidatStore');
  const [listNiveaux, setListNiveaux] = useState([]);
  const [filterCandidat, setFilterCandidat] = useState({
    niveau: '',
    centre: '',
  });

  /**
   * LIFECYCLE
   */
  useEffect(() => {
    const initDb = async () => {
      await dbService.initDB();
      const allItems = await dbService.getAllItems();
      if (allItems.length > 0) {
        setFiltered(allItems);
        setAllCandidat(allItems);
      }
      getStatistical();
      getAllCandidate();
    };

    initDb();
  }, []);

  useEffect(() => {
    getAllNiveau();
  }, []);

  useEffect(() => {
    if (errorMessage !== '') {
      setOpenSnack(true);
    }
  }, [errorMessage]);

  useEffect(() => {
    axios.get(`/centreconcours`).then((res) => {
      setCentreConcours(res.data);
    });
  }, []);

  useEffect(() => {
    switch (filter) {
      case 'all':
        setFiltered(allCandidat);
        break;
      case 'validated':
        setFiltered(allCandidat.filter((f) => f.convocation !== null));
        break;
      default:
        setFiltered(allCandidat.filter((f) => f.convocation === null));
        break;
    }
  }, [filter]);

  useEffect(() => {
    setFilterOption('Center');
    if (centreSelected === 0 || centreSelected === -1) getCandidatePagination();
    else getListCandidateProvince();
  }, [centreSelected]);

  useEffect(() => {
    if (searchQuery !== '') {
      if (searchQuery.length < 2) return;
      let filteredTmp = allCandidat.filter((data) => {
        if (
          data.nom.toLowerCase().includes(searchQuery.toLowerCase()) ||
          data.prenoms.toLowerCase().includes(searchQuery.toLowerCase()) ||
          data?.convocation?.numero.includes(searchQuery) ||
          (data?.numCIN !== null ? data?.numCIN.includes(searchQuery) : false)
        ) {
          return data;
        }
      });
      setFiltered(filteredTmp);
    } else {
      setFiltered(allCandidat);
    }
  }, [searchQuery]);

  /**
   * FUNCTION
   */
  const getAllCandidate = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(`/candidat`);
      setFiltered(res.data);
      setAllCandidat(res.data);

      // Ajouter tous les candidats dans IndexedDB
      await dbService.clearAllItems(); // Nettoyer d'abord l'ancienne liste
      for (const candidat of res.data) {
        await dbService.addItem({ id: candidat.numInscription.toString(), ...candidat });
      }

      setIsLoading(false);
    } catch (error) {
      setErrorMessage('Erreur lors de chargement de la liste des candidats');
      setIsLoading(false);
    }
  };

  const handleFilterChange = (e) => {
    const { value } = e.target;
    setFilterOption('State');
    setFilter(value);
  };

  const handleChangeProvince = (event) => {
    setCentreSelected(event.target.value);
  };

  const modificationCandidat = (data) => {
    setFiltered(
      filtered.map((d) => {
        return d.numInscription === data.id ? { ...d, ...data } : d;
      })
    );
  };

  const handleShowNumberCandidat = () => {
    setShowNumberCandidat(!showNumberCandidat);
  };

  const handleChangeFilterNiveaux = (e) => {
    const { value } = e.target;
    setFilterCandidat((oldValue) => {
      return {
        ...oldValue,
        niveau: value,
      };
    });
  };
  const handleChangeFilterCentre = (e) => {
    const { value } = e.target;
    setFilterCandidat((oldValue) => {
      return {
        ...oldValue,
        centre: value,
      };
    });
  };

  /**
   * API
   */
  const getAllNiveau = () => {
    axios.get('/niveaux').then(
      (res) => {
        setListNiveaux(res.data);
      },
      (error) => {
        setErrorMessage('Erreur lors de chargement du niveau');
      }
    );
  };

  const getStatistical = () => {
    axios.get('/candidat/statistical/all').then(
      (res) => {
        setStatistical(res.data);
        setTotalPage(res.data?.all);
      },
      (error) => {
        setErrorMessage('Erreur lors de chargement du statistique');
      }
    );
  };

  const getCandidatePagination = (page = 1, perPage = 15) => {
    setIsLoading(true);
    axios.get(`/candidat/paginate/${page}/${perPage}`).then(
      (res) => {
        setFiltered(res.data.candidats);
        setIsLoading(false);
        setTotalPage(res.data.all);
      },
      (error) => {
        setErrorMessage('Erreur lors de chargement de la liste des candidats');
        setIsLoading(false);
      }
    );
  };

  const getListCandidateProvince = () => {
    setIsLoading(true);
    axios.get(`/candidat/findByCentre/${centreSelected}`).then(
      (res) => {
        setFiltered(res.data);
        setIsLoading(false);
      },
      (error) => {
        setErrorMessage(
          'Erreur lors de chargement de la liste des candidats de ' +
            centreConcours.filter((d) => d.codeCentre === centreSelected)[0].nomCentre
        );
        setIsLoading(false);
      }
    );
  };

  return (
    <Wrapper>
      <div className="stats">
        <div className="stat">
          <h2>Inscrits</h2>
          <div className="number">{statistical?.all}</div>
        </div>

        <div className="stat">
          <h2>Validés</h2>
          <div className="number">{statistical?.valid}</div>
        </div>

        <div className="stat">
          <h2>En attente de validation</h2>
          <div className="number">{statistical?.invalid}</div>
        </div>
      </div>

      <Button
        style={{ marginLeft: '10px', height: '54px', marginBottom: '10px' }}
        variant="contained"
        onClick={(e) => {
          e.preventDefault();
          handleShowNumberCandidat();
        }}
      >
        Voir candidats validés par centre
      </Button>

      {showNumberCandidat && (
        <div className="stats">
          {Object.entries(statistical?.candidateByCenter).map(([property, value]) => (
            <div className="stats-province" key={`${property}_p`}>
              <h2>{property}</h2>
              <div className="number">{value}</div>
            </div>
          ))}
        </div>
      )}

      <Alert severity="info">
        Pour effectuer une recherche, vous devez cliquer sur l'icône à droite du champ de saisie.
      </Alert>

      <div style={{ marginTop: 10, marginBottom: 10 }}>
        <SelectComponent
          placeholder="Afficher"
          onChange={handleFilterChange}
          options={[
            { value: 'all', label: 'Toutes les inscriptions' },
            { value: 'validated', label: 'Les inscriptions validées' },
            { value: 'not_validated', label: 'Les inscriptions non validées' },
          ]}
        />
      </div>

      <WarningSnackBar open={openSnack} message={errorMessage} close={() => setOpenSnack(false)} />
      <CandidatTable
        modificationCandidat={modificationCandidat}
        filtered={filtered}
        statistical={statistical}
        isLoading={isLoading}
        totalPage={totalPage}
      />
    </Wrapper>
  );
};

export default Candidat;

const Wrapper = styled.div`
  max-width: 1500px;
  margin: auto;
  margin-top: 20px;
  .stats {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 30px;
    padding-bottom: 30px;
    .stat {
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 15px;
      width: 32%;
      color: white;
      background-color: #8ab5df;
      &:nth-child(2) {
        background-color: #8befa5;
      }
      &:nth-child(3) {
        background-color: #ffc979;
      }
      h2 {
        font-weight: normal;
      }
      .number {
        font-size: 3em;
        font-weight: bold;
      }
    }
    .stats-province {
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 15px;
      width: 32%;
      color: white;
      background-color: #8ab5df;
      h2 {
        font-weight: normal;
      }
      .number {
        font-size: 3em;
        font-weight: bold;
      }
    }
  }
  .search {
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  @media screen and (max-width: ${styles.breakpoints.md}) {
    .stats {
      flex-direction: column;
      .stat {
        width: 100%;
      }
    }
    .search {
      flex-direction: column;
      gap: 10;
    }
  }
`;
