import { Button, Tooltip } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const TableContigenceAdjuste = ({ contigences }) => {
  const [columnHeaders, setColumnHeaders] = useState([]);
  const [contigencesAdjuste, setContigencesAdjuste] = useState();
  const [totalContigencePerChoiceAdjuste, setTotalContigencePerChoiceAdjuste] = useState();
  const [exportExcel, setExportExcel] = useState(false);
  const tableRef = useRef(null);

  /**
   * LIFECYCLE
   */
  useEffect(() => {
    if (!contigences) return;

    if (contigences && contigences?.dataArray && contigences?.dataArray.length > 0) {
      const headers = Object.keys(contigences?.dataArray[0]).filter((key) => key !== 'Province');
      setColumnHeaders(headers);
      setContigencesAdjuste(contigences?.dataArray);
    }

    if (contigences?.totalContigencePerChoice && Object.keys(contigences?.totalContigencePerChoice).length > 0) {
      setTotalContigencePerChoiceAdjuste(contigences?.totalContigencePerChoice);
    }
  }, [contigences]);

  /**
   * FUNCTION
   */
  const onChangeContigent = (e, header, provinceData) => {
    const { value } = e.target;
    const data = contigencesAdjuste.find((c) => c['Province'] === provinceData.Province);
    if (!data) return;
    data[header]['contigence'] = +value;
    const updatedContigent = contigencesAdjuste.map((c) => {
      return c['Province'] === provinceData.Province ? data : c;
    });
    totalContigencePerChoiceAdjuste[header] = 0;
    updatedContigent.forEach((element) => {
      totalContigencePerChoiceAdjuste[header] =
        (!isNaN(totalContigencePerChoiceAdjuste[header]) ? totalContigencePerChoiceAdjuste[header] : 0) +
        element[header]['contigence'];
    });
    setContigencesAdjuste(updatedContigent);
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `Contigent_ajustement`,
    sheet: 'Contigent ajustement',
  });

  return (
    <>
      <Button
        variant="contained"
        onClick={(e) => {
          e.preventDefault();
          setExportExcel(true);
          setTimeout(() => {
            onDownload();
          }, 3000);
          setTimeout(() => {
            setExportExcel(false);
          }, 3000);
        }}
        color="success"
        style={{ margin: '10px' }}
        endIcon={<FileDownloadIcon />}
      >
        Export excel
      </Button>
      <table ref={tableRef}>
        <thead>
          <tr>
            <th>Province / Parcours</th>
            {columnHeaders.map((header) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {contigencesAdjuste?.map((provinceData, index) => (
            <tr key={index}>
              <td>{provinceData.Province}</td>
              {columnHeaders.map((header, i) => (
                <td key={i}>
                  {exportExcel ? (
                    <span
                      onDoubleClick={() => {
                        setExportExcel(false);
                      }}
                    >
                      {provinceData[header]?.contigence?.toFixed(2)}
                    </span>
                  ) : (
                    <input
                      type="number"
                      defaultValue={provinceData[header]?.contigence?.toFixed(2)}
                      onKeyUp={(e) => onChangeContigent(e, header, provinceData)}
                    />
                  )}
                </td>
              ))}
            </tr>
          ))}
          <tr>
            <td>Total</td>
            {columnHeaders.map((header, i) => (
              <td key={i}>
                {totalContigencePerChoiceAdjuste ? totalContigencePerChoiceAdjuste[header].toFixed(2) : ''}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default TableContigenceAdjuste;
