import React, { useEffect, useState } from 'react';
import * as styles from '../styles/variables';
import { Outlet } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import UserAvatarMenu from '../components/Dashboard/UserAvatarMenu';
import LeftDrawer from '../components/Dashboard/LeftDrawer';
import DrawerToggler from '../components/Dashboard/DrawerToggler';
import axios from 'axios';
import { apiRoute, token } from 'components/Dashboard/GlobalVariable';
import { reactLocalStorage } from 'reactjs-localstorage';

//Configuration global axios pour injecter le token
// reactLocalStorage.setObject("utilisateur", {
//   token: token,
// });
// let utilisateur = reactLocalStorage.get("utilisateur");
// axios.defaults.headers.common["Authorization"] = `bearer ${
//   JSON.parse(utilisateur).token
// }`;

axios.defaults.baseURL = apiRoute;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const drawerWidth = styles.size.drawerWidth;

export default function AdminLayout() {
  const [drawerMobileOpen, setdrawerMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setdrawerMobileOpen(!drawerMobileOpen);
  };

  let utilisateur = reactLocalStorage.get('utilisateur');

  //no token in localstorage ===> not connected
  if (!utilisateur) {
    window.location.href = '/toor/login';
  }
  //connected
  else {
    let utilisateur = reactLocalStorage.get('utilisateur');
    axios.defaults.headers.common['Authorization'] = `bearer ${JSON.parse(utilisateur).token}`;

    /**
     * ping candidat/ping
     */
    const ping = () => {
      axios.get(`/candidat/pings/serveur`).catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            reactLocalStorage.clear();

            window.location.href = '/toor/login';
          } else if (error.response.status === 500) {
            reactLocalStorage.clear();

            window.location.href = '/toor/login';
          }
        } else if (error.request) {
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
      });
    };

    ping();

    return (
      <Box sx={{ display: 'flex' }}>
        <AppBar
          elevation={2}
          sx={{
            width: { lg: `calc(100% - ${drawerWidth}px)` },
            ml: { lg: `${drawerWidth}px` },
            background: 'white',
          }}
        >
          <Toolbar
            sx={{
              justifyContent: 'space-between',
            }}
          >
            <DrawerToggler handleDrawerToggle={handleDrawerToggle} />
            <Box
              sx={{
                mr: 0,
                ml: 'auto',
              }}
            >
              <UserAvatarMenu />
            </Box>
          </Toolbar>
        </AppBar>
        <LeftDrawer
          drawerWidth={drawerWidth}
          handleDrawerToggle={handleDrawerToggle}
          drawerMobileOpen={drawerMobileOpen}
        />

        <Box
          sx={{
            ml: { lg: `${drawerWidth}px` },
            mt: '30px',
            p: 5,
            height: '90vh',
            width: '100%',
          }}
        >
          {/* ------ MAIN CONTENT ------- */}
          <Outlet />
        </Box>
      </Box>
    );
  }
}
