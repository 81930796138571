import React, { useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import CentreConcoursTable from "components/Dashboard/CentreConcours/CentreConcoursTable";
import { Button } from "@mui/material";
import CreateDialog from "components/Dashboard/Resultat/CreateDialog";
import ResultatTable from "components/Dashboard/Resultat/ResultatTable";
import axios from "axios";


const Resultat = () => {

  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  const [data, setData] = useState([]);

  const fetchData = () => {
    axios.get('/resultat')
      .then(res => {
        setData(res.data);
      })
  }


  useEffect(() =>{
    fetchData();
  },[] );

    return (
      <>
      <div>
        <Button variant='contained' onClick={() => setOpenCreateDialog(true)}>Ajouter</Button>
        <CreateDialog 
          open={openCreateDialog}
          onClose={() => setOpenCreateDialog(false)}
          fetchData={fetchData}
        />
      </div>
        <ResultatTable data={data} fetchData={fetchData} />
      </>
    );
}

export default Resultat;
