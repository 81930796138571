import React, { useState,useEffect } from "react";
import { DataGrid } from '@mui/x-data-grid';
import CentreConcoursTable from "components/Dashboard/CentreConcours/CentreConcoursTable";
import { Button, TextField } from "@mui/material";
import CreateDialog from "components/Dashboard/Faritany/CreateDialog";
import FaritanyTable from "components/Dashboard/Faritany/FaritanyTable";
import axios from "axios";


const Faritany = () => {

  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [data, setData] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const fetchData = () => {
    axios.get('/faritany')
      .then(res => {
        setData(res.data);
        setFiltered(res.data);
      })
  }
  const handleSearch = () => {
    let filteredTmp = data.filter((data) => {
      if(data.nomFaritany.toLowerCase().includes(searchQuery.toLowerCase()) || String(data.codeFaritany).includes(searchQuery)){
        return data;
      }
    });
    setFiltered(filteredTmp);
  }

  useEffect(() => {
    fetchData();
  }, [])

    return (
      <>
      
      <div>
        <Button variant='contained' onClick={() => setOpenCreateDialog(true)}>Ajouter</Button>
        <CreateDialog 
          open={openCreateDialog}
          onClose={() => setOpenCreateDialog(false)}
          fetchData={fetchData}
        />
      </div>
      <div className="search">
        <TextField
          margin="dense"
          id="search"
          label="Recherche par Nom Centre"
          type="text"
          fullWidth
          variant="standard"
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
          onKeyUp={handleSearch}
          sx={{
            maxWidth: '500px'
          }}
        />
      </div>
        <FaritanyTable data={data} filtered={filtered} fetchData={fetchData} />
      </>
    );
}

export default Faritany;
