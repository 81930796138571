import {
  Backdrop,
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import axios from 'axios';
import React, { Component, useEffect, useState } from 'react';
import styled from 'styled-components';
import WarningSnackBar from '../Snackbar/WarningSnackBar';
import { reactLocalStorage } from 'reactjs-localstorage';
import SuccessSnackBar from '../Snackbar/SuccessSnackBar';
import EditIcon from '@mui/icons-material/Edit';
import EditNote from './EditNote';
import * as styles from 'styles/variables';
import { FileUploader } from 'react-drag-drop-files';
import SelectComponent from 'shared/designSystem/SelectComponent';

const fileTypes = ['xls', 'xlsx'];

const NoteTable = (props) => {
  const [listCandidate, setListCandidate] = useState([]);
  const [formData, setFormData] = useState([]);
  const [openSnack, setOpenSnack] = useState(false);
  const [openSnackSuccess, setOpenSnackSuccess] = useState(false);
  const [dataEpreuve, setDataEpreuve] = useState([]);
  const [matiere, setMatiere] = useState('');
  const [oldMatiere, setOldMatiere] = useState('');
  const [succesMessage, setSuccesMessage] = useState('');
  const [openUndefinedSnack, setOpenUndefinedSnack] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openModalModif, setOpenModalModif] = useState(false);
  const [candidatToEdit, setCandidatToEdit] = useState(null);

  const [offset, setOffset] = useState(20);
  const [limit, setLimit] = useState(0);
  const [nbPage, setNbPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const [open, setOpen] = React.useState(false);
  const [file, setFile] = useState(null);
  const [choix, setChoix] = useState([]);
  const [listNiveaux, setListNiveaux] = useState([]);
  const [filter, setFilter] = useState({
    niveau: '',
    parcour: '',
    choix: null,
    epreuve: null,
  });

  /**
   * FUNCTION
   */
  const handleChangeFilterNiveaux = (e) => {
    const { value } = e.target;
    const niveau = listNiveaux.find((niveau) => {
      return +niveau.id === +value;
    });
    if (!niveau) return;
    const mapValue = niveau.choix.map((c) => {
      return {
        value: c.codeChoix,
        label: c.libelleChoix,
      };
    });
    setChoix(mapValue);
  };
  const handleChangeFilterParcours = (e) => {};

  /**
   * LIFECYCLE
   */
  useEffect(() => {
    getAllNiveau();
  }, []);

  /**
   * API
   */
  const getAllNiveau = () => {
    axios.get('/niveaux').then(
      (res) => {
        setListNiveaux(res.data);
      },
      (error) => {
        console.error('error');
      }
    );
  };

  //Quand on change la valeur dans l'input
  const handleChangeFormData = (event, c) => {
    event.preventDefault();
    const { value, name } = event.target;

    if (name.indexOf('obse_') > -1) {
      const { checked } = event.target;
      setFormData(
        formData.map((data) => {
          return data.numInscription === name.split('_')[1]
            ? {
                ...data,
                Presence: checked,
                Note: checked === false ? 0 : data.Note,
              }
            : data;
        })
      );
      setListCandidate(
        listCandidate.map((data) => {
          return data.numInscription === name.split('_')[1]
            ? {
                ...data,
                Presence: checked,
                Note: checked === false ? 0 : data.Note,
              }
            : data;
        })
      );
      // listCandidate[i].Presence = checked;
    } else {
      const noteUpdated = value.slice(0, 5);
      if (+noteUpdated <= 20 && +noteUpdated >= 0) {
        setFormData(
          formData.map((data) => {
            return data.numInscription === name
              ? {
                  ...data,
                  Note: noteUpdated === '' ? '' : +noteUpdated,
                }
              : data;
          })
        );

        setListCandidate(
          listCandidate.map((data) => {
            return data.numInscription === name
              ? {
                  ...data,
                  Note: noteUpdated === '' ? '' : +noteUpdated,
                }
              : data;
          })
        );

        // listCandidate[i].Note = noteUpdated;
      } else {
        setErrorMessage('Invalid note (Le note doit comprise entre 0 à 20) ');
        setOpenUndefinedSnack(true);
      }
    }
  };

  /**
   * Liste candidat
   */
  const listeCandidat = (limit, offset) => {
    //Ouvrir loading
    // setOpen(true);
    axios.get(`/candidat/note/${limit}/${offset}`).then((res) => {
      setNbPage(Math.ceil(res.data.numberCandidats / 20));
      setFormData(
        res.data.candidats
          .filter((d) => d.convocation !== null)
          .map((data) => {
            return {
              id: data.numInscription,
              idNote: matiere,
              numInscription: data.convocation.numero,
              Note:
                data.composer.length > 0
                  ? data.composer.filter((n) => n.codeEpreuve.codeEpreuve === +matiere).length > 0
                    ? data.composer.filter((n) => n.codeEpreuve.codeEpreuve === +matiere)[0].Note
                    : ''
                  : '',
              // (Math.random() * (20 - 0) + 0).toFixed(2),
              Presence:
                data.composer.length > 0
                  ? data.composer.filter((n) => n.codeEpreuve.codeEpreuve === +matiere).length > 0
                    ? data.composer.filter((n) => n.codeEpreuve.codeEpreuve === +matiere)[0].Presence
                    : true
                  : true,
              composer: data.composer,
              edited:
                data.composer.length > 0
                  ? data.composer.filter((n) => n.codeEpreuve.codeEpreuve === +matiere).length > 0
                    ? true
                    : false
                  : false,
            };
          })
          .sort(function (a, b) {
            if (a.numInscription < b.numInscription) {
              return -1;
            } else {
              return 1;
            }
          })
          .slice(limit, offset)
      );

      setOffset(limit + 20);

      setListCandidate(
        res.data.candidats
          .filter((d) => d.convocation !== null)
          .map((data) => {
            return {
              id: data.numInscription,
              idNote: matiere,
              numInscription: data.convocation.numero,
              Note:
                data.composer.length > 0
                  ? data.composer.filter((n) => n.codeEpreuve.codeEpreuve === +matiere).length > 0
                    ? data.composer.filter((n) => n.codeEpreuve.codeEpreuve === +matiere)[0].Note
                    : ''
                  : '',
              // (Math.random() * (20 - 0) + 0).toFixed(2),
              Presence:
                data.composer.length > 0
                  ? data.composer.filter((n) => n.codeEpreuve.codeEpreuve === +matiere).length > 0
                    ? data.composer.filter((n) => n.codeEpreuve.codeEpreuve === +matiere)[0].Presence
                    : true
                  : true,
              composer: data.composer,
              edited:
                data.composer.length > 0
                  ? data.composer.filter((n) => n.codeEpreuve.codeEpreuve === +matiere).length > 0
                    ? true
                    : false
                  : false,
            };
          })
          .sort(function (a, b) {
            if (a.numInscription < b.numInscription) {
              return -1;
            } else {
              return 1;
            }
          })
      );
      // fermer loading
      setOpen(false);
    });
  };

  useEffect(() => {
    fetchDataEpreuve();
  }, []);

  useEffect(() => {
    if (matiere !== '') {
      if (oldMatiere !== matiere) {
        listeCandidat(0, 20);
        setOffset(20);
        setLimit(0);
        let pc = 1;
        setCurrentPage(pc);

        reactLocalStorage.setObject('lastPage', {
          limit: 0,
          offset: 20,
          currentPage: 1,
          nbPage: nbPage,
          matiere: matiere,
        });
      } else {
        let lastPageParams = reactLocalStorage.getObject('lastPage');
        if (JSON.stringify(lastPageParams) !== '{}') {
          setCurrentPage(lastPageParams.currentPage);
          setLimit(lastPageParams.limit);
          setOffset(lastPageParams.offset);
          setNbPage(lastPageParams.nbPage);
          setMatiere(lastPageParams.matiere);
          listeCandidat(lastPageParams.limit, lastPageParams.offset);
        } else {
          listeCandidat(0, 20);
        }
      }
    }
  }, [matiere]);

  const fetchDataEpreuve = () => {
    axios.get(`/epreuve`).then((res) => {
      setDataEpreuve(res.data);
      setMatiere(res.data[0].codeEpreuve);
      setOldMatiere(res.data[0].codeEpreuve);
    });
  };

  const handleChangeMatiere = (e) => {
    e.preventDefault();
    setMatiere(e.target.value);
    setOldMatiere(matiere);
  };

  /**
   * Sauvegarder note
   */
  const saveNote = (e) => {
    e.preventDefault();
    if (testzSiNoteVide() === false) {
      //Ouvrir loading
      setOpen(true);
      let data = formData.map((d) => {
        return {
          codeEpreuve: matiere,
          Presence: d.Presence,
          numInscription: d.id,
          Note: d.Note,
        };
      });

      axios
        .post('/composer/bulk', data)
        .then((res) => {
          if (res.data.error) {
            setErrorMessage("Erreur lors de l'ajout des notes");
            setOpenUndefinedSnack(true);
          } else {
            /**
             * Changer note local
             */

            let updateNote = [...listCandidate.slice(0, limit), ...listCandidate.slice(offset)];

            let epreuve = dataEpreuve.filter((d) => formData[0].idNote === d.codeEpreuve)[0];
            updateNote.splice(
              limit,
              0,
              ...formData.map((d) => {
                if (d.composer.length > 0) {
                  return {
                    ...d,
                    composer: d.composer.push({
                      id: d.id,
                      Presence: true,
                      Note: 12,
                      codeEpreuve: epreuve,
                    }),
                    edited: true,
                  };
                } else {
                  return {
                    ...d,
                    composer: [
                      {
                        id: d.id,
                        Presence: true,
                        Note: 12,
                        codeEpreuve: epreuve,
                      },
                    ],
                    edited: true,
                  };
                }
              })
            );

            setListCandidate(updateNote);
            /**
             * Pagination candidat
             */
            setFormData(updateNote.slice(limit + 20, offset + 20));
            setOffset(offset + 20);
            setLimit(limit + 20);

            let pc = currentPage + 1;
            setCurrentPage(pc);

            reactLocalStorage.setObject('lastPage', {
              limit: limit,
              offset: offset,
              currentPage: pc,
              nbPage: nbPage,
              matiere: matiere,
            });

            //Fermer loading
            setOpen(false);
            setSuccesMessage('Toutes les notes on bien été enregistrer');
            setOpenSnackSuccess(true);
          }
        })
        .catch((err) => {
          //Fermer loading
          setOpen(false);
          setErrorMessage("Erreur lors de l'ajout des notes");
          setOpenUndefinedSnack(true);
        });
    } else {
      //Fermer loading
      setOpen(false);
      setErrorMessage("Il y a des notes vide , veillez verifier s'il vous plaie");
      setOpenUndefinedSnack(true);
    }
  };

  /**
   * Testez s'il y un note vide
   */
  const testzSiNoteVide = () => {
    let data = formData.filter((d) => d.Note === '');
    return data.length > 0 ? true : false;
  };

  //Bloquer e
  const blockInvalidChar = (e) => {
    onKeyDown(e);
    ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
  };

  useEffect(() => {
    if (succesMessage !== '') {
      setOpenSnack(true);
    }
  }, [succesMessage]);

  useEffect(() => {
    if (errorMessage !== '') {
      setOpenUndefinedSnack(true);
    }
  }, [errorMessage]);

  /**
   * Pagination Suivant
   */
  const pageNext = () => {
    setFormData(listCandidate.slice(limit + 20, offset + 20));

    setOffset(offset + 20);
    setLimit(limit + 20);

    let pc = currentPage + 1;
    setCurrentPage(pc);

    reactLocalStorage.setObject('lastPage', {
      limit: limit + 20,
      offset: offset + 20,
      currentPage: pc,
      nbPage: nbPage,
      matiere: matiere,
    });
  };

  /**
   * Pagination Previus
   */
  const pagePrevious = () => {
    setFormData(listCandidate.slice(limit - 20, offset - 20));
    setOffset(offset - 20);
    setLimit(limit - 20);

    let pc = currentPage - 1;
    setCurrentPage(pc);

    reactLocalStorage.setObject('lastPage', {
      limit: limit - 20,
      offset: offset - 20,
      currentPage: pc,
      nbPage: nbPage,
      matiere: matiere,
    });
  };

  const handleUpdateNote = (data) => {
    setFormData(
      formData.map((d) => {
        return d.numInscription !== data.numInscription ? d : { ...d, Note: data.Note, Presence: data.Presence };
      })
    );
    setSuccesMessage(`Modification note de ${data.numInscription} avec succèes`);
    setOpenSnackSuccess(true);
    setOpenModalModif(false);
  };

  const testezNoteVide = () => {
    return formData.every((element) => element.edited === false);
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      const fields = Array.from(e.currentTarget.querySelectorAll('input')) || [];

      const position = fields.indexOf(
        e.target // as HTMLInputElement (for TypeScript)
      );
      fields[position + 1] && fields[position + 1].focus();
    }
  };

  const handleChange = (file) => {
    setFile(file);
  };

  const saveExcel = (e) => {
    e.preventDefault();
    if (file !== null) {
      setOpen(true);
      let formData = new FormData();
      formData.set('file', file);
      axios.post('/composer/uploadNote', formData).then((res) => {
        if (res.data.filename) {
          axios
            .post('/composer/addNoteUploaded', {
              filename: res.data.filename,
              matiere: matiere,
            })
            .then((res) => {
              setOpen(false);
              listeCandidat(0, 20);
              setSuccesMessage('Le fichier a bien été importer');
              setOpenSnackSuccess(true);
            });
        }
      });
    } else {
      setErrorMessage('Veillez choisir au plus un fichier excel');
      setOpenUndefinedSnack(true);
      setOpen(false);
    }
  };

  return (
    <Wrapper>
      {candidatToEdit !== null ? (
        <EditNote
          open={openModalModif}
          candidatToEdit={{
            codeEpreuve: dataEpreuve.filter((d) => d.codeEpreuve === matiere)[0],
            ...candidatToEdit,
          }}
          handleClose={(e) => {
            e.preventDefault();
            setOpenModalModif(false);
          }}
          handleUpdateNote={handleUpdateNote}
        />
      ) : (
        <></>
      )}

      <div className="header">
        {/* AFFICHER LISTE PAR NIVEAU */}
        <SelectComponent
          placeholder="Afficher par niveau"
          onChange={handleChangeFilterNiveaux}
          options={[
            { value: '', label: '-' },
            ...listNiveaux.map((niveau) => ({
              value: niveau.id,
              label: niveau.nom,
            })),
          ]}
          className="select-filter"
        />
        <SelectComponent
          placeholder="Afficher"
          onChange={handleChangeFilterParcours}
          options={[{ value: '', label: '-' }, ...choix]}
          className="select-filter"
        />

        <SelectComponent
          placeholder="Matiere"
          onChange={handleChangeMatiere}
          options={[
            { value: '', label: '-' },
            ...dataEpreuve.map((epreuve) => ({
              value: epreuve.codeEpreuve,
              label: epreuve.libelleEpreuve,
            })),
          ]}
          className="select-filter"
        />
        {/* <div className="import-file">
          <div className="input">
            <FileUploader handleChange={handleChange} name="file" types={fileTypes} label="Importer note" />
            <Button onClick={saveExcel} style={{ marginLeft: '10px' }} variant="contained" color="success">
              Importer
            </Button>
          </div>
        </div> */}
        <div>
          Page {currentPage} / <b>{nbPage}</b>
        </div>
      </div>

      <div className="table">
        <table>
          <thead>
            <tr>
              <th>Numéro</th>
              <th>Note</th>
              <th>Absence</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {formData.map((c, i) => (
              <tr key={i}>
                <td>
                  <b>
                    {c.numInscription.slice(0, 2)} {c.numInscription.slice(2)}
                  </b>
                </td>
                <td>
                  <div className="note">
                    <input
                      sx={{ width: '100%' }}
                      margin="dense"
                      type="number"
                      required
                      value={formData[i].Note}
                      name={`${c.numInscription}`}
                      onChange={(e) => {
                        handleChangeFormData(e, c);
                      }}
                      placeholder={`Saissisez ici la note de ${c.numInscription.slice(0, 2)} ${c.numInscription.slice(
                        2
                      )} (Note /20)`}
                      onKeyDown={blockInvalidChar}
                      disabled={c.edited}
                    />
                  </div>
                </td>
                <td>
                  <Checkbox
                    disabled={c.edited}
                    checked={formData[i].Presence}
                    name={`obse_${c.numInscription}`}
                    onChange={handleChangeFormData}
                  />
                </td>
                <td>
                  {c.edited ? (
                    <Tooltip title="Edition note" placement="bottom">
                      <IconButton
                        onClick={() => {
                          setCandidatToEdit(formData[i]);
                          setOpenModalModif(true);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="btn-pagination">
          <ButtonGroup variant="contained" aria-label="outlined primary button group">
            <Button
              onClick={(e) => {
                e.preventDefault();
                pagePrevious();
              }}
              disabled={currentPage === 1}
            >
              page précédente
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                pageNext();
              }}
              disabled={currentPage > nbPage}
            >
              page suivante
            </Button>
          </ButtonGroup>
        </div>

        {testezNoteVide() && (
          <div className="bouton-valider">
            <div></div>
            <Button variant="contained" onClick={saveNote} color="success" disabled={currentPage > nbPage}>
              {currentPage > nbPage ? 'Dernière page' : 'Enregistrer et suivant'}
            </Button>
          </div>
        )}
      </div>

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <WarningSnackBar open={openUndefinedSnack} message={errorMessage} close={() => setOpenUndefinedSnack(false)} />
      <SuccessSnackBar open={openSnackSuccess} message={succesMessage} close={() => setOpenSnackSuccess(false)} />
    </Wrapper>
  );
};

export default NoteTable;

const Wrapper = styled.div`
  max-width: 1000px;
  margin: auto;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 30px;
  }
  padding: 10px;
  /* table,
td,
th {
  border: 1px solid black;
}

.table {
  text-align: center;
}

table {
  border-collapse: collapse;
}

td {
  text-align: center;
  height: 30px;
  width: 33%;
  padding: 5px;
}

th {
  height: 50px;
} */
  .select-matiere {
    min-width: 20%;
    margin-bottom: 10px;
  }
  .bouton-valider {
    margin: 10px;
    display: flex;
    justify-content: space-between;
  }
  table,
  td,
  th {
    border: 1px solid #bababa;
    text-align: center;
  }
  table {
    border-collapse: collapse;
    width: 100%;
    th {
      height: 45px;
      background-color: ${styles.colors.drawerBg};
      color: white;
    }
    tr {
      &:hover,
      &:nth-child(even) {
        background-color: #f2f2f2;
        /* background-color: white; */
      }
    }
    .note {
      height: 100%;
      display: flex;
      justify-content: space-between;

      input {
        width: 100%;
        height: 45px;
        border: none;
        padding-left: 10px;
        font-size: 16px;
        font-weight: bold;
        color: black;
        background-color: transparent;
      }
    }
  }
  .btn-pagination {
    margin-top: 30px;
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
  }
`;
