import React from 'react';
import * as styles from 'styles/variables';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';


export default function ValidationIcon({ isValid = null, ...otherProps }) {
    const config = {
        sx: {
            color: isValid ? styles.colors.success : styles.colors.error
        },
        ...otherProps
    };
    if (isValid != null) {
        return isValid ? <CheckCircleRoundedIcon {...config} /> : <CancelRoundedIcon {...config} />;
    }

    return "";
}