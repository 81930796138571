import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { apiRoute } from '../GlobalVariable';
import SuccessSnackBar from '../Snackbar/SuccessSnackBar';

export default function EditDialog(props) {
  const [openSnack, setOpenSnack] = useState(false);
  const [formData, setFormData] = useState({
    nomCentreBacc: ' ',
    codeFaritany: ' ',
  });

  //Quand on change la valeur dans l'inout
  const handleChangeFormData = (event) => {
    const { value, name, type } = event.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: type === 'number' ? parseFloat(value) : value,
      };
    });
  };

  //Sauvegarder les données modifiées
  const handleSubmit = (e) => {
    e.preventDefault();
    axios.put(`/centrebacc/${props.codeCentreBacc}`, formData).then((res) => {
      setOpenSnack(true);
      setTimeout(() => {
        props.onClose();
      }, 2000);
    });
  };

  useEffect(() => {
    axios
      .get(`/centrebacc/${props.codeCentreBacc}`)
      .then((res) => {
        setFormData({
          nomCentreBacc: res.data.nomCentreBacc,
          codeFaritany: res.data.codeFaritany,
        });
      })
      .catch((err) => {});
  }, []);

  return (
    <div>
      <Dialog open={props.open} onClose={props.onClose}>
        <form onSubmit={(e) => handleSubmit(e)}>
          <DialogTitle>Modifier centre</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="nomCentre"
              label="Nom Centre"
              type="text"
              fullWidth
              variant="standard"
              required
              name="nomCentreBacc"
              value={formData.nomCentreBacc}
              onChange={handleChangeFormData}
            />
            <TextField
              margin="dense"
              id="codeFaritany"
              label="Faritany"
              type="text"
              name="codeFaritany"
              fullWidth
              variant="standard"
              required
              value={formData.codeFaritany}
              onChange={handleChangeFormData}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onClose}>Annuler</Button>
            <Button type="submit" color="primary" variant="contained">
              Modifier
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <SuccessSnackBar open={openSnack} message="Centre bacc modifié avec succès!" close={() => setOpenSnack(false)} />
    </div>
  );
}
