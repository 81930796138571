import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { apiRoute, token } from '../GlobalVariable';
import SuccessSnackBar from '../Snackbar/SuccessSnackBar';

const EditDialog = (props) => {
  const [libelleSerie, setLibelleSerie] = useState('');
  const [openSnack, setOpenSnack] = useState(false);

  useEffect(() => {
    axios.get(`/series/${props.codeSerie}`).then((res) => {
      setLibelleSerie(res.data.libelleSerie);
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .put(`/series/${props.codeSerie}`, {
        libelleSerie: libelleSerie,
      })
      .then((res) => {
        setOpenSnack(true);
        setTimeout(() => {
          props.onClose();
        }, 2000);
      })
      .catch((err) => {});
  };

  return (
    <div>
      <Dialog open={props.open} onClose={props.onClose}>
        <form onSubmit={(e) => handleSubmit(e)}>
          <DialogTitle>Modifier Type Candidat</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="libelleTypeCand"
              label="Libellé série"
              type="text"
              fullWidth
              variant="standard"
              value={libelleSerie}
              onChange={(e) => setLibelleSerie(e.target.value)}
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onClose}>Annuler</Button>
            <Button type="submit" color="primary" variant="contained">
              Modifier
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <SuccessSnackBar open={openSnack} message="Série modifiée avec succès!" close={() => setOpenSnack(false)} />
    </div>
  );
};

export default EditDialog;
