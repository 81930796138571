import { Backdrop, CircularProgress } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectComponent from 'shared/designSystem/SelectComponent';
import { niveauSelectors } from 'store/niveau';
import { setSelectedNiveau } from 'store/niveau/SliceNiveau';
import styled from 'styled-components';
import TableGestionNote from './TableGestionNote';

const GenerateResultat = () => {
  /**
   * REDUX
   */
  const dispatch = useDispatch();
  const niveaux = useSelector(niveauSelectors.selectNiveaux);

  /**
   * VARIABLE
   */
  const [open, setOpen] = useState(false);
  const [listNiveaux, setListNiveaux] = useState([]);
  const [parcours, setParcours] = useState([]);
  const [filter, setFilter] = useState({
    niveau: '',
    parcour: '',
    epreuve: '',
  });

  /**
   * LIFECYCLE
   */

  useEffect(() => {
    getAllNiveau();
  }, []);

  useEffect(() => {
    if (!filter) return;
    const niveau = listNiveaux.find((niveau) => {
      return niveau.id === filter.niveau;
    });

    if (!niveau) return;
    const { parcours } = niveau;
    const parcoursValue = parcours.map((p) => {
      return {
        value: p.id,
        label: p.name,
      };
    });
    setParcours(parcoursValue);
  }, [filter]);
  /**
   * FUNCTION
   */
  const handleChangeFilterNiveaux = (e) => {
    const { value } = e.target;
    setFilter((oldValue) => {
      return {
        ...oldValue,
        niveau: +value,
      };
    });
  };

  const handleChangeParcours = (e) => {
    const { value } = e.target;
    setFilter((oldValue) => {
      return {
        ...oldValue,
        parcour: +value,
      };
    });
  };

  /**
   * API
   */
  const getAllNiveau = () => {
    setOpen(true);
    axios.get('/niveaux').then(
      (res) => {
        setListNiveaux(res.data);
        dispatch(setSelectedNiveau(res.data));
        setOpen(false);
      },
      (error) => {
        setOpen(false);
        console.error('error');
      }
    );
  };

  return (
    <Wrapper>
      <div className="container">
        <div className="select-filters">
          {/* AFFICHER LISTE PAR NIVEAU */}
          <SelectComponent
            onChange={handleChangeFilterNiveaux}
            placeholder="Afficher par niveau"
            options={[
              { value: '', label: 'Selectioner niveau' },
              ...listNiveaux.map((niveau) => ({
                value: niveau.id,
                label: niveau.nom,
              })),
            ]}
            className="select-filter"
            defaultValue={filter.niveau}
          />

          <SelectComponent
            onChange={handleChangeParcours}
            placeholder="Afficher"
            options={[{ value: '', label: 'Selectioner parcours' }, ...parcours]}
            className="select-filter"
            defaultValue={filter.parcour}
          />
        </div>

        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <TableGestionNote filter={filter} setOpen={setOpen} />
      </div>
    </Wrapper>
  );
};

export default GenerateResultat;

const Wrapper = styled.div`
  .container {
    padding: 20px;

    /* Disposition des SelectComponents */
    .select-filters {
      display: flex;
      flex-wrap: wrap; /* Pour gérer les retours à la ligne */
      gap: 15px; /* Espacement entre les selects */
      justify-content: space-between; /* Espace entre les selects sur grand écran */

      /* Adaptation responsive */
      @media (max-width: 768px) {
        flex-direction: column; /* Disposition en colonne sur les petits écrans */
        gap: 10px;
      }
    }

    /* Styliser les sélecteurs */
    .select-filter {
      width: 100%;
      max-width: 300px; /* Limite la largeur des selects sur grand écran */
      border: 1px solid #ccc;
      border-radius: 4px;
      background-color: #fff;
      font-size: 16px;
      cursor: pointer;
      outline: none;

      &:focus {
        border-color: #007bff; /* Couleur de bordure lors de la focalisation */
      }

      @media (max-width: 768px) {
        max-width: 100%; /* S'assure que les selects prennent toute la largeur sur petit écran */
      }
    }

    /* Style pour le tableau */
    table {
      font-family: Arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
      margin-top: 20px;
    }

    td,
    th {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }

    tr:nth-child(even) {
      background-color: #f9f9f9;
    }
  }
`;
