import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import EditDialog from '../Droit/EditDialog';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import WarningSnackBar from '../Snackbar/WarningSnackBar';




const DroitTable = (props) => {

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [id, setId] = useState(null);

  const [openSnack, setOpenSnack] = useState(false);


  const handleOpenEditDialog = (idDroit) => {
    setId(idDroit);
    setOpenEditDialog(true);
  };
  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setId(null);
    props.fetchData();
  };

  const handleDelete = (id) =>{
    if(window.confirm('Voulez-vous vraiment supprimer ce droit?')){
      axios.delete(`/droit/${id}`)
        .then(res => {
          setOpenSnack(true);
          props.fetchData();
        })
    }
  } 

  const columns = [
    {field: 'id', headerName:'ID', flex: 0.5},
    {field: 'droitConcours', headerName: 'Droit concours', flex: 1},
    {field:'fraisScolaire', headerName: 'Frais scolaire', flex: 1},
    {
      field: 'libelleTypeCand', 
      headerName: 'Type candidat',
      valueGetter: (params) => {
        return params.getValue(params.id, "typeCandidat").libelleTypeCand;
      },
      flex: 1
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: (params) => (
        <>
          <IconButton title='Modifier' sx={{color: 'blue'}} onClick={() => handleOpenEditDialog(params.id)}>
            <EditRoundedIcon />
          </IconButton>
          <IconButton title='Supprimer' sx={{color: 'red'}} onClick={() => handleDelete(params.id)}>
            <DeleteRoundedIcon />
          </IconButton>
        </>
      )
    }
  ]

  return (
    <>
    {
      id !=null ? <EditDialog open={openEditDialog} id={id} onClose={handleCloseEditDialog} /> : ''
    }

    
    {
      props.data && (
        <DataGrid
          autoHeight
          getRowId={(row) => row.id}
          rows={props.data}
          columns={columns}
        />
      )
    }
    <WarningSnackBar
        message="Droit supprimé"
        open={openSnack}
        close={() => setOpenSnack(false)}
      />
    </>
  );
}

export default DroitTable;