import axios from 'axios';
import { apiRoute } from 'components/Dashboard/GlobalVariable';
import { createContext, useEffect, useState } from 'react';
import * as Yup from 'yup';

axios.defaults.baseURL = apiRoute;

const PANELS = {
  AVIS: 'avis',
  INFOSPERSOS: 'infos-persos',
  BACC: 'bacc',
  LICENCE: 'licence',
  INSCRIPTION: 'inscription',
  PIECESJOINTES: 'pieces-jointes',
};

export const FormContext = createContext();

export const ValidationProvider = (props) => {
  // Définir INITIAL_FORM_STATE
  const [INITIAL_FORM_STATE, SET_INITIAL_FORM_STATE] = useState({
    nom: '',
    prenoms: '',
    dateNaissInconnue: false,
    dateNaiss: '',
    dateNaissExacte: '',
    lieuNaissance: '',
    telephone: '',
    mel: '',
    situationMatrimoniale: 'C',
    nomPere: '',
    nomMere: '',
    adresseParent: '',
    numCIN: '',
    dateCIN: '',
    lieuCIN: '',
    dateDuplicata: '',
    lieuDuplicata: '',
    codeSerie: 1,
    numBacc: '',
    anneeBacc: '2024',
    codeCentreBacc: '1',
    mention: 'passable',
    premierChoix: '',
    deuxiemeChoix: '',
    codeCentre: '',
    codeTypeCand: '1',
    numBord: '',
    agenceBord: '',
    dateBord: '',

    //LICENCE
    etablissementLicence: '',
    anneeLicence: '',
    mentionLicence: '',
  });

  const [formFieldsError, setFormFieldsError] = useState(() => {
    const tmp = {};
    for (const key in INITIAL_FORM_STATE) {
      tmp[key] = false; // Initialiser tous les champs à false (pas d'erreur par défaut)
    }
    return tmp;
  });

  const [expanded, setExpanded] = useState(PANELS.AVIS);
  const [attachmentsForm, setAttachmentsForm] = useState([]);
  const [isSectionValid, setIsSectionValid] = useState({
    avis: true,
    infosPersos: false,
    bacc: false,
    licence: false,
    inscription: false,
    piecesJointes: false,
  });

  const [FORM_VALIDATION, SET_FORM_VALIDATION] = useState(Yup.object().shape({}));
  const [isMaster, setIsMaster] = useState(false);

  useEffect(() => {
    const newDataInitialised = {};

    const buildValidationSchema = () => {
      const baseSchema = {
        nom: Yup.string().required('Champs requis'),
        prenoms: Yup.string(),
        dateNaissInconnue: Yup.boolean(),
        dateNaissExacte: Yup.date().when('dateNaissInconnue', {
          is: false,
          then: Yup.date().required('Champs requis'),
          otherwise: Yup.date(),
        }),
        lieuNaissance: Yup.string().required('Champs requis'),
        telephone: Yup.string()
          .required('Champs requis')
          .test('start', 'Le numéro doit commnencer par 0', (val) => val && val[0] === '0'),
        mel: Yup.string().required('Champs requis').email('Adresse invalide'),
        nomPere: Yup.string(),
        nomMere: Yup.string().required('Champs requis'),
        adresseParent: Yup.string().required('Champs requis'),
        numCIN: Yup.string(),
        dateCIN: Yup.date().when('numCIN', (numCIN, schema) => {
          return numCIN ? schema.required('Champs requis') : schema;
        }),
        lieuCIN: Yup.string().when('numCIN', (numCIN, schema) => {
          return numCIN ? schema.required('Champs requis') : schema;
        }),
        dateDuplicata: Yup.date(),
        lieuDuplicata: Yup.string().test('is-required', 'Champs requis', function (value) {
          return this.parent.numCIN && this.parent.dateDuplicata && !value ? false : true;
        }),
        codeSerie: Yup.string().required('Champs requis'),
        numBacc: Yup.string()
          .required('Champs requis')
          .min(6, 'le numéro doit etre composé de 6 ou 7 chiffres')
          .max(7, 'le numéro doit etre composé de 6 ou 7 chiffres'),
        anneeBacc: Yup.number()
          .required('Champs requis')
          .test('len', 'année invalide', (val) => val && val.toString().length === 4)
          .max(new Date().getFullYear(), 'Année invalide'),
        codeCentreBacc: Yup.string().required('Champs requis'),
        mention: Yup.string().required('Champs requis'),
        premierChoix: Yup.string().required('Champs requis'),
        deuxiemeChoix: Yup.string().nullable(),
        codeCentre: Yup.string().required('Champs requis'),
        codeTypeCand: Yup.string().required('Champs requis'),
        numBord: Yup.string().required('Champs requis'),
        agenceBord: Yup.string().required('Champs requis'),
        dateBord: Yup.date().required('Champs requis'),
      };

      if (isMaster) {
        baseSchema.etablissementLicence = Yup.string().required('Champs requis');
        baseSchema.anneeLicence = Yup.string().required('Champs requis');
        baseSchema.mentionLicence = Yup.string().required('Champs requis');
      } else {
        baseSchema.etablissementLicence = Yup.string().nullable();
        baseSchema.anneeLicence = Yup.string().nullable();
        baseSchema.mentionLicence = Yup.string().nullable();
      }

      if (attachmentsForm.length > 0) {
        attachmentsForm.forEach((attachment) => {
          newDataInitialised[attachment.name_code] = '';
          baseSchema[attachment.name_code] = attachment.required
            ? Yup.string().required('Champs requis')
            : Yup.string().nullable();
        });
      }
      return Yup.object().shape(baseSchema);
    };

    // Mettre à jour formFieldsError pour les nouveaux champs ajoutés dynamiquement
    setFormFieldsError((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      attachmentsForm.forEach((attachment) => {
        updatedErrors[attachment.name_code] = true; // Considérer les pièces jointes comme non validées par défaut
      });
      return updatedErrors;
    });
    SET_INITIAL_FORM_STATE((oldValue) => {
      return { ...oldValue, ...newDataInitialised };
    });
    SET_FORM_VALIDATION(buildValidationSchema());
  }, [attachmentsForm, isMaster]);

  useEffect(() => {
    if (INITIAL_FORM_STATE) {
      setFormFieldsError(() => {
        const tmp = {};
        for (const key in INITIAL_FORM_STATE) {
          tmp[key] = false; // Initialiser tous les champs à false (pas d'erreur par défaut)
        }
        return tmp;
      });
    }
  }, [INITIAL_FORM_STATE]);

  return (
    <FormContext.Provider
      value={{
        FORM_VALIDATION,
        SET_FORM_VALIDATION,
        INITIAL_FORM_STATE,
        PANELS,
        expanded,
        setExpanded,
        formFieldsError,
        setFormFieldsError,
        isSectionValid,
        setIsSectionValid,
        setAttachmentsForm,
        setIsMaster,
      }}
    >
      {props.children}
    </FormContext.Provider>
  );
};
