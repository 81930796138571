import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';
import Pagination from '../AjoutNote/component/Pagination';

const TableGestionNote = ({ filter, setOpen }) => {
  // État pour la pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [candidats, setCandidats] = useState([]);
  const itemsPerPage = 10; // Nombre d'éléments à afficher par page
  const [filters, setFilters] = useState(null); // Initialisation de l'état des filtres
  const [openRows, setOpenRows] = useState({}); // Etat pour savoir quelles lignes sont ouvertes
  const totalPages = Math.ceil(candidats.length / itemsPerPage);

  /**
   * LIFECYCLE
   */
  useEffect(() => {
    // S'assurer que `filter` est bien défini avant de le mettre dans `filters`
    if (filter.parcour !== '' && filter.niveau !== '') {
      setFilters(filter); // Mettre à jour `filters` avec le nouveau `filter`
      setCurrentPage(1); // Réinitialiser à la première page lors du changement de filtre
      getCandidat(filter); // Passer le nouveau filtre à la fonction `getCandidat`
    }
  }, [filter]);

  // Fonction pour aller à la page précédente
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Fonction pour aller à la page suivante
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleFirstPage = () => {
    setCurrentPage(1); // Aller à la première page
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages); // Aller à la dernière page
  };

  // Fonction pour gérer l'ouverture et fermeture des détails
  const toggleRow = (id) => {
    setOpenRows((prev) => ({
      ...prev,
      [id]: !prev[id], // Inverser l'état ouvert/fermé de la ligne
    }));
  };

  /**
   * API
   */
  const getCandidat = (currentFilters) => {
    setOpen(true);
    axios
      .post(`/candidat/filter/average`, currentFilters) // Utiliser le `currentFilters` passé
      .then((res) => {
        setCandidats(res.data.data); // Charger tous les candidats
        setOpen(false);
      })
      .catch((error) => {
        setOpen(false);
        console.error('Erreur lors de la récupération des candidats:', error);
      });
  };

  const handleAddNote = (value, candidat, currentFilters) => {
    axios
      .post(`/composer`, {
        niveauId: currentFilters.niveau,
        note: value,
        numInscription: candidat.id,
        parcoursId: currentFilters.parcour,
        presence: true,
      })
      .then((res) => {
        const { composer, numInscription } = res.data;
        // Mettre à jour la note du candidat dans l'état `candidats`
        setCandidats((prevCandidats) =>
          prevCandidats.map((cand) => (cand.id === numInscription ? { ...cand, note: composer.note } : cand))
        );
      })
      .catch((error) => {
        toast.error("Erreur lors de l'ajout de la note:", error);
      });
  };

  // Calculer les éléments de la page actuelle
  const currentCandidats = candidats.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <TableWrapper>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        handlePreviousPage={handlePreviousPage}
        handleNextPage={handleNextPage}
        handleFirstPage={handleFirstPage}
        handleLastPage={handleLastPage}
      />
      <StyledTable>
        <thead>
          <tr>
            <th>Numéro</th>
            <th>Moyenne</th>
            <th>Détails</th>
            <th>Absence</th>
          </tr>
        </thead>
        <tbody>
          {currentCandidats.map((c, i) => (
            <React.Fragment key={`${c.id}-${currentPage}`}>
              <tr>
                <td>
                  <b>{c.convocation.numero}</b>
                </td>
                <td>{c.moyenne}</td>
                <td>
                  <ChevronButton onClick={() => toggleRow(c.id)}>
                    {openRows[c.id] ? '▲' : '▼'} {/* Utilisation d'entités HTML pour les chevrons */}
                  </ChevronButton>
                </td>
                <td>{/* Ajoutez ici le gestionnaire pour l'absence si nécessaire */}</td>
              </tr>
              {openRows[c.id] && (
                <tr>
                  <td colSpan={4}>
                    <DetailsWrapper>
                      {c?.composer?.map((compose, index) => (
                        <NoteWrapper key={index}>
                          <NoteDisplay>
                            <EpreuveTitle>{compose?.codeEpreuve?.libelleEpreuve}</EpreuveTitle>
                            <NoteValue>Note: {compose.note}</NoteValue>
                            <CoefValue>Coefficient: {compose?.codeEpreuve?.coef}</CoefValue>
                          </NoteDisplay>
                        </NoteWrapper>
                      ))}
                    </DetailsWrapper>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </StyledTable>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        handlePreviousPage={handlePreviousPage}
        handleNextPage={handleNextPage}
        handleFirstPage={handleFirstPage}
        handleLastPage={handleLastPage}
      />
    </TableWrapper>
  );
};

export default TableGestionNote;
const TableWrapper = styled.div`
  margin: 20px;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;

  th,
  td {
    border: 1px solid #ddd;
    text-align: left;
    padding: 10px;
  }

  th {
    background-color: #f4f4f4;
    color: #000;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  tr:hover {
    background-color: #f1f1f1;
  }

  td {
    font-size: 16px;
    color: #000;
  }
`;

const ChevronButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #000; /* Couleur du chevron */
  &:hover {
    color: #007bff; /* Changer la couleur au survol */
  }
`;

// Styles pour l'affichage des détails des notes et coefficients
const DetailsWrapper = styled.div`
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 8px;
`;

const NoteWrapper = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
`;

const NoteDisplay = styled.div`
  display: flex;
  flex-direction: column;
  color: #000;
`;

const EpreuveTitle = styled.h4`
  font-size: 16px;
  margin: 0;
  font-weight: bold;
`;

const NoteValue = styled.span`
  font-size: 14px;
`;

const CoefValue = styled.span`
  font-size: 14px;
  color: #555;
`;
