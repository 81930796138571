import React from 'react'
import styled from 'styled-components';
import * as styles from 'styles/variables';
import { Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#2F80ED',
            dark: '#1f65c0'
        },
        secondary: {
            main: '#F5F5F8',
            dark: '#dadada'
        }
    },
});

export default function FormButton({ children, variant = 'contained', size = 'large', ...otherProps }) {
    const config = {
        sx: {
            borderRadius: '10px',
            fontWeight: '600',
            boxShadow: 'none',
            textTransform: 'capitalize',
            fontFamily: 'Montserrat',
            minWidth: '100px'
        },
        variant: variant,
        size: size,
        ...otherProps
    }
    return (
        <ThemeProvider theme={theme}>
            <Button {...config} disableElevation>
                {children}
            </Button>
        </ThemeProvider>
    );
}

const t = styled.div`
    text-transform: capitalize;
`