import CategoryRoundedIcon from '@mui/icons-material/CategoryRounded';
import ForkLeftRoundedIcon from '@mui/icons-material/ForkLeftRounded';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import ImportContactsRoundedIcon from '@mui/icons-material/ImportContactsRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import LabelImportantRoundedIcon from '@mui/icons-material/LabelImportantRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import PaidRoundedIcon from '@mui/icons-material/PaidRounded';
import PowerSettingsNewRoundedIcon from '@mui/icons-material/PowerSettingsNewRounded';
import WorkspacePremiumRoundedIcon from '@mui/icons-material/WorkspacePremiumRounded';
import { Divider, Drawer, List, ListItem, Toolbar } from '@mui/material';
import logoENI from 'assets/img/eni-logo.png';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';
import styled from 'styled-components';
import * as styles from '../../styles/variables';

export default function LeftDrawer(props) {
  const { window } = props;

  const navigate = useNavigate();

  const handleLogout = (e) => {
    e.preventDefault();
    reactLocalStorage.clear();
    navigate('/toor/login');
  };

  const drawer = (
    <DrawerContent>
      <div className="top">
        <Toolbar
          sx={{
            height: 110,
          }}
          className="toolbar"
        >
          <img src={logoENI} alt="logo ENI" />
          <h3>
            ENI <br />
            Concours
          </h3>
        </Toolbar>
        <Divider
          sx={{
            borderColor: 'rgb(45, 55, 72)',
            mb: 7,
          }}
        />

        <List
          sx={{
            pl: 2,
            pr: 2,
          }}
        >
          <strong className="subheader">CANDIDAT</strong>
          <ListItem button className="list-item">
            <NavLink to="/toor/candidat">
              <GroupRoundedIcon />
              Candidats inscrits
            </NavLink>
          </ListItem>
          <ListItem button className="list-item">
            <NavLink to="/toor/statistic">
              <GroupRoundedIcon />
              Statistique
            </NavLink>
          </ListItem>
          <ListItem button className="list-item">
            <NavLink to="/toor/inscription">
              <GroupRoundedIcon />
              Formulaire d'inscription
            </NavLink>
          </ListItem>
          <strong className="subheader">PARAMETRES CONCOURS</strong>
          <ListItem button className="list-item">
            <NavLink to="/toor/parametre">
              <InfoRoundedIcon />
              Infos générales
            </NavLink>
          </ListItem>
          <ListItem button className="list-item">
            <NavLink to="/toor/typeCandidat">
              <CategoryRoundedIcon />
              Types de candidat
            </NavLink>
          </ListItem>
          <ListItem button className="list-item">
            <NavLink to="/toor/droit">
              <PaidRoundedIcon />
              Droit
            </NavLink>
          </ListItem>
          <ListItem button className="list-item">
            <NavLink to="/toor/choix">
              <ForkLeftRoundedIcon />
              Parcours à choisir
            </NavLink>
          </ListItem>
          <ListItem button className="list-item">
            <NavLink to="/toor/epreuve">
              <ImportContactsRoundedIcon />
              Epreuves
            </NavLink>
          </ListItem>
          <ListItem button className="list-item">
            <NavLink to="/toor/centreConcours">
              <LocationOnRoundedIcon />
              Centres
            </NavLink>
          </ListItem>
          <strong className="subheader">PARAMETRES BACC</strong>
          <ListItem button className="list-item">
            <NavLink to="/toor/serie">
              <LabelImportantRoundedIcon />
              Séries
            </NavLink>
          </ListItem>
          <ListItem button className="list-item">
            <NavLink to="/toor/centreBacc">
              <LocationOnRoundedIcon />
              Centres
            </NavLink>
          </ListItem>
          <strong className="subheader">AUTRES PARAMETRES</strong>
          <ListItem button className="list-item">
            <NavLink to="/toor/resultat">
              <WorkspacePremiumRoundedIcon />
              Types de résultat
            </NavLink>
          </ListItem>
          <ListItem button className="list-item">
            <NavLink to="/toor/faritany">
              <LocationOnRoundedIcon />
              Faritany
            </NavLink>
          </ListItem>
        </List>
      </div>
      <div className="bottom">
        <Divider
          sx={{
            borderColor: 'rgb(45, 55, 72)',
            mb: 2,
          }}
        />
        <List
          sx={{
            pl: 2,
            pr: 2,
          }}
        >
          <ListItem button className="list-item">
            <Link to="/" onClick={handleLogout}>
              <PowerSettingsNewRoundedIcon />
              Se déconnecter
            </Link>
          </ListItem>
        </List>
      </div>
    </DrawerContent>
  );
  const container = window !== undefined ? () => window().document.body : undefined;
  const drawerWidth = props.drawerWidth;
  return (
    <div>
      <Drawer
        container={container}
        variant="temporary"
        open={props.drawerMobileOpen}
        onClose={props.handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', lg: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            background: styles.colors.drawerBg,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', lg: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            background: styles.colors.drawerBg,
            color: styles.colors.drawerColor,
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </div>
  );
}

const DrawerContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .toolbar {
    color: white;
    img {
      width: 70px;
      margin-right: 20px;
    }
  }
  .subheader {
    display: block;
    color: #6b7280;
    font-size: 12px;
    margin-left: 32px;
    margin-top: 20px;
  }
  .list-item {
    border-radius: ${styles.size.borderRadius};
    height: 42px;
    padding: 0;
    margin-bottom: 4px;
    overflow: hidden;
    a {
      color: ${styles.colors.drawerColor};
      text-decoration: none;
      font-weight: bold;
      display: flex;
      height: 100%;
      width: 100%;
      align-items: center;
      font-size: 14px;
      padding: 9px 24px;
      transition: all 0.25s;
      letter-spacing: 1px;
      svg {
        width: 20px;
        margin-right: 10px;
      }
      &:hover,
      &.active {
        background-color: rgba(255, 255, 255, 0.08);
      }
      &.active {
        color: ${styles.colors.green};
      }
    }
  }
  .bottom {
    a:hover {
      background-color: #fd14146b;
    }
    small {
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 7px;
      .heart {
        color: red;
        width: 14px;
        margin-left: 7px;
        margin-right: 7px;
      }
    }
  }
`;
