import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import SuccessSnackBar from "../Snackbar/SuccessSnackBar";
import styled from "styled-components";

const CreateDialog = (props) => {
  const [openSnack, setOpenSnack] = useState(false);
  const [formData, setFormData] = useState({
    anneeUniv: "",
    dateDebutConcours: "",
    dateFermetureInscription: "",
    dateFinConcours: "",
    dateOuvertureInscription: "",
    numCpteENI: "",
  });


  //Quand on change la valeur dans l'inout
  const handleChangeFormData = (event) => {
    const { value, name, type } = event.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: type === "number" ? parseFloat(value) : value,
      };
    });
  };


  //Sauvearder paramètre
  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post(`/parametres`, formData).then((res) => {
      props.handleChangeDataParametre(res.data.data, 'new');
      setOpenSnack(true);
    });
  };

  return (
    <div>
      <Dialog open={props.open} onClose={props.onClose} fullWidth={true}>
        <Form onSubmit={(e)=>handleSubmit(e)}>
          <DialogTitle>Ajouter un nouveau paramètre</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="anneeUniv"
              label="Année Universitaire"
              type="text"
              name="anneeUniv"
              fullWidth
              required
              value={formData.anneeUniv}
              onChange={handleChangeFormData}
            />

            <h3>Période d'inscription</h3>
              <TextField
                id="ouverture"
                label="Date ouverture inscription"
                type="date"
                fullWidth
                margin="dense"
                name="dateOuvertureInscription"
                required
                InputLabelProps={{
                  shrink: true,
                }}
                value={formData.dateOuvertureInscription}
              onChange={handleChangeFormData}
              />
              <TextField
                margin="dense"
                id="fermeture"
                label="Date fermeture inscription"
                type="date"
                fullWidth
                required
                name="dateFermetureInscription"
                InputLabelProps={{
                  shrink: true,
                }}
                value={formData.dateFermetureInscription}
                onChange={handleChangeFormData}
              />

              <h3>Dates concours</h3>
              <TextField
                id="dateDebutConcours"
                label="Date début concours"
                type="date"
                fullWidth
                margin="dense"
                required
                name="dateDebutConcours"
                InputLabelProps={{
                  shrink: true,
                }}
                value={formData.dateDebutConcours}
                onChange={handleChangeFormData}
              />
              <TextField
                id="dateFinConcours"
                label="Date fin concours"
                type="date"
                fullWidth
                margin="dense"
                required
                name="dateFinConcours"
                InputLabelProps={{
                  shrink: true,
                }}
                value={formData.dateFinConcours}
                onChange={handleChangeFormData}
              />

              <h3>Information compte ENI</h3>
              <TextField
                margin="dense"
                id="numCpteENI"
                label="N° de compte ENI"
                type="text"
                fullWidth
                name="numCpteENI"
                required
                value={formData.numCpteENI}
                onChange={handleChangeFormData}
              />
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onClose}>Annuler</Button>
            <Button type="submit" color="primary" variant="contained">
              Ajouter
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
      <SuccessSnackBar
        open={openSnack}
        message="Paramètre ajoutée avec succès!"
        close={() => setOpenSnack(false)}
      />
    </div>
  );
};

export default CreateDialog;

const Form = styled.form`
  h3{
    font-size: .9em;
  }
`;
