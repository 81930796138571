import styled from 'styled-components'

import * as styles from '../../styles/variables'

export const FilledButton = styled.button`
    background-color: ${({bgColor}) => bgColor ? bgColor : `${styles.colors.lightBlue}`};
    height: 44px;
    color: ${({color}) => color ? color : '#fff'};
    border-radius: 12px;  
    border: none;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: ${({bold}) => bold &&'700'};

    cursor: pointer;
`

export const OutlineButton = styled.button`
    background: none;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({color}) => color ? color : `${styles.colors.darkBlue}`} ;
    border-radius: ${({rounded}) => rounded ? '22px' : `12px`};
    border: 1px solid ${({borderColor}) => borderColor ? borderColor : `${styles.colors.darkBlue}`} ;
    padding: 0 20px;
    font-weight: 700;
    cursor: pointer;
`