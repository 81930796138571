import React from 'react'
import styled from 'styled-components'
import { FilledButton } from '../../../styles/candidat/Button.styled'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import * as styles from '../../../styles/variables'
import { Link } from 'react-router-dom';

const OnlineInscription = () => {
  return (
    <OnlineInscriptionWrapper>
        <p>Vous pouvez aussi vous inscrire directement à partir du formulaire d'inscription en ligne.</p>
        <Link to='/inscription' style={{textDecoration: 'none'}}>
          <FilledButton color={styles.colors.lightBlue} bgColor='#fff' bold>
              Accéder au formulaire d'inscription
              <ArrowForwardIcon color={styles.colors.lightBlue} style={{marginLeft: 10}} />
          </FilledButton>
        </Link>
    </OnlineInscriptionWrapper>
  )
}

const OnlineInscriptionWrapper = styled.div`
    max-width: 1280px;
    margin-top: 40px;
    min-height: 220px ;
    background: rgb(12,111,167);
    background: linear-gradient(0deg, rgba(12,111,167,1) 0%, rgba(23,189,225,1) 100%);
    width: 100%;
    border-radius: 28px;
    padding: 20px 40px;

    p{
        color: #fff;
        font-size: 24px;
        max-width: 450px;
    }
`

export default OnlineInscription