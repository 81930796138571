import React, { useState, useEffect } from 'react';
import EditDialog from '../Faritany/EditDialog';
import axios from 'axios';
import { apiRoute } from '../GlobalVariable';

import { DataGrid } from '@mui/x-data-grid';
import { IconButton, TextField } from '@mui/material';
import EditRounded from '@mui/icons-material/EditRounded';
import DeleteRounded from '@mui/icons-material/DeleteRounded';
import WarningSnackBar from '../Snackbar/WarningSnackBar';


const FaritanyTable = (props) => {

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [codeFaritany, setCodeFaritany] = useState();
  const [openSnack, setOpenSnack] = useState(false);

  
  const handleOpenEditDialog = (codeFaritany) => {
    setCodeFaritany(codeFaritany);
    setOpenEditDialog(true)
  }

  const handleCloseEditDialog = (dataUpdated) => {
    props.fetchData();
    setOpenEditDialog(false)
    setCodeFaritany(null);
  }

   //Suppression faritany
   const handleDelete= (id) => {
    if(window.confirm('Voulez-vous vraiment supprimer ce faritany ? ')){
      axios.delete(`/faritany/${id}`)
        .then((res) => {
          setOpenSnack(true);
          props.fetchData();
        });
    }
    };

  const columns = [
    {field: 'codeFaritany', headerName: 'Code Faritany', flex: 1},
    {field: 'nomFaritany', headerName: 'Nom', flex: 1},
    {
      field: 'actions',
      headerName: 'Action',
      renderCell: (params) => (
        <>
          <IconButton title="modifier" sx={{ color: 'blue' }} onClick={() => handleOpenEditDialog(params.id)} >
            <EditRounded />
          </IconButton>
          <IconButton title="supprimer" sx={{ color: 'red' }} onClick={() => handleDelete(params.id)}>
            <DeleteRounded />
          </IconButton>
        </>
      )
    } 

  ]

  return (
    <>
    {
      codeFaritany != null ? <EditDialog open={openEditDialog} codeFaritany={codeFaritany} onClose={handleCloseEditDialog} /> : ""
    }
    
    {props.data && (
      <DataGrid autoHeight
        getRowId={(row) => row.codeFaritany}
        rows={props.filtered}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10,20,30]}
        localeText={{
          MuiTablePagination: {
            labelDisplayedRows: ({ from, to, count }) =>
              `${from} - ${to} sur ${count}`,
            labelRowsPerPage: "Lignes par page",
          },
        }}
      />
    )}
    <WarningSnackBar open={openSnack} message="Faritany supprimé" close={() => setOpenSnack(false)} />
    </>
  );
}

export default FaritanyTable;