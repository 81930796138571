import React, { useState, useEffect } from 'react';
import EditDialog from '../Serie/EditDialog';
import { DataGrid } from '@mui/x-data-grid';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import EditRounded from '@mui/icons-material/EditRounded';
import DeleteRounded from '@mui/icons-material/DeleteRounded';
import { apiRoute, token } from '../GlobalVariable';
import axios from 'axios';
import WarningSnackBar from '../Snackbar/WarningSnackBar';
import SuccessSnackBar from '../Snackbar/SuccessSnackBar';

const SerieTable = (props) => {
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [codeSerie, setCodeSerie] = useState(null);

  const [openSnack, setOpenSnack] = useState(false);

  //DATAGRID
  const [pageSize, setPageSize] = useState(10);

  const handleOpenEditDialog = (codeSerie) => {
    setCodeSerie(codeSerie);
    setOpenEditDialog(true);
  };
  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setCodeSerie(null);
    props.fetchData();
  };

  //CONFIRMATION
  const handleConfirmationDialog = (id) => {
    setOpenConfirmationDialog(true);
    setCodeSerie(id);
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmationDialog(false);
    setCodeSerie(null);
  };

  const handleDelete = (id) => {
    axios
      .delete(`/series/${id}`)
      .then((res) => {
        props.fetchData();
        setOpenConfirmationDialog(false);
        setCodeSerie(null);
        setOpenSnack(true);
      })
      .catch((err) => {});
  };

  const columns = [
    { field: 'codeSerie', headerName: 'Code Série', flex: 1 },
    { field: 'libelleSerie', headerName: 'Série', flex: 1 },
    {
      field: 'actions',
      headerName: 'Action',
      renderCell: (params) => (
        <>
          <IconButton title="modifier" sx={{ color: 'blue' }} onClick={() => handleOpenEditDialog(params.id)}>
            <EditRounded />
          </IconButton>
          <IconButton title="supprimer" sx={{ color: 'red' }} onClick={() => handleConfirmationDialog(params.id)}>
            <DeleteRounded />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <>
      {codeSerie != null ? (
        <EditDialog open={openEditDialog} codeSerie={codeSerie} onClose={handleCloseEditDialog} />
      ) : (
        ''
      )}
      {props.data && (
        <DataGrid
          autoHeight
          getRowId={(row) => row.codeSerie}
          rows={props.filtered}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 20, 30]}
          localeText={{
            MuiTablePagination: {
              labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} sur ${count}`,
              labelRowsPerPage: 'Lignes par page',
            },
          }}
        />
      )}

      {/* CONFIRMATION DIALOG */}
      <Dialog open={openConfirmationDialog} onClose={handleCloseConfirmation}>
        <DialogTitle>Etes-vous sûr de vouloir supprimé ?</DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseConfirmation}>Annuler</Button>
          <Button onClick={() => handleDelete(codeSerie)} autoFocus>
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
      <WarningSnackBar open={openSnack} message="Série supprimée!" close={() => setOpenSnack(false)} />
    </>
  );
};

export default SerieTable;
