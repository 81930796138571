import React, { useState, useEffect } from "react";
import EditDialog from "../Epreuve/EditDialog";
import axios from "axios";
import { apiRoute } from "../GlobalVariable";

import { DataGrid } from "@mui/x-data-grid";
import { IconButton, TextField } from "@mui/material";
import EditRounded from "@mui/icons-material/EditRounded";
import DeleteRounded from "@mui/icons-material/DeleteRounded";
import * as moment from "moment";
import "moment/locale/fr";
import WarningSnackBar from "../Snackbar/WarningSnackBar";
moment.locale("fr");

const FaritanyTable = (props) => {
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [codeEpreuve, setCodeEpreuve] = useState();

  const [openSnack, setOpenSnack] = useState(false);

  const handleOpenEditDialog = (codeEpreuve) => {
    setCodeEpreuve(codeEpreuve);
    setOpenEditDialog(true);
  };
  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setCodeEpreuve(codeEpreuve);
    props.fetchData();
  };

  const handleDelete = (id) => {
    if (window.confirm("Voulez-vous vraiment supprimer cet épreuve ?")) {
      axios.delete(`/epreuve/${id}`).then((res) => {
        props.fetchData();
        setOpenSnack(true);
      });
    }
  };

  const columns = [
    { field: "codeEpreuve", headerName: "Code Epreuve", flex: 1 },
    { field: "libelleEpreuve", headerName: "Epreuve", flex: 1 },
    { field: "coef", headerName: "Coefficient", flex: 1 },
    { field: "duree", headerName: "Durée", flex: 1 },
    { field: "dateEpreuve", headerName: "Date Epreuve", flex: 1 },
    {
      field: "actions",
      headerName: "Action",
      renderCell: (params) => (
        <>
          <IconButton
            title="modifier"
            sx={{ color: "blue" }}
            onClick={() => handleOpenEditDialog(params.id)}
          >
            <EditRounded />
          </IconButton>
          <IconButton
            title="supprimer"
            sx={{ color: "red" }}
            onClick={() => handleDelete(params.id)}
          >
            <DeleteRounded />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <>
      {codeEpreuve != null ? (
        <EditDialog
          open={openEditDialog}
          codeEpreuve={codeEpreuve}
          onClose={handleCloseEditDialog}
        />
      ) : (
        ""
      )}

      {props.data && (
        <DataGrid
          autoHeight
          getRowId={(row) => row.codeEpreuve}
          rows={props.filtered.map((e) => {
            return {
              ...e,
              dateEpreuve: moment(e.dateEpreuve).format("YYYY/MM/DD à hh:mm"),
            };
          })}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10, 20, 30]}
          localeText={{
            MuiTablePagination: {
              labelDisplayedRows: ({ from, to, count }) =>
                `${from} - ${to} sur ${count}`,
              labelRowsPerPage: "Lignes par page",
            },
          }}
        />
      )}
      <WarningSnackBar
        open={openSnack}
        message="Epreuve supprimé"
        close={() => setOpenSnack(false)}
      />
    </>
  );
};

export default FaritanyTable;
