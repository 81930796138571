import React, { useState, useEffect } from 'react';
import EditDialog from '../CentreConcours/EditDialog';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { IconButton, TextField } from '@mui/material';
import EditRounded from '@mui/icons-material/EditRounded';
import DeleteRounded from '@mui/icons-material/DeleteRounded';
import WarningSnackBar from '../Snackbar/WarningSnackBar';

const CentreTable = (props) => {
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [codeCentre, setCodeCentre] = useState();

  const [openSnack, setOpenSnack] = useState(false);

  const [pageSize, setPageSize] = useState(10);
  //Quand on ouvre dialog edition
  const handleOpenEditDialog = (codeCentre) => {
    setCodeCentre(codeCentre);
    setOpenEditDialog(true);
  };

  //Quand on ferme dialog edition , mettre a jour le données modifier
  const handleCloseEditDialog = (dataUpdated) => {
    setOpenEditDialog(false);
    setCodeCentre(null);
    props.fetchData();
  };

  //Suppression centre concours
  const handleDeleteCentreConcours = (id) => {
    if (window.confirm('Voulez-vous vraiment supprimer ce centre ?')) {
      axios.delete(`/centreConcours/${id}`).then((res) => {
        setOpenSnack(true);
        props.fetchData();
      });
    }
  };

  const columns = [
    { field: 'codeCentre', headerName: 'Code Centre', flex: 1 },
    { field: 'nomCentre', headerName: 'Nom Centre', flex: 1 },
    { field: 'plage', headerName: 'Plage', flex: 1 },
    {
      field: 'actions',
      headerName: 'Action',
      renderCell: (params) => (
        <>
          <IconButton title="modifier" sx={{ color: 'blue' }} onClick={() => handleOpenEditDialog(params.id)}>
            <EditRounded />
          </IconButton>
          <IconButton title="supprimer" sx={{ color: 'red' }} onClick={() => handleDeleteCentreConcours(params.id)}>
            <DeleteRounded />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <>
      {codeCentre != null ? (
        <EditDialog open={openEditDialog} codeCentre={codeCentre} onClose={handleCloseEditDialog} />
      ) : (
        ''
      )}

      {props.filtered && (
        <DataGrid
          autoHeight
          getRowId={(row) => row.codeCentre}
          rows={props.filtered}
          columns={columns}
          pageSize={pageSize}
          onPageChange={(pageSize) => setPageSize(pageSize)}
          rowsPerPageOptions={[10, 20]}
          localeText={{
            MuiTablePagination: {
              labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} sur ${count}`,
              labelRowsPerPage: 'Lignes par page',
            },
          }}
        />
      )}
      <WarningSnackBar message="Centre concours supprimé" open={openSnack} close={() => setOpenSnack(false)} />
    </>
  );
};

export default CentreTable;
