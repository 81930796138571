import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

function InfoSnackBar(props) {
  const [open, setOpen] = React.useState(props.open);
  const handleClose = () => {
    props.close();
  };
  return (
    <Snackbar
      open={props.open}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <MuiAlert
        onClose={handleClose}
        severity="info"
        sx={{ width: "100%" }}
        variant="filled"
        elevation={6}
      >
        {props.message}
      </MuiAlert>
    </Snackbar>
  );
}

export default InfoSnackBar;
