import React, { useEffect, useState } from "react";
import CentreConcoursTable from "components/Dashboard/CentreConcours/CentreConcoursTable";
import { Button, TextField } from "@mui/material";
import CreateDialog from "components/Dashboard/CentreConcours/CreateDialog";
import axios from "axios";
import { apiRoute } from "components/Dashboard/GlobalVariable";


const CentreCoucours = () => {

  const [data, setData] = useState([]);
  const [filtered, setFiltered] = useState();
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = () => {
    let filteredTmp = data.filter((ap) => {
      if (
        ap.nomCentre.toLowerCase().includes(searchQuery.toLowerCase()) ||
        String(ap.codeCentre).includes(searchQuery)
      ) {
        return ap;
      }
    });
    setFiltered(filteredTmp);
  };

  const fetchData = () => {
    axios.get('/centreConcours')
      .then(res => {
        setData(res.data);
        setFiltered(res.data);
      })
  }

  useEffect(() => {
    fetchData();
  }, [])

  const [openCreateDialog, setOpenCreateDialog] = useState(false);
    return (
      <>
      <div className="search">
        <TextField
          margin="dense"
          id="search"
          label="Recherche par Nom Centre"
          type="text"
          fullWidth
          variant="standard"
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
          onKeyUp={handleSearch}
          sx={{
            maxWidth: "500px",
          }}
        />
      </div>
        <div>
          <Button variant='contained' onClick={() => setOpenCreateDialog(true)}>Ajouter</Button>
          <CreateDialog 
            open={openCreateDialog}
            onClose={() => setOpenCreateDialog(false)}
            fetchData={fetchData}
          />
        </div>
        <CentreConcoursTable fetchData={fetchData} data={data} filtered={filtered} />
      </>
    );
}

export default CentreCoucours;
