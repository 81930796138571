// LoadingOverlay.js
import React from 'react';
import '../../styles/loadingOverlay.css'; // Créez un fichier CSS pour le style

const LoadingOverlay = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <div className="loading-overlay">
      <div className="spinner"></div>
    </div>
  );
};

export default LoadingOverlay;
