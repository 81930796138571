import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import axios from 'axios';
import Accordion from 'components/FormUI/Accordion';
import FormButton from 'components/FormUI/Button';
import { Formik, Form as FormikForm, useFormikContext } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { FormContext } from '../FormContext';
import Avis from './sections/Avis';
import Bacc from './sections/Bacc';
import InfosPersos from './sections/InfosPersos';
import Inscription from './sections/Inscription';
import PiecesJointes from './sections/PiecesJointes';
import SectionValidation from './SectionValidation';
import UploadProgress from './UploadProgress';
import { compare } from 'utility/utils';
import { useNotification } from 'contexts/NotificationContext';
import Licence from './sections/Licence';

export default function Form(props) {
  const {
    FORM_VALIDATION,
    INITIAL_FORM_STATE,
    PANELS,
    expanded,
    setExpanded,
    isSectionValid,
    setIsSectionValid,
    setAttachmentsForm,
    setIsMaster,
  } = useContext(FormContext);
  const { notifyError } = useNotification();

  const [percentage, setPercentage] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [showSnackBarError, setShowSnackBarError] = useState(false);
  const [infoParametrage, setInforParametrage] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [series, setSeries] = useState(null);
  const [centres, setCentres] = useState(null);
  const [centresConcours, setCentresConcours] = useState(null);
  const [choix, setChoix] = useState(null);
  const [deuxiemeChoix, setDeuxiemeChoix] = useState(null);
  const [typeCandidat, setTypeCandidat] = useState(null);
  const [attachments, setAttachments] = useState(null);

  /**
   * LYFECICLE
   */
  useEffect(() => {
    const url = window.location.href;
    const substring = 'toor';
    if (url.includes(substring)) {
      setIsAdmin(true);
    }
  }, []);

  useEffect(() => {
    if (showProgress) {
      document.querySelector('body').style.overflowY = 'hidden';
    } else {
      document.querySelector('body').style.overflowY = 'scroll';
    }
  }, [showProgress]);

  useEffect(() => {
    axios.get(`/parametres`).then((res) => {
      if (res.data) {
        setInforParametrage(res.data[0]);
      }
    });
  }, []);

  useEffect(() => {
    getListeCentreBacc();
    getTypeCandidat();
  }, []);

  useEffect(() => {
    if (props.infoNiveauxSelected) {
      setIsMaster(props.infoNiveauxSelected?.nom === 'Master');
      const { centreConcours, choix, epreuves, series, attachments } = props.infoNiveauxSelected;

      const mapToOptions = (items, valueKey, textKey) =>
        items.map((item) => ({
          value: item[valueKey],
          text: item[textKey],
        }));

      setSeries(mapToOptions(series, 'codeSerie', 'libelleSerie'));
      setCentresConcours(mapToOptions(centreConcours, 'codeCentre', 'nomCentre'));
      setChoix(mapToOptions(choix, 'codeChoix', 'libelleChoix'));
      setDeuxiemeChoix(mapToOptions(choix, 'codeChoix', 'libelleChoix'));
      setAttachments(attachments);
      setAttachmentsForm(attachments);
    }
  }, [props]);

  /**
   * API
   */

  const getListeCentreBacc = () => {
    axios.get(`/centrebacc`).then((res) => {
      let tmp = [];
      res.data.forEach((centre) => {
        tmp.push({
          value: centre.codeCentreBacc,
          text: centre.nomCentreBacc,
        });
        tmp.sort(compare);
      });
      setCentres(tmp);
    });
  };

  const getTypeCandidat = () => {
    axios.get(`/typecandidat`).then((res) => {
      let tmp = [];
      res.data.forEach((type) => {
        tmp.push({
          value: type.codeTypeCand,
          text: type.libelleTypeCand,
        });
      });
      setTypeCandidat(tmp);
    });
  };

  /**
   * FUNCTION
   */
  const handleSnackBarError = () => {
    let notValid = Object.keys(isSectionValid).some((key) => {
      return isSectionValid[key] == false;
    });
    setShowSnackBarError(notValid);
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const formikRef = useRef();

  useEffect(() => {
    if (formikRef.current) {
      // Revalidation automatique lorsque INITIAL_FORM_STATE ou FORM_VALIDATION change
      formikRef.current.validateForm();
    }
  }, [INITIAL_FORM_STATE, FORM_VALIDATION]);

  return (
    <Wrapper>
      <Formik
        innerRef={formikRef} // Attache la ref ici
        enableReinitialize
        initialValues={{ ...INITIAL_FORM_STATE }}
        validationSchema={FORM_VALIDATION}
        onSubmit={(values) => {
          if (!props.infoNiveauxSelected) return;

          let formData = new FormData();

          // Append other form fields
          for (let key in values) {
            if (key === 'dateNaiss') {
              if (values[key]) {
                formData.append(key, '01/01/' + values[key]);
              } else {
                formData.append(key, '01/01/1900');
              }
            } else if (key === 'deuxiemeChoix' && values[key] !== null) {
              formData.append(key, values[key]);
            } else {
              formData.append(key, values[key]);
            }
          }

          formData.append('montantBord', 0);

          // Append attachments dynamically
          attachments.forEach((attachment) => {
            let fileInput = document.getElementById(attachment.name_code);
            if (fileInput && fileInput.files && fileInput.files.length > 0) {
              formData.append(attachment.name_code, fileInput.files[0]);
            }
          });

          setIsSectionValid((prev) => {
            let bar = Object.assign({}, prev);
            bar.piecesJointes = true;
            return bar;
          });

          formData.append('niveau_id', props.infoNiveauxSelected.id);
          const config = {
            onUploadProgress: (progressEvent) => {
              let percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              setPercentage(percent);
            },
          };

          setShowProgress(true);
          axios
            .post(`/candidat/create`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              ...config,
            })
            .then((res) => {
              setTimeout(() => {
                setUploaded(true);
              }, 1500);
            })
            .catch((err) => {
              if (err.response) {
                setShowProgress(false);
                notifyError(err.response.data.message || err.response.data);
              } else if (err.request) {
                console.log('Aucune réponse reçue du backend');
              } else {
                console.log('Erreur lors de la configuration de la requête', err.message);
              }
            });
        }}
      >
        {({ validateForm }) => {
          return (
            <FormikForm>
              <SectionValidation setIsValids={setIsSectionValid} infoNiveauxSelected={props.infoNiveauxSelected} />
              <div className="accordions">
                <Accordion
                  title="Avis de concours"
                  isValid={true}
                  expanded={expanded === PANELS.AVIS}
                  onChange={handleAccordionChange(PANELS.AVIS)}
                  id={PANELS.AVIS}
                >
                  {/* form ui */}
                  <Avis nextAccordion={PANELS.INFOSPERSOS} infoParametrage={infoParametrage} />
                </Accordion>

                <Accordion
                  title="Informations personnelles"
                  isValid={isSectionValid.infosPersos}
                  expanded={expanded === PANELS.INFOSPERSOS}
                  onChange={handleAccordionChange(PANELS.INFOSPERSOS)}
                  id={PANELS.INFOSPERSOS}
                >
                  {/* form ui */}
                  <InfosPersos
                    prevAccordion={PANELS.AVIS}
                    nextAccordion={PANELS.BACC}
                    setIsValids={setIsSectionValid}
                  />
                </Accordion>

                <Accordion
                  title="BACC"
                  isValid={isSectionValid.bacc}
                  expanded={expanded === PANELS.BACC}
                  onChange={handleAccordionChange(PANELS.BACC)}
                  id={PANELS.BACC}
                >
                  {/* form ui */}

                  <Bacc
                    setExpandedAccordion={setExpanded}
                    prevAccordion={PANELS.INFOSPERSOS}
                    nextAccordion={PANELS.INSCRIPTION}
                    setIsValids={setIsSectionValid}
                    series={series}
                    centres={centres}
                  />
                </Accordion>

                {props?.infoNiveauxSelected?.nom === 'Master' && (
                  <Accordion
                    title="LICENCE"
                    isValid={isSectionValid.licence}
                    expanded={expanded === PANELS.LICENCE}
                    onChange={handleAccordionChange(PANELS.LICENCE)}
                    id={PANELS.LICENCE}
                  >
                    {/* form ui */}

                    <Licence
                      setExpandedAccordion={setExpanded}
                      prevAccordion={PANELS.INFOSPERSOS}
                      nextAccordion={PANELS.INSCRIPTION}
                      setIsValids={setIsSectionValid}
                    />
                  </Accordion>
                )}

                <Accordion
                  title="Inscription"
                  isValid={isSectionValid.inscription}
                  expanded={expanded === PANELS.INSCRIPTION}
                  onChange={handleAccordionChange(PANELS.INSCRIPTION)}
                  id={PANELS.INSCRIPTION}
                >
                  {/* form ui */}
                  <Inscription
                    setExpandedAccordion={setExpanded}
                    prevAccordion={PANELS.BACC}
                    nextAccordion={PANELS.PIECESJOINTES}
                    setIsValids={setIsSectionValid}
                    series={series}
                    centresConcours={centresConcours}
                    deuxiemeChoix={deuxiemeChoix}
                    choix={choix}
                    typeCandidat={typeCandidat}
                    setDeuxiemeChoix={setDeuxiemeChoix}
                    infoNiveauxSelected={props.infoNiveauxSelected}
                  />
                </Accordion>

                <Accordion
                  title="Pieces jointes"
                  isValid={isSectionValid.piecesJointes}
                  expanded={expanded === PANELS.PIECESJOINTES}
                  onChange={handleAccordionChange(PANELS.PIECESJOINTES)}
                  id={PANELS.PIECESJOINTES}
                >
                  {/* form ui */}
                  <PiecesJointes
                    attachments={attachments}
                    setExpandedAccordion={setExpanded}
                    prevAccordion={PANELS.INSCRIPTION}
                  />
                </Accordion>
              </div>
              <div className="btn-section">
                {infoParametrage.isActive || isAdmin ? (
                  <FormButton type="submit" color="success" className="submitBtn" onClick={handleSnackBarError}>
                    S’inscrire
                  </FormButton>
                ) : (
                  <FormButton type="button" color="success" className="submitBtn" disabled>
                    Inscription fermée
                  </FormButton>
                )}
              </div>
            </FormikForm>
          );
        }}
      </Formik>

      <Snackbar
        open={showSnackBarError}
        autoHideDuration={6000}
        onClose={() => setShowSnackBarError(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity="error" variant="filled">
          Certains champs sont invalides ou incomplets, veuiller les corriger s'il vous plaît !
          <IconButton size="small" aria-label="close" color="inherit" onClick={() => setShowSnackBarError(false)}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Alert>
      </Snackbar>

      {showProgress ? (
        <div className="progress">
          <UploadProgress value={percentage} />
        </div>
      ) : (
        ''
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .accordions {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 7vh;
    .accordion {
    }
  }
  .btn-section {
    margin-top: 30px;
    display: flex;
    justify-content: right;
    gap: 20px;
  }
  .submitBtn {
    margin-bottom: 10vh;
  }
  .progress {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #6a6a6a5e;
    backdrop-filter: blur(6px);
    z-index: 9999;
  }
`;
