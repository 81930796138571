import React, { useState } from 'react';
import styled from 'styled-components';
import FormButton from 'components/FormUI/Button';
import Select from 'components/FormUI/Select';
import TextField from 'components/FormUI/TextField';
import { useFormikContext } from 'formik';

export default function Licence({ setExpandedAccordion, nextAccordion, prevAccordion, setIsValids, ...otherProps }) {
  const { setFieldValue, errors, touched } = useFormikContext();
  const [mentions, setMentions] = useState([
    {
      value: 'passable',
      text: 'Passable',
    },
    {
      value: 'AB',
      text: 'Assez-bien',
    },
    {
      value: 'B',
      text: 'Bien',
    },
    {
      value: 'TB',
      text: 'Très bien',
    },
  ]);
  return (
    <Wrapper>
      <div className="fields">
        <TextField name="etablissementLicence" label="Etablissement *" tooltip="Etablissement" type="text" />

        <TextField name="anneeLicence" label="Session *" tooltip="Session (année)" type="number" placeholder="Année" />

        {mentions ? (
          <Select
            name="mentionLicence"
            label="Mention *"
            tooltip="Mention obtenue en Licence"
            options={mentions}
            className="select"
          />
        ) : (
          ''
        )}
      </div>

      <div className="btn-section">
        <FormButton
          color="secondary"
          onClick={() => {
            setExpandedAccordion(prevAccordion);
          }}
        >
          Précédent
        </FormButton>
        <FormButton
          color="primary"
          onClick={() => {
            setExpandedAccordion(nextAccordion);
          }}
        >
          Suivant
        </FormButton>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .fields {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    .field,
    .select {
      max-width: 450px;
      width: 100%;
    }
  }
`;
