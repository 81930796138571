import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// Styled components
const InputWrapper = styled.div`
  .note input {
    width: 100%;
    padding: 8px;
    margin: 8px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px; /* Meilleure lisibilité */
  }

  .error {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
`;

const NoteInput = ({ convocationNumero, onNoteChange, defaultValue = '' }) => {
  const [error, setError] = useState('');
  const [note, setNote] = useState('');

  // Mettre à jour la valeur de l'input si la valeur par défaut change
  useEffect(() => {
    // Conversion du point en virgule pour l'affichage initial
    setNote(defaultValue.toString().replace('.', ','));
  }, [defaultValue]);

  const handleInputChange = (e) => {
    let value = e.target.value.replace('.', ','); // Remplace les points par des virgules pour l'affichage
    setNote(value);

    // Vérification en temps réel
    const numericValue = parseFloat(value.replace(',', '.')); // Conversion en valeur numérique pour la validation
    if (value === '' || (numericValue >= 0 && numericValue <= 20 && !isNaN(numericValue))) {
      setError(''); // Pas d'erreur
    } else {
      setError('La note doit être comprise entre 0 et 20 avec un maximum de 2 décimales.');
    }
  };

  const handleBlur = () => {
    let value = note.replace(',', '.'); // Remplacement de la virgule par un point pour la conversion en numérique
    const numericValue = parseFloat(value);

    if (note === '') {
      setError('');
      onNoteChange({
        target: {
          name: `${convocationNumero}`,
          value: '', // Envoyer une chaîne vide
        },
      });
    } else if (numericValue >= 0 && numericValue <= 20 && !isNaN(numericValue)) {
      const formattedValue = numericValue.toFixed(2); // Formatage avec 2 décimales
      setNote(formattedValue.replace('.', ',')); // Mettre à jour l'affichage avec une virgule
      setError(''); // Pas d'erreur

      // Envoyer la valeur formatée avec un point pour le backend
      onNoteChange({
        target: {
          name: `${convocationNumero}`,
          value: formattedValue, // Envoyer la valeur formatée avec deux décimales et un point
        },
      });
    } else {
      setError('La note doit être comprise entre 0 et 20 avec un maximum de 2 décimales.');
    }
  };

  return (
    <InputWrapper>
      <div className="note">
        <input
          type="text" // Utilisation de type="text" pour permettre la gestion complète des entrées
          required
          name={`${convocationNumero}`}
          value={note}
          onChange={handleInputChange} // Mise à jour immédiate de l'état local
          onBlur={handleBlur} // Validation et mise à jour uniquement lors du blur
          placeholder={`Saisissez ici la note de ${convocationNumero} (Note /20)`}
        />
        {error && <div className="error">{error}</div>}
      </div>
    </InputWrapper>
  );
};

export default NoteInput;
