import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import SuccessSnackBar from '../Snackbar/SuccessSnackBar';

export default function EditDialog(props) {
  const [libelleResultat, setLibelleResultat] = useState('');
  const [openSnack, setOpenSnack] = useState(false);

  useEffect(() => {
    axios.get(`/resultat/${props.codeResultat}`).then((res) => {
      setLibelleResultat(res.data.libelleResultat);
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put(`/resultat/${props.codeResultat}`, {
        libelleResultat: libelleResultat,
      })
      .then((res) => {
        setOpenSnack(true);
        setTimeout(() => {
          props.onClose();
        }, 2000);
      })
      .catch((err) => {});
  };

  return (
    <div>
      <Dialog open={props.open} onClose={props.onClose} maxWidth="xs">
        <form onSubmit={(e) => handleSubmit(e)}>
          <DialogTitle>Modifier Faritany</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="libelleResultat"
              label="Libellé Résultat"
              type="text"
              fullWidth
              variant="outlined"
              value={libelleResultat}
              onChange={(e) => setLibelleResultat(e.target.value)}
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onClose}>Annuler</Button>
            <Button type="submit" color="primary" variant="contained">
              Modifier
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <SuccessSnackBar open={openSnack} message="Série Modifiée avec succès!" close={() => setOpenSnack(false)} />
    </div>
  );
}
