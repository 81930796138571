import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { apiRoute, token } from '../GlobalVariable';
import SuccessSnackBar from '../Snackbar/SuccessSnackBar';

const CreateDialog = (props) => {
  const [openSnack, setOpenSnack] = useState(false);

  //DATA
  const [libelleChoix, setLibelleChoix] = useState('');
  const [limiteAge, setLimiteAge] = useState();
  const [limiteBacc, setLimiteBacc] = useState();
  const [nbPlaces, setNbPlaces] = useState();
  const [nbListeAttente, setNbListeAttente] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(`/choix`, {
        libelleChoix: libelleChoix,
        limiteAge: limiteAge,
        limiteBacc: limiteBacc,
        nbPlaces: nbPlaces,
        nbListeAttente: nbListeAttente,
      })
      .then((res) => {
        props.onClose();
        props.fetchData();
        setOpenSnack(true);
      })
      .catch((err) => {});
  };

  return (
    <div>
      <Dialog open={props.open} onClose={props.onClose} maxWidth="xs">
        <form onSubmit={(e) => handleSubmit(e)}>
          <DialogTitle>Ajouter un nouveau choix</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="libelleChoix"
              label="Libellé choix"
              type="text"
              fullWidth
              variant="standard"
              onChange={(e) => setLibelleChoix(e.target.value)}
              required
            />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <TextField
                margin="dense"
                id="limiteAge"
                label="Limite Age"
                type="number"
                variant="standard"
                style={{ width: '40%' }}
                onChange={(e) => setLimiteAge(e.target.value)}
                required
              />
              <TextField
                margin="dense"
                id="limiteBacc"
                label="Limite Bacc"
                type="number"
                variant="standard"
                style={{ width: '40%' }}
                onChange={(e) => setLimiteBacc(e.target.value)}
                required
              />
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <TextField
                margin="dense"
                id="nbPlaces"
                label="Nombre de places"
                type="number"
                style={{ width: '40%' }}
                variant="standard"
                onChange={(e) => setNbPlaces(e.target.value)}
                required
              />
              <TextField
                margin="dense"
                id="nbListeAttente"
                label="Nombre liste d'attente"
                type="text"
                style={{ width: '40%' }}
                variant="standard"
                onChange={(e) => setNbListeAttente(e.target.value)}
                required
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onClose}>Annuler</Button>
            <Button type="submit" color="primary" variant="contained">
              Ajouter
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <SuccessSnackBar open={openSnack} message="Choix ajouté avec succès!" close={() => setOpenSnack(false)} />
    </div>
  );
};

export default CreateDialog;
