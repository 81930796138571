import axios from 'axios';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { useParams, useNavigate } from 'react-router-dom';
import { apiRouteFile } from 'components/Dashboard/GlobalVariable';
import { Avatar, IconButton, InputAdornment, TextField } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import EditDialog from './EditDialog';

import Zoom from 'react-medium-image-zoom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import 'react-medium-image-zoom/dist/styles.css';
import FormButton from 'components/FormUI/Button';
import SuccessSnackBar from '../Snackbar/SuccessSnackBar';
import * as moment from 'moment';
import 'moment/locale/fr';
import WarningSnackBar from '../Snackbar/WarningSnackBar';
import AlertDialogSlide from '../DeleteConfirmation/DeleteConfirmation';
import ImageGallery from './ImageGallery';
import CandidatAttachments from './CandidatAttachments';
moment.locale('fr');

const CandidatDetails = () => {
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [candidat, setCandidat] = useState();
  const { numInscription } = useParams();
  const [droit, setDroit] = useState([]);
  const [openSnack, setOpenSnack] = useState(false);
  const [openUndefinedSnack, setOpenUndefinedSnack] = useState(false);
  const [lastNumber, setLastNumber] = useState('');
  const [succesMessage, setSuccesMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [disabledBoutonValid, setDisabledBoutonValid] = useState(false);
  const [messageConfirmationDialog, setMessageConfirmationDialog] = useState('');
  const [code, setCode] = useState(30);
  const navigate = useNavigate();
  const [urlPhotoCandidat, setUrlPhotoCandidat] = useState(null);
  const [situationMatrimonial] = useState([
    { value: 'C', text: 'Célibataire' },
    { value: 'M', text: 'Marié(e)' },
    { value: 'D', text: 'Divorcé(e)' },
    { value: 'V', text: 'Veuf(ve)' },
  ]);
  const [situationMatrimonialValue, setSituationMatrimonialValue] = useState('C');
  const [centres, setCentres] = useState(null);
  const [centresBacc, setCentresBacc] = useState(null);
  const [typeCandidat, setTypeCandidat] = useState(null);
  const [choix, setChoix] = useState(null);
  const [deuxiemeChoix, setDeuxiemeChoix] = useState(null);
  const [series, setSeries] = useState(null);
  const [mentions, setMentions] = useState([
    {
      value: 'passable',
      text: 'Passable',
    },
    {
      value: 'AB',
      text: 'Assez-bien',
    },
    {
      value: 'B',
      text: 'Bien',
    },
    {
      value: 'TB',
      text: 'Très bien',
    },
  ]);

  useEffect(() => {
    fetchDroitTypeCandidate(fetchDetailCandidate);
    lastNumberConvocation();
  }, []);

  useEffect(() => {
    if (candidat?.attachments && candidat?.attachments.length > 0) {
      const photoCandidat = candidat.attachments.find((a) => a?.attachment?.name_code === 'photos_identite');
      if (photoCandidat) {
        setUrlPhotoCandidat(`${apiRouteFile}/${photoCandidat.link}`);
      }
    }
  }, [candidat]);

  useEffect(() => {
    if (succesMessage !== '') {
      setOpenSnack(true);
    }
  }, [succesMessage]);

  useEffect(() => {
    if (errorMessage !== '') {
      setOpenUndefinedSnack(true);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (messageConfirmationDialog !== '') {
      setOpenDeleteDialog(true);
    } else {
      setOpenDeleteDialog(false);
    }
  }, [messageConfirmationDialog]);

  useEffect(() => {
    axios.get(`/centreconcours`).then((res) => {
      let tmp = [];
      res.data.forEach((centre) => {
        tmp.push({
          value: centre.codeCentre,
          text: centre.nomCentre,
        });
      });
      setCentres(tmp);
    });

    axios.get(`/choix`).then((res) => {
      let tmp = [];
      res.data.forEach((choix) => {
        tmp.push({
          value: choix.codeChoix,
          text: choix.libelleChoix,
        });
      });
      setChoix(tmp);
      setDeuxiemeChoix(tmp);
    });

    axios.get(`/typecandidat`).then((res) => {
      let tmp = [];
      res.data.forEach((type) => {
        tmp.push({
          value: type.codeTypeCand,
          text: type.libelleTypeCand,
        });
      });
      setTypeCandidat(tmp);
    });

    axios.get(`/series`).then((res) => {
      let tmp = [];
      res.data.forEach((serie) => {
        tmp.push({
          value: serie.codeSerie,
          text: serie.libelleSerie,
        });
      });
      setSeries(tmp);
    });
    axios.get(`/centrebacc`).then((res) => {
      let tmp = [];
      res.data.forEach((centre) => {
        tmp.push({
          value: centre.codeCentreBacc,
          text: centre.nomCentreBacc,
        });
        tmp.sort(compare);
      });
      setCentresBacc(tmp);
    });
  }, []);

  /**
   * FUNCTION
   */

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  //fonction return situation matrimoniale
  const situationMatrimoniale = (value) => {
    return [
      { value: 'C', text: 'Célibataire' },
      { value: 'M', text: 'Marié(e)' },
      { value: 'D', text: 'Divorcé(e)' },
      { value: 'V', text: 'Veuf(ve)' },
    ].filter((s) => s.value === value)[0].text;
  };

  //handle change modification num conv
  const handleChangeNumConvocation = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setLastNumber(value.slice(0, 3));
  };

  /**
   * Open dialog suppression confirmation
   */
  const dialogDelete = () => {
    if (candidat?.convocation?.id !== undefined) {
      setOpenDeleteDialog(true);
      setMessageConfirmationDialog(
        `Vous voullez supprimer vraiment cet candidat et libérer le numéro d'inscription <b> ${candidat?.convocation?.numero.slice(
          0,
          2
        )} ${candidat?.convocation?.numero.slice(2)}</b> ? L'action est irreverssible.`
      );
    } else {
      setOpenDeleteDialog(true);
      setMessageConfirmationDialog(`Vous voullez supprimer vraiment cet candidat  ? L'action est irreverssible.`);
    }
  };

  const rechercheCodeCentre = (query) => {
    return centres.filter((c) => c.value === Number(query))[0].text;
  };

  const recherchePremierChoix = (query) => {
    return choix.filter((c) => c.value === Number(query))[0].text;
  };

  const recherCentreBacc = (query) => {
    return centresBacc.filter((c) => c.value === Number(query))[0].text;
  };

  const recherSerie = (query) => {
    return series.filter((c) => c.value === Number(query))[0].text;
  };

  const recherMention = (query) => {
    return mentions.filter((c) => c.value === query)[0].text;
  };

  /**
   * API
   */
  const fetchDetailCandidate = () => {
    axios.get(`/candidat/relation/${numInscription}`).then(
      (res) => {
        setCandidat(res.data);
        setSituationMatrimonialValue(res.data.situationMatrimonial);
      },
      (error) => {
        setErrorMessage("Le candidat n'existe pas ");
        setTimeout(() => {
          navigate('/toor/candidat');
        }, 3000);
      }
    );
  };

  const fetchDroitTypeCandidate = (cb) => {
    axios.get('/droit').then((res) => {
      setDroit(res.data);
      cb();
    });
  };

  const generateConvocation = (data) => {
    setOpenUndefinedSnack(false);
    setOpenSnack(false);

    // Quand on utilise l'atribution automatique mais modifiable du numero
    if (lastNumber.length >= 3) {
      setDisabledBoutonValid(true);
      axios
        .post(`/convocation/createconvocationmanually`, {
          numero: `${code}${lastNumber}`,
          idCandidat: numInscription,
          nomCentreConcours: candidat.codeCentre.nomCentre,
          download: false,
        })
        .then((res) => {
          if (res.data.message !== 'numéro existe déjà') {
            setTimeout(() => {
              fetchDetailCandidate();
            }, 2000);
            setOpenSnack(true);
            setSuccesMessage('Numéro générer automatiquement');
          } else {
            setDisabledBoutonValid(false);
            setOpenUndefinedSnack(true);
            setErrorMessage('Numéro invalid ou le numéro existe déja');
          }
        });
    } else {
      setOpenUndefinedSnack(true);
      setErrorMessage('Numéro invalid ou le numéro existe déja');
    }
  };

  //Prend dernier numéro % au centre d'examen
  const lastNumberConvocation = () => {
    axios.get(`/convocation/candidatetconvocationmanually/${numInscription}`).then((res) => {
      const { candidat, code, lastNumber } = res.data;
      setCode(code);
      setLastNumber(lastNumber);
    });
  };

  const handleDeleteCandidat = (confirm) => {
    if (confirm === true) {
      if (candidat?.convocation?.id !== undefined) {
        axios.delete(`/convocation/${candidat.convocation.id}`).then((res) => {
          if (res.data.error === false) {
            supprimerCandidate();
          }
        });
      } else {
        supprimerCandidate();
      }
    } else {
      setOpenDeleteDialog(confirm);
    }
  };

  const supprimerCandidate = (id) => {
    axios.delete(`/candidat/${numInscription}`).then((res) => {
      if (res.data.error === false) {
        let message =
          candidat?.convocation?.numero !== undefined
            ? `et le numéro ${candidat?.convocation?.numero.slice(0, 2)} ${candidat?.convocation?.numero.slice(
                2
              )} est libérer`
            : '';
        setOpenDeleteDialog(false);
        setSuccesMessage(`Candidate #id ${candidat.numInscription} est supprimé avec succèes ${message}`);
        setTimeout(() => {
          navigate('/toor/candidat');
        }, 3000);
      }
    });
  };

  //TODO
  const changeDisplayInput = (property) => {
    const elemSpan = document.getElementById(property);
    const elemInput = document.getElementById(`${property}In`);
    if (elemInput.style.display === 'none') {
      elemSpan.style.display = 'none';
      elemInput.style.display = 'inline-block';
      elemInput.focus();
      elemInput.value = elemSpan.innerText;
    } else {
      elemSpan.style.display = 'inline-block';
      elemInput.style.display = 'none';

      if (elemSpan.innerHTML !== elemInput.value && elemInput.value !== '') {
        updateCandidate(property, elemInput.value);

        switch (property) {
          case 'situationMatrimoniale':
            elemSpan.textContent = situationMatrimoniale(elemInput.value);
            break;
          case 'codeCentre':
            elemSpan.textContent = rechercheCodeCentre(elemInput.value);
            break;
          case 'premierChoix':
            elemSpan.textContent = recherchePremierChoix(elemInput.value);
            break;

          case 'deuxiemeChoix':
            elemSpan.textContent = recherchePremierChoix(elemInput.value);
            break;

          case 'codeCentreBacc':
            elemSpan.textContent = recherCentreBacc(elemInput.value);
            break;

          case 'codeSerie':
            elemSpan.textContent = recherSerie(elemInput.value);
            break;

          case 'mention':
            elemSpan.textContent = recherMention(elemInput.value);
            break;

          default:
            elemSpan.textContent = elemInput.value;
            break;
        }
      }
    }
  };

  const updateCandidate = (property, newValue) => {
    axios
      .put(`/candidat/edit-property/${numInscription}`, {
        [property]: newValue,
      })
      .then((res) => {
        if (res.data.error === false) {
        }
      });
  };

  const compare = (a, b) => {
    if (a.text < b.text) return -1;
    if (a.text > b.text) return 1;
    return 0;
  };

  return (
    <>
      {candidat && (
        <CandidatWrapper>
          <EditDialog
            numInscription={candidat.numInscription}
            open={openEditDialog}
            onClose={handleCloseEditDialog}
            candidat={candidat}
          />
          <div className="flex space-between">
            <div className="back-icon" onClick={() => navigate('/toor/candidat')}>
              <ArrowBackIcon style={{ cursor: 'pointer' }} />
            </div>
            <div className="buttons">
              <IconButton
                title="Supprimer"
                size="large"
                sx={{ color: 'red' }}
                onClick={(e) => {
                  e.preventDefault();
                  dialogDelete();
                }}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          </div>
          <div className="header">
            <div className="main-info">
              <Zoom>
                <Avatar sx={{ width: 120, height: 120, marginRight: '20px' }} src={`${urlPhotoCandidat}`}>
                  Photo
                </Avatar>
              </Zoom>
              <div>
                {candidat?.convocation?.id !== undefined ? (
                  <p>
                    <span className="subtitle">Enregistrer sous numéro: </span>
                    <b>{candidat?.convocation?.numero.slice(0, 2) + ' ' + candidat?.convocation?.numero.slice(2)}</b>
                  </p>
                ) : (
                  <></>
                )}
                <p>
                  <span className="subtitle">Niveau: </span>
                  <span id="niveau">{candidat?.niveau?.nom}</span>
                </p>
                <p
                  onClick={(e) => {
                    changeDisplayInput('nom');
                  }}
                >
                  <span className="subtitle">Nom: </span>

                  <input
                    type="text"
                    id="nomIn"
                    style={{ display: 'none' }}
                    onBlur={() => {
                      changeDisplayInput('nom');
                    }}
                  />
                  <span id="nom">{candidat.nom}</span>
                </p>
                <p
                  onClick={(e) => {
                    changeDisplayInput('prenoms');
                  }}
                >
                  <span className="subtitle">Prénoms: </span>

                  <input
                    type="text"
                    id="prenomsIn"
                    style={{ display: 'none' }}
                    onBlur={() => {
                      changeDisplayInput('prenoms');
                    }}
                  />
                  <span id="prenoms">{candidat.prenoms ? candidat.prenoms : <span>&#8709;</span>}</span>
                </p>
                <p>
                  <span className="subtitle">Type Candidat: </span>
                  <select
                    style={{ display: 'none' }}
                    id="libelleTypeCandIn"
                    onChange={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <option value="">dede</option>
                    <option value="">dede</option>
                  </select>
                  <span
                    id="libelleTypeCand"
                    onClick={(e) => {
                      e.preventDefault();
                      changeDisplayInput('libelleTypeCand');
                    }}
                  >
                    {candidat.codeTypeCand && candidat.codeTypeCand.libelleTypeCand}
                  </span>
                </p>
                <p
                  onClick={(e) => {
                    changeDisplayInput('telephone');
                  }}
                >
                  <span className="subtitle">Téléphone: </span>
                  <input
                    type="text"
                    id="telephoneIn"
                    style={{ display: 'none' }}
                    onBlur={() => {
                      changeDisplayInput('telephone');
                    }}
                  />
                  <span id="telephone">{candidat.telephone}</span>
                </p>
              </div>
            </div>
          </div>
          <div className="info">
            <div className="section">
              <strong className="title">INFORMATIONS GENERALES</strong>
              {moment(candidat.dateNaissExacte).isValid() &&
                moment(candidat.dateNaissExacte).format('DD/MM/YYYY').split('/')[2] !== '1900' && (
                  <p
                    onClick={(e) => {
                      changeDisplayInput('dateNaissExacte');
                    }}
                  >
                    <span className="subtitle">Date de naissance: </span>
                    <input
                      type="date"
                      id="dateNaissExacteIn"
                      style={{ display: 'none' }}
                      onBlur={() => {
                        changeDisplayInput('dateNaissExacte');
                      }}
                    />
                    <span id="dateNaissExacte">{moment(candidat.dateNaissExacte).format('DD/MM/YYYY')}</span>
                  </p>
                )}
              {moment(candidat.dateNaiss).isValid() &&
                moment(candidat.dateNaiss).format('DD/MM/YYYY').split('/')[2] !== '1900' && (
                  <p>
                    <span className="subtitle">Année de naissance: </span>
                    {moment(candidat.dateNaiss).format('YYYY')}
                  </p>
                )}
              <p
                onClick={(e) => {
                  changeDisplayInput('lieuNaissance');
                }}
              >
                <span className="subtitle">Lieu de naissance: </span>
                <input
                  type="text"
                  id="lieuNaissanceIn"
                  style={{ display: 'none' }}
                  onBlur={() => {
                    changeDisplayInput('lieuNaissance');
                  }}
                />
                <span id="lieuNaissance">{candidat.lieuNaissance}</span>
              </p>
              <p>
                <span className="subtitle">Situation matrimoniale: </span>
                <select
                  style={{ display: 'none' }}
                  id="situationMatrimonialeIn"
                  name="situationMatrimoniale"
                  onBlur={(e) => {
                    changeDisplayInput('situationMatrimoniale', e);
                  }}
                >
                  {situationMatrimonial.map((s, index) => (
                    <option value={s.value} key={`sm_${index}`}>
                      {s.text}
                    </option>
                  ))}
                </select>
                <span
                  onClick={(e) => {
                    changeDisplayInput('situationMatrimoniale');
                  }}
                  id="situationMatrimoniale"
                >
                  {situationMatrimoniale(candidat.situationMatrimoniale)}
                </span>
              </p>
              <p
                onClick={(e) => {
                  changeDisplayInput('nomPere');
                }}
              >
                <span className="subtitle">Nom du père: </span>
                <input
                  type="text"
                  id="nomPereIn"
                  style={{ display: 'none' }}
                  onBlur={() => {
                    changeDisplayInput('nomPere');
                  }}
                />
                <span id="nomPere">{candidat.nomPere ? candidat.nomPere : <span>&#8709;</span>}</span>
              </p>
              <p
                onClick={(e) => {
                  changeDisplayInput('nomMere');
                }}
              >
                <span className="subtitle">Nom de la mère: </span>
                <input
                  type="text"
                  id="nomMereIn"
                  style={{ display: 'none' }}
                  onBlur={() => {
                    changeDisplayInput('nomMere');
                  }}
                />
                <span id="nomMere">{candidat.nomMere}</span>
              </p>
              <p
                onClick={(e) => {
                  changeDisplayInput('adresseParent');
                }}
              >
                <span className="subtitle">Adresse des parents: </span>
                <input
                  type="text"
                  id="adresseParentIn"
                  style={{ display: 'none' }}
                  onBlur={() => {
                    changeDisplayInput('adresseParent');
                  }}
                />
                <span id="adresseParent">{candidat.adresseParent}</span>
              </p>
              <p
                onClick={(e) => {
                  changeDisplayInput('mel');
                }}
              >
                <span className="subtitle">Mail: </span>
                <input
                  type="text"
                  id="melIn"
                  style={{ display: 'none' }}
                  onBlur={() => {
                    changeDisplayInput('mel');
                  }}
                />
                <span id="mel">{candidat.mel}</span>
              </p>
            </div>
            <hr />
            <div className="section">
              <strong className="title">INFO CONCOURS</strong>
              <p>
                <span className="subtitle">Lieu Concours: </span>
                <select
                  style={{ display: 'none' }}
                  id="codeCentreIn"
                  name="codeCentre"
                  onBlur={(e) => {
                    changeDisplayInput('codeCentre', e);
                  }}
                >
                  {centres.map((s, index) => (
                    <option value={s.value} key={`sm_${index}`}>
                      {s.text}
                    </option>
                  ))}
                </select>
                <span
                  onClick={(e) => {
                    changeDisplayInput('codeCentre');
                  }}
                  id="codeCentre"
                >
                  {candidat.codeCentre && candidat.codeCentre.nomCentre}
                </span>
              </p>
              <p>
                <span className="subtitle">Premier Choix: </span>
                <select
                  style={{ display: 'none' }}
                  id="premierChoixIn"
                  name="premierChoix"
                  onBlur={(e) => {
                    changeDisplayInput('premierChoix', e);
                  }}
                >
                  {choix.map((s, index) => (
                    <option value={s.value} key={`sm_${index}`}>
                      {s.text}
                    </option>
                  ))}
                </select>
                <span
                  onClick={(e) => {
                    changeDisplayInput('premierChoix');
                  }}
                  id="premierChoix"
                >
                  {candidat.premierChoix.libelleChoix}
                </span>
              </p>
              <p>
                <span className="subtitle">Deuxième choix: </span>
                <select
                  style={{ display: 'none' }}
                  id="deuxiemeChoixIn"
                  name="deuxiemeChoix"
                  onBlur={(e) => {
                    changeDisplayInput('deuxiemeChoix', e);
                  }}
                >
                  {choix.map((s, index) => (
                    <option value={s.value} key={`sm_${index}`}>
                      {s.text}
                    </option>
                  ))}
                </select>
                <span
                  onClick={(e) => {
                    changeDisplayInput('deuxiemeChoix');
                  }}
                  id="deuxiemeChoix"
                >
                  {candidat?.deuxiemeChoix?.libelleChoix}
                </span>
              </p>
            </div>
            <hr />
            <div className="section">
              <strong className="title">CIN</strong>
              <div className="cin-container">
                <div className="cin">
                  <p
                    onClick={(e) => {
                      changeDisplayInput('numCIN');
                    }}
                  >
                    <span className="subtitle">Numéro CIN: </span>
                    <input
                      type="text"
                      id="numCINIn"
                      style={{ display: 'none' }}
                      onBlur={() => {
                        changeDisplayInput('numCIN');
                      }}
                    />
                    <span id="numCIN">{candidat.numCIN ? candidat.numCIN : <span>&#8709;</span>}</span>
                  </p>
                  <p>
                    <span className="subtitle">Date CIN: </span>
                    {candidat.dateCIN ? moment(candidat.dateCIN).format('DD/MM/YYYY') : <span>&#8709;</span>}
                  </p>
                  <p
                    onClick={(e) => {
                      changeDisplayInput('lieuCIN');
                    }}
                  >
                    <span className="subtitle">Lieu CIN: </span>
                    <input
                      type="text"
                      id="lieuCINIn"
                      style={{ display: 'none' }}
                      onBlur={() => {
                        changeDisplayInput('lieuCIN');
                      }}
                    />
                    <span id="lieuCIN">{candidat.lieuCIN ? candidat.lieuCIN : <span>&#8709;</span>}</span>
                  </p>
                </div>
                <div className="duplicata">
                  <p>
                    <span className="subtitle">Date duplicata: </span>
                    {candidat.dateDuplicata ? (
                      moment(candidat.dateDuplicata).format('DD/MM/YYYY')
                    ) : (
                      <span>&#8709;</span>
                    )}
                  </p>
                  <p
                    onClick={(e) => {
                      changeDisplayInput('lieuDuplicata');
                    }}
                  >
                    <span className="subtitle">Lieu duplicata: </span>
                    <input
                      type="text"
                      id="lieuDuplicataIn"
                      style={{ display: 'none' }}
                      onBlur={() => {
                        changeDisplayInput('lieuDuplicata');
                      }}
                    />
                    <span id="lieuDuplicata">
                      {candidat.lieuDuplicata ? candidat.lieuDuplicata : <span>&#8709;</span>}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <hr />
            <div className="section">
              <strong className="title">BACC</strong>
              <p>
                <span className="subtitle">Centre Bacc: </span>
                <select
                  style={{ display: 'none' }}
                  id="codeCentreBaccIn"
                  name="codeCentreBacc"
                  onBlur={(e) => {
                    changeDisplayInput('codeCentreBacc', e);
                  }}
                >
                  {centresBacc.map((s, index) => (
                    <option value={s.value} key={`sm_${index}`}>
                      {s.text}
                    </option>
                  ))}
                </select>
                <span
                  onClick={(e) => {
                    changeDisplayInput('codeCentreBacc');
                  }}
                  id="codeCentreBacc"
                >
                  {candidat.codeCentreBacc && candidat.codeCentreBacc.nomCentreBacc}
                </span>
              </p>

              <p
                onClick={(e) => {
                  changeDisplayInput('numBacc');
                }}
              >
                <span className="subtitle">Numéro BACC: </span>
                <input
                  type="text"
                  id="numBaccIn"
                  style={{ display: 'none' }}
                  onBlur={() => {
                    changeDisplayInput('numBacc');
                  }}
                />
                <span id="numBacc">{candidat.numBacc ? candidat.numBacc : <span>&#8709;</span>}</span>
              </p>

              <p
                onClick={(e) => {
                  changeDisplayInput('anneeBacc');
                }}
              >
                <span className="subtitle">Session: </span>
                <input
                  type="text"
                  id="anneeBaccIn"
                  style={{ display: 'none' }}
                  onBlur={() => {
                    changeDisplayInput('anneeBacc');
                  }}
                />
                <span id="anneeBacc">{candidat.anneeBacc ? candidat.anneeBacc : <span>&#8709;</span>}</span>
              </p>

              <p>
                <span className="subtitle">Série: </span>
                <select
                  style={{ display: 'none' }}
                  id="codeSerieIn"
                  name="codeSerie"
                  onBlur={(e) => {
                    changeDisplayInput('codeSerie', e);
                  }}
                >
                  {series.map((s, index) => (
                    <option value={s.value} key={`sm_${index}`}>
                      {s.text}
                    </option>
                  ))}
                </select>
                <span
                  onClick={(e) => {
                    changeDisplayInput('codeSerie');
                  }}
                  id="codeSerie"
                >
                  {candidat.codeSerie && candidat.codeSerie.libelleSerie}
                </span>
              </p>
              <p>
                <span className="subtitle">Mention: </span>
                <select
                  style={{ display: 'none' }}
                  id="mentionIn"
                  name="mention"
                  onBlur={(e) => {
                    changeDisplayInput('mention', e);
                  }}
                >
                  {mentions.map((s, index) => (
                    <option value={s.value} key={`sm_${index}`}>
                      {s.text}
                    </option>
                  ))}
                </select>
                <span
                  onClick={(e) => {
                    changeDisplayInput('mention');
                  }}
                  id="mention"
                >
                  {candidat.mention}
                </span>
              </p>
            </div>
            <hr />
            {candidat?.niveau?.nom === 'Master' && (
              <>
                <div className="section">
                  <strong className="title">LICENCE</strong>
                  <p>
                    <span className="subtitle">Etablissement: </span>

                    <span id="numBord">{candidat.etablissementLicence}</span>
                  </p>
                  <p>
                    <span className="subtitle">Année: </span>

                    <span id="numBord">{candidat.anneeLicence}</span>
                  </p>
                  <p>
                    <span className="subtitle">Mention: </span>

                    <span id="numBord">{candidat.mentionLicence}</span>
                  </p>
                </div>
                <hr />
              </>
            )}
            <div className="section">
              <strong className="title">BORDEREAU</strong>
              <div className="bordereau">
                <p
                  onClick={(e) => {
                    changeDisplayInput('numBord');
                  }}
                >
                  <span className="subtitle">N°Bordereau: </span>
                  <input
                    type="text"
                    id="numBordIn"
                    style={{ display: 'none' }}
                    onBlur={() => {
                      changeDisplayInput('numBord');
                    }}
                  />
                  <span id="numBord">{candidat.numBord}</span>
                </p>
                <p>
                  <span className="subtitle">Date bordereau: </span>
                  {moment(candidat.dateBord).format('DD/MM/YYYY')}
                </p>
                <p
                  onClick={(e) => {
                    changeDisplayInput('montantBord');
                  }}
                >
                  <span className="subtitle">Montant: </span>
                  <input
                    type="number"
                    id="montantBordIn"
                    style={{ display: 'none' }}
                    onBlur={() => {
                      changeDisplayInput('montantBord');
                    }}
                  />
                  <span id="montantBord">{candidat.montantBord}</span>
                </p>
              </div>
            </div>
            <hr />
            <div className="section">
              <strong>PIECES JOINTES</strong>
              <div className="pieces-jointes">
                <div className="flex mt">
                  <CandidatAttachments candidat={candidat} apiRouteFile={apiRouteFile} />
                </div>
              </div>

              {candidat?.convocation?.id === undefined ? (
                <div className="num-inscription">
                  <div></div>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      generateConvocation(candidat);
                    }}
                  >
                    <p>NB: Ce numéro est générer automatiquement mais vous pouvez le modifier</p>
                    {/* <span className="subtitle">N° d'inscription: </span> */}
                    <div className="flexs" style={{ marginTop: 20 }}>
                      <FormButton
                        type="button"
                        color="success"
                        sx={{ marginRight: '20px' }}
                        onClick={(e) => {
                          e.preventDefault();
                          lastNumberConvocation();
                        }}
                        disabled={disabledBoutonValid}
                      >
                        Auto
                      </FormButton>
                      <TextField
                        required
                        label="Numéro inscription"
                        variant="standard"
                        margin="none"
                        sx={{ width: '200px', marginRight: '20px' }}
                        value={lastNumber}
                        onChange={handleChangeNumConvocation}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">{code}</InputAdornment>,
                        }}
                        type="number"
                      />
                      <FormButton type="submit" color="success" className="submitBtn" disabled={disabledBoutonValid}>
                        Valider ce dossier
                      </FormButton>
                    </div>
                  </form>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <SuccessSnackBar open={openSnack} message={succesMessage} close={() => setOpenSnack(false)} />
          <WarningSnackBar
            open={openUndefinedSnack}
            message={errorMessage}
            close={() => setOpenUndefinedSnack(false)}
          />
          <AlertDialogSlide
            open={openDeleteDialog}
            message={messageConfirmationDialog}
            handleDeleteDialog={handleDeleteCandidat}
          />
        </CandidatWrapper>
      )}
    </>
  );
};

const CandidatWrapper = styled.div`
  border: 1px solid #dbdbdb;
  border-radius: 10px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

  .subtitle {
    font-weight: 600;
  }

  .input-edit {
    display: none;
    height: 30px;
    border-radius: 2px;
    padding: 0px 10px;
  }

  .title {
    color: #3d3d3d;
  }
  .flex {
    display: flex;
  }

  .flexs {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .space-between {
    justify-content: space-between;
  }

  .back-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: #f0f0f0;
    border-radius: 20px;
  }

  .num-inscription {
    margin: 20px 0 80px 0;
    display: flex;
    justify-content: space-between;
  }

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    position: relative;
    .main-info {
      display: flex;
      align-items: center;
    }
    .buttons {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  .info {
    .section {
      margin: 40px 0;
    }
    .cin-container {
      .cin {
        display: flex;
        & p {
          margin-right: 60px;
        }
      }
      .duplicata {
        display: flex;
        & p {
          margin: 0 60px 0 0;
        }
      }
    }
    .bordereau {
      display: flex;
      & p {
        margin-right: 60px;
      }
    }
    .pieces-jointes {
      .item {
        margin-right: 40px;
      }
      .mt {
        margin-top: 40px;
      }

      .img-piece-jointe {
        margin-top: 10px;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
      }
    }
  }
`;

export default CandidatDetails;
